import React, { useState, useEffect,useContext} from 'react';
import { DataContext } from '../../contexts/ContextDataProvider/DataProvider';
import { Card, Box, CardContent, Typography, Button, Grid } from '@mui/material';

import ResponsiveMenu from './menubar/ResponsiveMenu';
import AddElementModal from './AddElementModal';
import ElementsList from './ElementsList';
import MAINRENDERBOX from './@MainRenderBox';
import UnitsAndCodeSelectionModal from './ModalUnistsAndCode';
import UnitsList from './UnitsList';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import UnitsCodes from './UnitsCodes';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ModalNewSheetInside from './ModalNewSheetInside';

//this

const AppIngenniHandler =({ idtypemodule, idioma }) => {
  //Context DATAPROVIDER
  const { projects, addSheet, cleanActiveSheet,  updateActiveSheet , activeSheet } = useContext(DataContext);
  //Context DATAPROVIDER
  
 const isModule="modulo05"
 ///***************** */
 const ElementDataForms=JSON.parse(localStorage.getItem("ElementDataForms_container1"))
 //console.log("FROM @AppIngenniHnadler")
 //console.log(ElementDataForms)
 ///***************** */

  const [dataModules, setDataModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [auxselectedModule, setauxselectedModule] = useState(null);
  const [unitsAndCodeModalOpen, setUnitsAndCodeModalOpen] = useState(false);
  const [designCodeOptions, setDesignCodeOptions] = useState([]);
  const [unitsOptions, setUnitsOptions] = useState([]);
  //console.log("CONSOLE FROM @AppIngenniHandler")
  //console.log(activeSheet)

  const [activenamesheet, setactivenamesheet] = useState(activeSheet.nameSheet);

  // MEDIA QUERY
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg')); // Pantallas grandes
  const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg')); // Pantallas medianas
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Pantallas pequeñas

  const boxHeight = isDesktop ? '100%' : (isTablet ? '70%' : '30%');
  const boxHeight2 = isDesktop ? '100%' : (isTablet ? '70%' : '80%');

  // PRINCIPAL STATES//
  const [Elements, setElements] = useState(() => {
    const storedElements = localStorage.getItem('elements');
    return storedElements ? JSON.parse(storedElements) : [];
  });
  const [isElementsVisible, setIsElementsVisible] = useState(true);
  const [widthWorkArea, setwidthWorkArea] = useState(10);
  const [MBOXAINRENDERBOX, setMBOXAINRENDERBOX] = useState(0);



  const [ActiveElement, setActiveElement] = useState([]); 
  const [ActiveSheet, setActiveSheet] = useState([]);
  const [stateLockedElements, setstateLockedElements] = useState([]);  
  const [codeDesign, setcodeDesign] = useState([]);  // Code for estructural Design 
  const [unitsDesign, setunitsDesign] = useState([]);    /// Units for structural calculus  
  const [ImportedData, setImportedData] = useState([]);   /// Data Imported From Ingenni Integration
  const [Typelements, setTypeElements] = useState({});    /// Type Design
  
  // Inicializa inputDataElements con los datos del localStorage
  const [inputDataElements, setinputDataElements] = useState(() => {
      const storedInputData = localStorage.getItem('inputData');
      return storedInputData ? JSON.parse(storedInputData) : {};
    });
  const [outputDataElements, setoutputDataElements] = useState({});  /// Ouput Dada of structural calcs  
  const [openModal, setOpenModal] = useState(false);
  const [openModalimport, setOpenModalimport] = useState(false);
  // FUNCION SAVE ELEMENTS IN LOCAL STORAGE
  
  //*************************Use Effects*********************************** */
  //*************************Use Effects*********************************** */
  //*************************Use Effects*********************************** */
  
  //*************************Drive Context Data*********************************** */
  //*************************Drive Context Data*********************************** */
  
  useEffect(() => {
    localStorage.setItem('inputData', JSON.stringify(inputDataElements));
  }, [inputDataElements]);

  useEffect(() => {
    
    if (idtypemodule !== undefined) {
      updateActiveSheet({
        isModule: idtypemodule
      });
    }
  }, [idtypemodule]);

  useEffect(() => {
    updateActiveSheet({DataSheet:{units:unitsDesign}})  
  }, [unitsDesign]);

  useEffect(() => {
    updateActiveSheet({DataSheet:{structuralCodeDesign:codeDesign}})  
  }, [codeDesign]);




   //**************************************************************************/
  

  




  useEffect(() => {
    localStorage.setItem('elements', JSON.stringify(Elements));
    //console.log("this is the AppIngenniHandler")
    //console.log("Saved elements to localStorage:", Elements); // Verificar datos guardados
  }, [Elements]);
  

  //FUCTION SAVE IMPORTED DATA
  const saveImportedData = (importedData) => {
    setImportedData(importedData);    
  };

  

  const SaveInputs = (data) => {
    if (ActiveElement) {
      // Actualiza los datos para el elemento activo sin sobrescribir los existentes
      setinputDataElements(prevData => ({
        ...prevData,
        [ActiveElement]: { ...prevData[ActiveElement], ...data }
      }));
    }
  };
  

  useEffect(() => {
    import('./dataModules.json').then((data) => {
      setDataModules(data.default);
      // Establecer el módulo seleccionado basado en idtypemodule
      const selectedModuleData = data.default.find(module => module.idtypemodule === idtypemodule);
      if (selectedModuleData) {
        setSelectedModule(selectedModuleData.name);
        // [Configuración adicional basada en el módulo seleccionado, si es necesario]
      }
    });
  }, [idtypemodule]);

  const handleActiveSheet = (value) => {
    setActiveSheet(value);
  };

  const handleActiveElement = (element) => {
    setActiveElement(element);
  };
  

const ChangeUnits =(NewUnits)=>{
  setunitsDesign(NewUnits)

}

const ChangeCodeDesig =(NewCode)=>{
  setcodeDesign(NewCode)
}

//Funcion control de barra latera de elementos y unidades
const toggleElementsVisibility = () => {
  setIsElementsVisible(!isElementsVisible);
  
  // Cambiar widthWorkArea entre 12 y 10.1
  if (widthWorkArea === 10) {
    setwidthWorkArea(12);
  } else {
    setwidthWorkArea(10);
  }

  if (MBOXAINRENDERBOX === 0) {
    setMBOXAINRENDERBOX(1.9);
  } else {
    setMBOXAINRENDERBOX(0);;
  }





};


    // Función para abrir la ventana modal
    const handleOpenModal = () => {
        setOpenModal(true);
      };
    
 // Función para cerrar la ventana modal y guardar la información
// Función para cerrar la ventana modal y guardar la información
const handleCloseModal = (nombreElemento, typeDesign) => {
  if (nombreElemento) {
    // Verifica si el nombre ya existe en la lista
    const elementoExistente = Elements.find(element => element.nombre === nombreElemento);
    
    if (!elementoExistente) {
      // Si no existe, agrega el nuevo elemento
      const nuevoElemento = { nombre: nombreElemento };
      setElements([...Elements, nuevoElemento]);

      // Agrega o actualiza el typeDesign para el nuevoElemento en inputDataElements
      setTypeElements(prevData => ({
        ...prevData,
        [nombreElemento]: { ...prevData[nombreElemento], typeDesign }
      }));

      setOpenModal(false);
    } else {
      // Si existe, muestra un mensaje de error al usuario
      alert('This element already exist!');
    }
  } else {
    // Si no se proporciona un nombre, solo cierra la ventana modal
    setOpenModal(false);
  }
};




    // Agrega un useEffect para mostrar el contenido de Elements cuando cambie
    useEffect(() => {
        //console.log(Elements);
        //console.log("this is the appIngenniFactory")  
        //console.log('inputDataElements:', inputDataElements)
      }, [Elements,Typelements]); // Este efecto se ejecutará cada vez que Elements cambie
  
    ///Funciones para editar y eliminar elementos////
    ///Funciones para editar y eliminar elementos////
      const handleEditElement = (currentName, newName) => {
        const updatedElements = Elements.map(el => el.nombre === currentName ? { ...el, nombre: newName } : el);
        setElements(updatedElements);
    };
    
    const handleDeleteElement = (index) => {
        const updatedElements = [...Elements];
        updatedElements.splice(index, 1);
        setElements(updatedElements);
    };
 
  const [openModalNew, setOpenModalNew] = useState(false);
  //****************************************** */


 


    if (selectedModule) {
      const widthinput=dataModules.find((module) => module.name === selectedModule).widthinput
      const widthoutput=dataModules.find((module) => module.name === selectedModule).widthoutput

      
      //************************ADD NEW SHEET*******************/
      //************************ADD NEW SHEET*******************/
      //************************ADD NEW SHEET*******************/
      const createNewSheet = (formData) => {
        // Preparación de los datos que se pasarán a addSheet
        const sheetData = {
          nameSheet: formData.nameSheet,
          project: formData.project,
          subproject: formData.subproject,
          isModule: selectedModule,
          // Asumiendo que formData incluye todos los datos necesarios.
          Data: {
            units: formData.unit,
            structuralCodeDesign: formData.codeDesign
          }
        };
      
        // Invoca addSheet de DataContext pasando los datos preparados
        addSheet(sheetData).then(() => {
          // Puedes manejar acciones post-creación aquí. Por ejemplo, redireccionar o actualizar el estado.
          //navigate(`/${selectedModule}`);
          console.log("Saved Succesfully")
          alert("Sheet Saved Succesfully")
        }).catch(error => {
          console.error('Error al crear la hoja de cálculo desde SheetsList:', error);
        });
      };

           
      const handleOpenModalNewSheetInside = () => {
        setOpenModalNew(true);
      };
      
     

      return (
        <div style={{ marginTop: isMobile || isTablet ? '1.2cm' : 0, display: 'flex', flexDirection: 'column' }}>              
          <ResponsiveMenu add={handleOpenModal} namesheetactive={activeSheet.nameSheet} updateActiveSheet={updateActiveSheet} openSaveAs={handleOpenModalNewSheetInside} />
          <Box  >
          <Grid container spacing={2} >          
            {/* Primera columna: ElementsList */}
            
            {isElementsVisible && (
             <Grid item xs={12}  md={1.8} flexDirection="column" justifyContent="space-between" height={boxHeight2} >
            <Box display="flex" flexDirection="column" justifyContent="space-between" padding={1.5} borderRadius={2.5}  mt={2} sx={{backgroundColor:"transparent"}}>
              <ElementsList 
                label={dataModules.find((module) => module.name === selectedModule).label}
                elements={Elements} 
                onEditElement={handleEditElement}
                onDeleteElement={handleDeleteElement}  
                colorElementsList={dataModules.find((module) => module.name === selectedModule).colorElementsList}
                colorBoxElementsList={dataModules.find((module) => module.name === selectedModule).colorBoxElementsList}
                colorTextElementsList={dataModules.find((module) => module.name === selectedModule).colorTextElementsList}
                colorTextBoxElementsList={dataModules.find((module) => module.name === selectedModule).colorTextBoxElementsList}
                colorElementsListHover={dataModules.find((module) => module.name === selectedModule).colorElementsListHover}
                colorScrollBarElementsList={dataModules.find((module) => module.name === selectedModule).colorScrollBarElementsList}
                colorScrollBarElementsList2={dataModules.find((module) => module.name === selectedModule).colorScrollBarElementsList2}
                activeElementcolor={dataModules.find((module) => module.name === selectedModule).activeElementcolor}
                editcolor={dataModules.find((module) => module.name === selectedModule).editcolor}
                deletecolor={dataModules.find((module) => module.name === selectedModule).deletecolor}
                copyIconcolor={dataModules.find((module) => module.name === selectedModule).copyIconcolor}
                add={handleOpenModal}
                activeElement={handleActiveElement}
                activesheet={handleActiveSheet}
                idioma={idioma}                
              />
              <Box mt={3}>              
                <UnitsCodes units={dataModules.find((module) => module.name === selectedModule).structuralCodeAvailable} currentUnit={unitsDesign} ChangeUnits={ChangeCodeDesig}/>
                <UnitsList units={dataModules.find((module) => module.name === selectedModule).unistAvailable} currentUnit={unitsDesign} ChangeUnits={ChangeUnits}/>
              </Box>

              </Box>

            </Grid>
            )}
  
            {/* Condición para mostrar solo si typeinterface es "1" --Esta es*/}
            {dataModules.find((module) => module.name === selectedModule).typeinterface === "1" && (
              <>
                {/* Columna 2: InputBox */}
                <Grid item xs={12} md={isElementsVisible ? 10.1 : 12}>    
                  <Box mt={2} >
                 
                  <MAINRENDERBOX
                    colorbackgroundInputs={dataModules.find((module) => module.name === selectedModule).colorbackgroundInputs}
                    colorbackgroundArea={dataModules.find((module) => module.name === selectedModule).colorbackgroundArea}
                    typeModule={dataModules.find((module) => module.name === selectedModule).idtypemodule}
                    nameModule={selectedModule}
                    activeElement={ActiveElement}
                    ActiveSheet={ActiveSheet} 
                    codeDesign={codeDesign}
                    units={unitsDesign} 
                    label={dataModules.find((module) => module.name === selectedModule).label}
                    dataimported={ImportedData}     
                    idioma={idioma}  
                    temporalInputSave={SaveInputs} 
                    toggleElementsVisibility={toggleElementsVisibility}
                    isElementsVisible={isElementsVisible}
                   
                  />
                  </Box>              
                </Grid>
             
  
            </>
            )}
          </Grid>
          </Box>
  
          {/* Renderiza la ventana modal */}
          <AddElementModal open={openModal} onClose={handleCloseModal} addOptions={dataModules.find((module) => module.name === selectedModule).addOptions} idioma={idioma}/>


          <ModalNewSheetInside
             nameprovisional={activenamesheet}
             open={openModalNew}
             handleClose={() => setOpenModalNew(false)}       
             onAcceptNewSheet={createNewSheet} 
             codeDesigns={[codeDesign]} // Pasa structuralCodeAvailable
             units={[unitsDesign]}
             module={idtypemodule}
        />
        </div>
      );
  }
  
  

  return (
    <Box m={5}>   
    </Box>
  );
};

export default AppIngenniHandler;
