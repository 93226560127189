import { useState, useEffect } from "react";
import { DataProvider } from "./contexts/ContextDataProvider/DataProvider";
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { UserProfileProvider } from "./contexts/UserProfileContext/UserProfileContext";

import PrivateRoutes from "./AppPrivateRoutes";
import PublicRoutes from "./AppPublicRoutes";
import useAuth from "./hooks/useAuth/useAuth";

function WrapperPrivateRoutes({onLogout,usernameoficial,idioma}) {
  const [theme, colorMode] = useMode();
//
//
  return (
    <DataProvider>
      <UserProfileProvider>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline/>   
                  <PrivateRoutes onLogout={onLogout} usernameoficial={usernameoficial} idioma={idioma}/>  
          </ThemeProvider>
        </ColorModeContext.Provider>
      </UserProfileProvider>
    </DataProvider>

  );
}

export default WrapperPrivateRoutes;
