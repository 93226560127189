import { useState,useContext } from 'react';
import { fetchcustom } from '../../functions/fetch/fetchcustom';
import UserProfileContext from '../../contexts/UserProfileContext/UserProfileContext';

function useUserProfile({ operation,identificationCode,nameUser,job }) {
  const { updateUserProfile } = useContext(UserProfileContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [response, setResponse] = useState(null);
  


  const performOperation = async () => {
    setIsLoading(true);
    setError(null);
  
    try {
      // Aquí se asume la existencia de tu función fetchCustom(url, data)
      if (operation === "updateUserProfile") {
        const data = {
          "operation": operation,
          "identificationCode": identificationCode,
          "nameUser": nameUser,
          "job": job
        };
        const url = "https://ss0pnhnrj9.execute-api.us-east-1.amazonaws.com/dev/crud-users";
        const result = await fetchcustom(url, data);
  
        // Asumiendo que result.body contiene los campos newjob y newName
        if (result.body && result.body.newjob && result.body.newName) {
            updateUserProfile(result.body.newName, result.body.newjob);
          }
  
        setResponse(result);
        console.log("useUserProfile.js")
        console.log(result.body)
        
      }
      // Aquí se pueden agregar más operaciones CRUD con else if
  
    } catch (e) {
      setError(e.toString()); // Usar e.toString() para asegurar que se está capturando el mensaje de error correctamente
    } finally {
      setIsLoading(false);
    }
  };
  

  return { isLoading, error, response, performOperation };

}

export default useUserProfile;
