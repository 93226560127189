import React, { useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';

const UnitsList = ({ units, currentUnit,ChangeUnits }) => {
  
  const initialUnit = localStorage.getItem('selectedUnit') || (units.length > 0 ? units[0] : '');
  const [selectedUnit, setSelectedUnit] = React.useState(initialUnit);



  useEffect(() => {
    // Actualiza localStorage cuando selectedUnit cambia
    localStorage.setItem('selectedUnit', selectedUnit);
    ChangeUnits(selectedUnit);
  }, [selectedUnit, ChangeUnits]);

  const handleUnitChange = (event) => {
    setSelectedUnit(event.target.value);
  };


  return (
    <div style={{ width: '100%' }}>
      <Box>
        <FormControl fullWidth variant="outlined" size="small" style={{ marginBottom: '10px' }}>
          <InputLabel id="unit-label" shrink style={{ fontSize: 'h6', padding: '0 4px' }}>Units</InputLabel>
          <Select
            labelId="unit-label"
            id="unit-select"
            value={selectedUnit}
            label="Unit"
            onChange={handleUnitChange}
        
          >
            {units.map((unit, index) => (
              <MenuItem key={index} value={unit} >{unit}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default UnitsList;
