import React, { useEffect, useState, useContext} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { DataContext } from '../../contexts/ContextDataProvider/DataProvider';

const EditProjectDialog = ({ open, onClose, project, onUpdate }) => {
  const { updateProjectName } = useContext(DataContext);
  const [newName, setNewName] = useState('');
  const [newCode, setNewCode] = useState('');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); 

  useEffect(() => {
    if (project) {
      setNewName(project.nombre);
      setNewCode(project.codigo);
    }
  }, [project]);

  const handleUpdate = async () => {
    await updateProjectName(project.id, newCode, newName); // Usa la función del contexto
    onClose(); // Cierra el cuadro de diálogo después de la actualización
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { backgroundColor: colors.primary.primary3 } }}>
      <DialogTitle>Edit Project</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Project Name"
          type="text"
          fullWidth
          variant="outlined"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Project Code"
          type="text"
          fullWidth
          variant="outlined"
          value={newCode}
          onChange={(e) => setNewCode(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">Cancel</Button>
        <Button onClick={handleUpdate} variant="contained">Update</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditProjectDialog;
