export async function fetchcustom(url, data) {
    try {
      const respuesta = await fetch(url, {
        method: 'POST', // Método HTTP utilizado para la solicitud
        headers: {
          'Content-Type': 'application/json' // Indica el tipo de contenido que se está enviando
        },
        body: JSON.stringify(data) // Convierte los datos a una cadena JSON
      });
  
      if (!respuesta.ok) {
        throw new Error(`HTTP error! status: ${respuesta.status}`);
      }
  
      const contenido = await respuesta.json();
      return contenido; // Retorna la respuesta del servidor como un objeto JSON
    } catch (error) {
      console.error('Hubo un problema con la operación fetch: ' + error.message);
      throw error; // Relanza el error para manejarlo más adelante si es necesario
    }
  }
  
