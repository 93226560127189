import { useState } from "react";

import Topbar from "./scenes/global/Topbar";
import TopbarSells from "./scenes/global/TopbarSells";
import Sidebar from "./scenes/global/Sidebar";
import {BrowserRouter as Router,Route,Routes,Navigate} from 'react-router-dom'

import { Box } from "@mui/material";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";

import MainPageSells from "./public/MainPageSells";

import RecoverPassword from "./scenes/recover/recover";
import TopBarSellsIngenni from "./public/menu/TopBarPublic";
import LoginV1_0 from "./public/login/LoginV1.0";
import NotFound from "./scenes/NotFound/NotFound";
import MenuPublic from "./public/menu/MenuPublic";


function PublicRoutes({ onLogin,idioma }) {
  const [theme,colorMode]=useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box>
          
            {/*</Box><TopBarSellsIngenni />*/}
          
            <MenuPublic></MenuPublic>
        
            <Routes>                         
              <Route path='/' element={<LoginV1_0 onLogin={onLogin}/>}/> 
              <Route path='/login' element={<LoginV1_0 onLogin={onLogin}/>}/>           
              <Route path='/recover' element={<RecoverPassword/>}/> 
              <Route path='*' element={<NotFound/>}/>  
              {//<Route path='/quotes' element={<QuoteForm/>}/>
              }
            </Routes>
         </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default PublicRoutes;
