import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../contexts/ContextDataProvider/DataProvider.js';
import {Grid, Avatar,useTheme, Box, Typography, List, ListItem, ListItemText, ListItemIcon, Button, Collapse, IconButton,TextField,FormControl, InputLabel,Select,MenuItem} from '@mui/material';
import { tokens } from '../../theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import UserAvatar from '../../components/UserAvatar/UserAvatar.js';
import FactoryIcon from '@mui/icons-material/Factory';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Icono de chulito o verificación
import EditIcon from '@mui/icons-material/Edit';
import ToggleOffIcon from '@mui/icons-material/ToggleOff'; // Icono para "desactivar"
import ToggleOnIcon from '@mui/icons-material/ToggleOn'; // Icono para "activar"
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditEnterpriseDialog from './EditEnterpriseDialog.js';
import WhatsAppButton2 from '../../components/whatsapp/WhatsAppBuetton2.js';







export function MyEnterprise() {
  const { user , enterprises, datamodulesAuthorized,updateEnterprise} = useContext(DataContext);
  
  const [openDialog, setOpenDialog] = useState(false);
  
  
  if(enterprises){
    //console.log("enterprises from Enterprise.js")
    //console.log(enterprises)
    //console.log(datamodulesAuthorized)
  }
  

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  


  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    const [id,setId]=useState("")
    const [PlanIngenni,setPlanIngenni]=useState("")
    const [UsersAuthorized,setUsersAuthorized]=useState("")
    const [NameForEnterprise,NameEnterprise]=useState("")

    useEffect(() => {
      if (enterprises) {       
        setId(enterprises.id)
        setPlanIngenni(enterprises.PlanIngenni)
        setUsersAuthorized(enterprises.UsersAuthorized)
        NameEnterprise(enterprises.NameEnterprise)
      }
    }, [enterprises]); // Dependencia de user
    


    //const []=useState()
    // Estados iniciales vacíos o valores predeterminados
    // Estados iniciales con valores predeterminados
    const [userEmail, setUserEmail] =useState( user.userEmail);
    const [enterprise, setEnterprise] = useState(user.enterprise);
    const [userName, setUserName] = useState(user.userName);
    const [userJob, setUserJob] = useState(user.userJob);
    const [role, setRole] = useState(user.role);



    const passwordPlaceholder = "********"; //
  
    useEffect(() => {
      // Cargar los datos desde el localStorage
      const userEmail = localStorage.getItem('useremail') || 'No Email';
      const enterprise = localStorage.getItem('Enterprise') || 'No Enterprise';
      const userName = localStorage.getItem('username') || 'No Name';
      const userJob = localStorage.getItem('userjob') || 'No Job';
      const role = localStorage.getItem('rol') || 'No Role';
  
      setUserEmail(userEmail);
      setEnterprise(enterprise);
      setUserName(userName);
      setUserJob(userJob);
      setRole(role);
    }, []);


  


    const getInitials = (name) => {
        // Asegurar que name es una cadena antes de proceder
        name = name || '';
        
        const words = name.split(' ');
        let initials = '';
        
        if (words.length > 1 && words[0] && words[1]) {
          initials = words[0][0] + words[1][0];
        } else if (name.length > 1) {
          initials = name.substring(0, 2);
        } else if (name.length === 1) {
          initials = name[0];
        }
        
        return initials.toUpperCase();
      };



  const urlLogoEnterprise=localStorage.getItem('UrlLogo'); 
  const widthLogoEnterprise=localStorage.getItem('widthLogo'); 
  const heightLogoEnterprise=localStorage.getItem('heightLogo'); 


  const authorizedModules = [
    'Diseño de vigas',
    'Análisis estructural',
    'Diseño de columnas',
    'Simulación de cargas'
  ];

      // Lista de usuarios dummy
    const [users, setUsers] = useState([
        { name: 'John Doe', email: 'john@example.com', emailSecondary: 'johndoe@secondary.com', rol: 'Administrator', job: 'Developer', active: true },
        {name: 'Jane Smith', email: 'jane@example.com', emailSecondary: 'janesmith@secondary.com', rol: 'User', job: 'Designer', active: false },
        // Agrega más usuarios según necesites
    ]);

    // Función para eliminar un usuario
    const handleDelete = (userId) => {
        setUsers(users.filter(user => user.id !== userId));
    };

    // Función para activar/desactivar un usuario
    const toggleActive = (userId) => {
        setUsers(users.map(user => user.id === userId ? { ...user, active: !user.active } : user));
    };

      
    // Simula la acción de editar
    const handleEdit = (userProperty) => {
        console.log(`Editing ${userProperty}`);
      };



      const handleOpenDialog = () => {
        setOpenDialog(true);
      };
    
      const handleCloseDialog = () => {
        setOpenDialog(false);
      };
    
      const handleUpdateEnterprise = (newName) => {
        updateEnterprise(enterprises.id, newName);
      };
    

  

  return (
    <Box sx={{paddingBottom:2}}>

<Grid container spacing={2}>

     <Grid item xs={12} md={6}>
        
    <Box m={7.5} padding={5} borderRadius={2.5} mb={5} sx={{
      backgroundColor: colors.primary.primary,
      maxWidth: isDesktop ? '600px' : 'auto',
      marginRight: 'auto',
      marginLeft: 'auto',
      '@media (max-width:1280px)': {
        marginLeft: 2,
        marginRight: 2,
        marginBottom:5
      },
      '@media (max-width:600px)': {
        marginLeft: 1,
        marginRight: 1,
        marginBottom:5
      },
      //border: theme.palette.mode === 'light' ? `1px solid ${colors.primary.secondary}` : 'none',
      boxShadow: theme.palette.mode === 'light' ? `0 0 5px ${colors.grey[500]}` : 'none',
      //border: theme.palette.mode === 'light' ? `1px solid ${colors.grey[500]}` : 'none',
      //boxShadow: theme.palette.mode === 'light' ? `0 0 5px ${colors.blueAccent.contrast1}` : 'none',
    }}>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
      <FactoryIcon sx={{ fontSize: 40, mb:2}} /> {/* Ajusta el tamaño del icono según necesites */}
      <Typography variant="h3" gutterBottom>My Enterprise Profile</Typography>
    </Box>

    <Box mt={3} display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width={widthLogoEnterprise}
                  height={heightLogoEnterprise}
                  src={urlLogoEnterprise}         
                />
              </Box> 



        <Box mt={2}>
        <Box mt={2} mb={2} display="flex" gap={2} sx={{ justifyContent: 'space-between'}}>
            <Button variant="outlined" onClick={handleOpenDialog}>Edit enterprise name</Button>            
            <WhatsAppButton2 phoneNumber="+573005584478" />
          </Box>
          <TextField
            label="Enterprise id"
            variant="outlined"
            value={id}
            size="small"
            fullWidth
            margin="normal"
            disabled
          />
          <TextField
            label="Enterprise Name"
            variant="outlined"
            value={NameForEnterprise}
            size="small"
            fullWidth
            margin="normal"
            disabled
          />
          <TextField
            label="Plan Ingenni"
            variant="outlined"
            value={PlanIngenni}
            size="small"
            fullWidth
            margin="normal"
            disabled
          />

          <TextField
            label="Number of authorized users"
            variant="outlined"
            value={UsersAuthorized}
            size="small"
            fullWidth
            margin="normal"
            disabled
          />

        <TextField
            label="Cloud Storage "
            variant="outlined"
            value={enterprises.isCloudStorage?"Yes,Included":"Not Included"}
            size="small"
            fullWidth
            margin="normal"
            disabled
          />
    
          
    <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
             Authorized Modules
          </Typography>
          {!datamodulesAuthorized && (
               <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
               Loading ...
            </Typography>
               )}
          {datamodulesAuthorized && (
               <List>
               {datamodulesAuthorized.map((module, index) => (
                 <ListItem key={index} sx={{ pl: 0 }}>
                   <ListItemIcon>
                     <CheckCircleIcon sx={{ color: colors.primary.third }} /> {/* Ícono de verificación */}
                   </ListItemIcon>
                   <ListItemText primary={module.nameModule} /> {/* Accediendo a la propiedad nameModule */}
                 </ListItem>
               ))}
             </List>

          )}
       


       
        </Box>
        
   



          </Box>
          </Grid>

          <Grid item xs={12} md={6}>



          <Box m={7.5} padding={5} borderRadius={2.5} mb={5} sx={{
      backgroundColor: colors.primary.primary,
      maxWidth: isDesktop ? '600px' : 'auto',
      marginRight: 'auto',
      marginLeft: 'auto',
      '@media (max-width:1280px)': {
        marginLeft: 2,
        marginRight: 2,
        marginBottom:5
      },
      '@media (max-width:600px)': {
        marginLeft: 1,
        marginRight: 1,
        marginBottom:5
      },
      //border: theme.palette.mode === 'light' ? `1px solid ${colors.primary.secondary}` : 'none',
      boxShadow: theme.palette.mode === 'light' ? `0 0 5px ${colors.grey[500]}` : 'none',
      //border: theme.palette.mode === 'light' ? `1px solid ${colors.grey[500]}` : 'none',
      //boxShadow: theme.palette.mode === 'light' ? `0 0 5px ${colors.blueAccent.contrast1}` : 'none',
    }}>
  <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <AccountCircleIcon sx={{ fontSize: 40 }} /> {/* Se quita mb:2 para alinear verticalmente */}
        <Typography variant="h3">Users Admin</Typography>
      </Box>
  
      <Box>
          <Button variant="contained" size="small">Add User</Button>
      </Box>
      <Box>
        <WhatsAppButton2 phoneNumber="+573005584478" />
      </Box>
    </Box>
    
  
    <Box>

    <Box>
      {users.map((user, index) => (
        <Box key={index} mb={2}>
            <Box display="flex" justifyContent="space-between">
                <Box display="flex" justifyContent="space" alignItems="center">
                    <Box display="flex" justifyContent="center" mb={0.7} mr={2}> 
                        <UserAvatar name={user.name} width={35} height={35} />
                    </Box>
                    <Box>
                    <Typography variant="h6" gutterBottom>{user.name}</Typography>
                    </Box>
                </Box>
                <Box>
                    <Button>Edit</Button>
                </Box>
                <Box>
                    <Button>Delete</Button>
                </Box>
                <Box>
                    <Button>Desactivate</Button>
                </Box>
          </Box>
          <Box ml={4} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography>Email: {user.email}</Typography>
            <IconButton onClick={() => handleEdit(`${user.name} - Email`)}><EditIcon /></IconButton>
          </Box>
          <Box ml={4}display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography>Secondary Email: {user.emailSecondary}</Typography>
            <IconButton onClick={() => handleEdit(`${user.name} - Secondary Email`)}><EditIcon /></IconButton>
          </Box>
          <Box ml={4} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography>Role: {user.rol}</Typography>
            <IconButton onClick={() => handleEdit(`${user.name} - Role`)}><EditIcon /></IconButton>
          </Box>
          <Box ml={4} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography>Job: {user.job}</Typography>
            <IconButton onClick={() => handleEdit(`${user.name} - Job`)}><EditIcon /></IconButton>
          </Box>
          <Box ml={4} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Typography>Active: {user.active ? 'Yes' : 'No'}</Typography>
            {/* Suponiendo que no necesitas botón de editar para 'Active' */}
          </Box>
        </Box>
      ))}
    </Box>


        

    </Box>

   
     
   



          </Box>
          </Grid>


          </Grid>

          <EditEnterpriseDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onUpdate={handleUpdateEnterprise}
      />

    </Box>
  );
}
