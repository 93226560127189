// UserProfileContext.js
import { ConsoleSqlOutlined } from '@ant-design/icons';
import React, { createContext, useState, useEffect } from 'react';

const UserProfileContext = createContext();

export const UserProfileProvider = ({ children }) => {
  const [userName, setUserName] = useState(localStorage.getItem('username') || '');
  const [userJob, setUserJob] = useState(localStorage.getItem('userjob') || '');

  useEffect(() => {
    localStorage.setItem('username', userName);
    localStorage.setItem('userjob', userJob);
    //console.log("from UserProfileContext.js")
    //console.log(userName)
    //console.log(userJob)
  }, [userName, userJob]);

  const updateUserProfile = (newName, newJob) => {
    setUserName(newName);
    setUserJob(newJob);
  };

  return (
    <UserProfileContext.Provider value={{ userName, userJob, updateUserProfile }}>
      {children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileContext;

