import React from 'react';

const VentanaParaAbrirFormatoCapitulos = ({ agregarMultiplesCapitulos, agregarElementosCapitulo, capitulosExistentes }) => {
  const fileInputRef = React.useRef(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (!file) {
      alert("Ningún archivo seleccionado o acción cancelada por el usuario.");
      return;
    }

    if (file.type !== "application/json") {
      alert("Error: El archivo debe ser un .json");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = JSON.parse(e.target.result);

        if (!data.capitulos || !data.ElementosCapitulos) {
          throw new Error("La estructura del archivo JSON no es correcta.");
        }

        const capitulos = data.capitulos || [];
        
        // Filtrar los capítulos que ya existen
        const capitulosNuevos = capitulos.filter(capitulo => !capitulosExistentes.includes(capitulo));

        if (capitulosNuevos.length > 0) {
          agregarMultiplesCapitulos(capitulosNuevos);
        } else {
          alert("No hay nuevos capítulos para agregar.");
        }
               
      } catch (error) {
        alert(`Error al procesar el archivo JSON: ${error.message}`);
      }
    };

    reader.onerror = () => {
      alert("Error al leer el archivo.");
    };

    reader.readAsText(file);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <input
        type="file"
        accept=".json"
        onChange={handleFileUpload}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
      <button className="boton-menu" onClick={handleButtonClick}>Abrir Formato</button>
    </div>
  );
};

export default VentanaParaAbrirFormatoCapitulos;
