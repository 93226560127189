import { useState, useEffect } from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";

import PrivateRoutes from "./AppPrivateRoutes";
import PublicRoutes from "./AppPublicRoutes";
import useAuth from "./hooks/useAuth/useAuth";
import WrapperPrivateRoutes from "./WrapperPrivateRoutes";

function App() {
  //localStorage.clear();


  const [theme, colorMode] = useMode();
  const { loggedIn, username, handleLogin, handleLogout } = useAuth(); // Usa el hook
  const [idioma, setIdioma] = useState('en'); // Predeterminado en inglés

  useEffect(() => {
    const idiomaNavegador = navigator.language;
    if (idiomaNavegador.startsWith('es')) {
      setIdioma('es');
    } else {
      setIdioma('en');
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('idioma', idioma);
  }, [idioma]);

  return (
   
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">                    
          <main className="content">          
            {loggedIn ? (
              <WrapperPrivateRoutes onLogout={handleLogout} usernameoficial={username} idioma={idioma}/>
            ) : (
              <PublicRoutes onLogin={handleLogin} idioma={idioma} />
            )}    
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
   

  );
}

export default App;
