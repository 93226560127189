import React, { useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  CircularProgress
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import recoverImage from './recoverimage.png';
import { ColorModeContext,tokens } from '../../theme';


const ChangePasswordComponent = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);  

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isTablet = useMediaQuery(theme.breakpoints.only('md'));
  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState('');
  const [recoveryCode, setRecoveryCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [verifyNewPassword, setVerifyNewPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };


  const isPasswordValid = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return regex.test(password);
  };

  const isEmailValid = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailError(!isEmailValid(email));
    setPasswordError(!isPasswordValid(newPassword));
    setPasswordMatchError(newPassword !== verifyNewPassword);

    if (!email || !recoveryCode || !newPassword || !verifyNewPassword) {
      alert('Please, fill in all the fields.');
      return;
    }

    if (!isEmailValid(email) || !isPasswordValid(newPassword) || newPassword !== verifyNewPassword) {
      return;
    }

    if (newPassword !== verifyNewPassword) {
      alert('The passwords do not match.');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch('https://ss0pnhnrj9.execute-api.us-east-1.amazonaws.com/dev/resetpassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          keyRecoverPassword: recoveryCode,
          newpassword: newPassword,
          VerifyNewpasword: verifyNewPassword
        }),
      });

      const data = await response.json();
      alert(data.body);
    } catch (error) {
      console.error('Error sending data to the API.', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container>
       {isDesktop && (
      // Este Grid item actúa como un espacio en blanco a la izquierda en modo de escritorio
      <Grid item md={8.5} lg={9.5}>
        <Box
          component={Paper}
          sx={{
            height: '100vh',
            backgroundColor: colors.primary.background,
          }}
        />
      </Grid>
    )}

  
  
      <Grid item xs={12} md={3.5} lg={2.5}>
        <Box
           component={Paper}
           sx={{
             p: 4,
             height: '100vh',
             display: 'flex',
             flexDirection: 'column',
             justifyContent: 'center',
             margin: isTablet ? '0 auto' : 0,
             width: isTablet ? '100%' : '100%',
             backgroundColor: colors.primary.primary
           }}
        >
          <Box ml={0.5}>
          <Typography variant="h4" gutterBottom>
          Change Password
          </Typography>
          </Box>
  
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              error={emailError}
              helperText={emailError ? "Invalid email" : ""}
              value={email}
              onChange={(e) => { setEmail(e.target.value); setEmailError(false); }}
              size="small"
            />
  
            <TextField
              label="Recovery Code."
              variant="outlined"
              fullWidth
              margin="normal"
              value={recoveryCode}
              onChange={(e) => setRecoveryCode(e.target.value)}
              size="small"
            />
  
            <FormControl fullWidth variant="outlined" margin="normal" error={passwordError}>
              <InputLabel htmlFor="new-password">New Password</InputLabel>
              <OutlinedInput
                size="small"
                id="new-password"
                type={showPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={(e) => { setNewPassword(e.target.value); setPasswordError(false); }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
              />
              {passwordError && (
                <Typography color="error" variant="caption">
                  The password must have at least one uppercase letter, one lowercase letter, one number, one special character, and a length of 6 characters.
                </Typography>
              )}
            </FormControl>
  
            <FormControl fullWidth variant="outlined" margin="normal" error={passwordMatchError}>
              <InputLabel   htmlFor="verify-new-password">Verify New Password</InputLabel>
              <OutlinedInput
                size="small"
                id="verify-new-password"
                type={showPassword ? 'text' : 'password'}
                value={verifyNewPassword}
                onChange={(e) => { setVerifyNewPassword(e.target.value); setPasswordMatchError(false); }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Verify New Password"
              />
            </FormControl>
  
            <Button
              type="submit"
              variant="contained"
              
              fullWidth
              sx={{ mt: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Change Password'}
            </Button>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ChangePasswordComponent;
