import React,{useState,useEffect,useContext} from 'react';
import {useTheme, Box, Typography, Grid, TextField, Stack, Tabs, Tab, Button, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import { List, ListItem, ListItemText, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Icono para el indicador de expansión

///Import Contexto principal
import { DataContext } from '../../../contexts/ContextDataProvider/DataProvider';
///Import colorstheme
import { tokens } from '../../../theme';
///Import Componentes
import IngenniInput from '../../Components/inputs/IngenniInput';
import IngenniSelect from '../../Components/inputs/ingenniSelect';
///Import custom Hooks---Importar en todos los componente
import useUnitsLabels from '../../hooks/useUnitLabels/useUnitLabels';
import useLanguageLabels from '../../hooks/useNamesLabels/useNamesLabels';
import useDesignCode from '../../hooks/useDesignCode/useDesignCode';
import useUnits from '../../hooks/useConversionUnits/useUnits';
import useFormData from '../../hooks/useFormData/useFormData';
// Imports Particulares de cada modulo
import names from './names.json';
import TreeJs from './3dview';

import OutputM27 from './OutputM27'
import './ViewIndexM05.css'; 
import RectangleChart from './RectangleChart';
import IngenniSelectRestricted from '../../Components/inputs/ingenniSelectRestricted';

function IngenniM27(props) {  
  //-------------------------MANDATORY-------------------------------------------------
  //-------------------------MANDATORY-------------------------------------------------
  //************************************************************************************
  //************Theme Colors******* 
  //************************************************************************************  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);     

  //************************************************************************************
  //************Use Customs Manejo de Labels por idioma y Unidades**********************
  //************************************************************************************  
  const N = useLanguageLabels(names);
  const units=props.unitsDesign
  const currentUnits= useUnits(units);
  const unitData = useUnitsLabels(props.unitsDesign); 
  const currentCodeDesign = useDesignCode(props.codeDesign) 
  //************************************************************************************
  //************Use custom Gestion Estado Principal del componente**********************
  //************************************************************************************  
  const [formData, handleInputChange,handleInputObjectChange] = useFormData({},props.activeElement,currentUnits);
  //-------------------------MANDATORY-------------------------------------------------
  //-------------------------MANDATORY-------------------------------------------------
 
  //************************************************************************************
  //************CONTROLS & FUNCIONTS INTERFACE FOR THIS MODULE*******
  //************************************************************************************
  const [activeComponent, setActiveComponent] = useState('input');
  const toggleActiveButton = (buttonName) => {
    setActiveComponent(buttonName);
  };

  //************************************************************************************
  //************SUPPORTS************
  const [typeSupport, setTypeSupport] = useState("");
  const [dataSupport, setDataSupport] = useState({
    position: '',
    units: '',
    typeunit: ''
  });
  

  const addSupport = (path,position,units,typeunit,typeSupport) => {
    const supportData = {
      Position: {
        "value": position,
        "units": units,
        "typeunit": typeunit,
      },
      typeSupport: {
        "typeSupport": typeSupport
      }
    };
    handleInputObjectChange("supports", supportData,true);
  }

  const removeSupport = (path) => {    
    handleInputObjectChange(`supports.${path}`, undefined);
  }

  //************************************************************************************
  //************TYPE LOADS AND COMBINATIONS*******
  //************************************************************************************
  const [TypeLoad,setTypeLoad]=useState("")
  const [LoadPatter,setLoadPatter]=useState("")
  const [positionLoad, setPositionLoad] = useState({
    start: { value: '', units: '', typeunit: '' },
    end: { value: '', units: '', typeunit: '' },
    Value: '' 
  });
  
  const [Load, setLoad] = useState("")
  const [isExpanded, setisExpanded] = useState(props.isExpanded)


  useEffect(() => {
    console.log("From ViewIndexM27.js")
    console.log(isExpanded)
},[isExpanded])
    
  
  

  const addPointLoad = (path,loadvalue,loadunits,typeunitload,positionvalue,unitsposition,typeunitposition) => {
    const PointLoadData = {
      LoadPatter:LoadPatter,
      Position: {
        "value": positionvalue,
        "units": unitsposition,
        "typeunit": typeunitposition,
      },
      loadvalue: {
        "value": loadvalue,
        "units": loadunits,
        "typeunit": typeunitload,
      },
    };
    handleInputObjectChange("loads-PointLoads", PointLoadData,true);
  }

  const addDistributedLoad = (path,loadvalue,loadunits,typeunitload,positionStartvalue,positionEndValue,unitsposition,typeunitposition,LoadPatter) => {
    const PointLoadData = {
      LoadPatter:LoadPatter,
      PositionStar: {
        "value": positionStartvalue,
        "units": unitsposition,
        "typeunit": typeunitposition,
      },
      PositionEnd: {
        "value": positionEndValue,
        "units": unitsposition,
        "typeunit": typeunitposition,
      },
      loadvalue: {
        "value": loadvalue,
        "units": loadunits,
        "typeunit": typeunitload,
      },
    };
    handleInputObjectChange("loads-DistributedLoads", PointLoadData,true);
  }

  const removePointLoad = (key) => {
    handleInputObjectChange(`loads-PointLoads.${key}`, undefined);
  }

  const removeDistributedLoad = (key) => {
      handleInputObjectChange(`loads-DistributedLoads.${key}`, undefined);
  }


  //const removeSupport = (path) => {    
  //  handleInputObjectChange(`supports.${path}`, undefined);
  //}
  

  //************************************************************************************
  //************Constants*******
  //************************************************************************************
  const optionsSeismicDetail = [
    { value: 'none', label: 'none' },
    { value: 'DMI', label: 'DMI' },
    { value: 'DMO', label: 'DMO' },
    { value: 'DES', label: 'DES' },
  ];

  const optionsStations = [
    { value: 'automatic', label: 'automatic' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
  ];

  const optionsTypeSections = [
    { value: 'Rectangle', label: 'Rectangle' },
    //{ value: 'Box', label: 'Box' },
    //{ value: 'Box Rounded', label: 'Box Rounded' },
    //{ value: 'Channel', label: 'Channel' },
    //{ value: 'I', label: 'I' },
  ];

  const optionsMaterials = [
    { value: 'Steel Gr 36', label: 'Steel Gr 36' },
    { value: 'Steel Gr 50', label: 'Steel Gr 50' },
    { value: 'Concrete', label: 'Concrete' },
    //{ value: 'Timber', label: 'Timber' }, 
  ];

  const optionsSupports = [
    { value: 'Fixed', label: 'Fixed' },
    { value: 'Pinned', label: 'Pinned' },
    { value: 'Rolled', label: 'Rolled' }, 
  ];

  const optionsMaterial = [
    { value: 'Steel', label: 'Steel' },
    { value: 'Concrete', label: 'Concrete' },    
    { value: 'Timber', label: 'Timber' }, 
  ];

  const optionstypeCombination = [
    { value: 'ASCE-7', label: 'ASCE-7' },
    { value: 'NSR-10', label: 'NSR-10' },
    { value: 'CUSTOM', label: 'CUSTOM' },    
  ];

  const typeLoadASCE7 = [
    { value: 'Dead', label: 'Dead' },
    { value: 'Live', label: 'Live' },    
    { value: 'Live Roof', label: 'Live Roof'},    
    { value: 'Wind', label: 'Wind' },    
    { value: 'Snow', label: 'Snow' },    
    { value: 'Rain', label: 'Rain' },    
  ];

  const typeLoadNSR10 = [
    { value: 'Selfweight', label: 'Selfweight' },
    { value: 'Dead', label: 'Dead' },
    { value: 'Live', label: 'Live' },    
    { value: 'Live Roof', label: 'Live Roof'},    
    { value: 'Wind', label: 'Wind' },    
    { value: 'Snow', label: 'Snow' },       
  ];

  const combinations=[
    { value: '1.4D', label: '1.4D' },
    { value: '1.2D+1.6L+0.5Lr', label: '1.2D+1.6L+0.5Lr' },
    { value: '1.2D+1.6L+0.3R', label: '1.2D+1.6L+0.3R' },
    { value: '1.2D+1.6L+0.5S', label: '1.2D+1.6L+0.5S'},    
    { value: '1.2D+1.6Lr+L', label: '1.2D+1.6Lr+L' },
    { value: '1.2D+W+L+0.5Lr', label: '1.2D+1.6Lr+L' },
  ]

  const typeLoad=[
    {value: 'Distributed Load', label: 'Distributed Load'},
    {value: 'Point Load', label: 'Point Load'}
  ]

  const dummy=(dummy)=>{
    console.log(dummy)
  }

  const rectData = {
    base: 0.30,
    height: 0.40
  };
  const [isEspandedValue,setisExpandedValue]=useState(isExpanded)

  console.log("Console from ViewIndexM27.js")
  console.log("isExpanded value=")
  console.log(props.isExpanded)



  
  
  
  return (
    <Box paddingLeft={2} paddingRight={2} paddingBottom={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={props.isExpanded ?4:4} lg={props.isExpanded ? 2: 2.3333} xl={props.isExpanded ? 1.911:2.3}>
          <Box sx={{ backgroundColor: colors.primary.primary, borderRadius: 2.5, padding: 4, 
          height: '700px', 
          overflowY: 'auto' 
          }}>
            <Box 
              sx={{
           
                borderRadius: 0,
                padding: 0,
                display: 'flex',
                justifyContent: 'center', 
                alignItems: 'center', 
              }}            
              >
              <Typography variant="h5" fontWeight="bold" sx={{color:colors.primary.third}}>Input Data</Typography>            
              </Box>
              <Box
              sx={{
                '& > :not(style)': { mt: 2}, 
              }}                 
              >
                
                <Typography variant="h5" fontWeight="bold" sx={{color:colors.primary.third}}>{N["Units"]}</Typography>            
                <Typography variant="h6"  textAlign="left">Units: {units}</Typography>
                <Typography variant="h5" fontWeight="bold" sx={{color:colors.primary.third}}>{N["Beam"]}</Typography>            
                <IngenniInput 
                 label={N["Beam Length"] ? N["Beam Length"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Beam Length" units={unitData ? unitData.length : "Loading..."}                                      
                 OnSetChange={(newValue,units,typeunit) => handleInputChange("Beam Length", newValue,units,typeunit)} 
                 valuei={formData['Beam Length'] ? formData['Beam Length'].value : ''}  
                 error={false}
                 labelError="error 1"
                />
                {/*Supports Elements*/}
                <Typography variant="h5" fontWeight="bold" sx={{color:colors.primary.third}}>Supports:</Typography>  

                <IngenniInput 
                 label={N["Position"] ? N["Position"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Position" units={unitData ? unitData.length : "Loading..."}                                      
                 OnSetChange={(newValue, units, typeunit) => setDataSupport({
                  position: newValue,
                  units: units,
                  typeunit: typeunit
                })}
                 error={false}
                 labelError="error 1"
                />

                <IngenniSelectRestricted label="Type Support" options={optionsSupports}                  
                OnSetChange={(newValue,units,typeunit) => setTypeSupport(newValue)}
                ></IngenniSelectRestricted>

                <Button 
                  onClick={() => addSupport(                   
                    `support_${Date.now()}`,
                    dataSupport.position, 
                    dataSupport.units, 
                    dataSupport.typeunit, 
                    typeSupport
                  )} 
                  startIcon={<AddIcon />} 
                  variant="outlined"
                >
                  Add Support
                </Button>


                <Box sx={{ marginTop: 2 }}>
                  {formData.supports && Object.entries(formData.supports).map(([key, support], index) => (
                    <ListItem
                      key={key}
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={() => removeSupport(key)}>
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary={`Support ${index + 1}`}
                        secondary={`Position: ${support.Position.value} ${support.Position.units}, Type: ${support.typeSupport.typeSupport}`}
                      />
                    </ListItem>
                  ))}
                </Box>

                {/*Supports Elements*/}
                <Typography variant="h5" fontWeight="bold" sx={{color:colors.primary.third}}>Section:</Typography>  
                <IngenniSelectRestricted label="Type Section" options={optionsTypeSections}  
                valuei={formData['Type Section'] ? formData['Type Section'].value : ''}
                OnSetChange={(newValue,units,typeunit) => handleInputChange("Type Section", newValue,units,typeunit)}                  
                ></IngenniSelectRestricted>

                <IngenniInput 
                 label={N["Base:"] ? N["Base:"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Base" units={unitData ? unitData.length : "Loading..."}                                      
                 OnSetChange={(newValue,units,typeunit) => handleInputChange("Base", newValue,units,typeunit)} 
                 valuei={formData['Base'] ? formData['Base'].value : ''}  
                 error={false}
                 labelError="error 1"
                />

                <IngenniInput 
                 label={N["Height:"] ? N["Height:"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Height" units={unitData ? unitData.length : "Loading..."}                                      
                 OnSetChange={(newValue,units,typeunit) => handleInputChange("Height", newValue,units,typeunit)} 
                 valuei={formData['Height'] ? formData['Height'].value : ''}  
                 error={false}
                 labelError="error 1"
                />
                <div>
                  <h1>My Rectangle Chart</h1>
                  {/*<RectangleChart type="rectangle" data={rectData} />*/}
                </div>

                <Typography variant="h5" fontWeight="bold" sx={{color:colors.primary.third}}>Material:</Typography>  
                <IngenniInput 
                 label={N["Young's Modulus (E):"] ? N["Young's Modulus (E):"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Young's Modulus (E)" units={unitData ? unitData.stress : "Loading..."}                                      
                 OnSetChange={(newValue,units,typeunit) => handleInputChange("Elasticity Module", newValue,units,typeunit)} 
                 valuei={formData['Elasticity Module'] ? formData['Elasticity Module'].value : ''}  
                 error={false}
                 labelError="error 1"
                />
                {/*Materials and Section*/}

                {/*Loads*/}
                {/*Materials and Section*/}
              </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={props.isExpanded? 10.05 : 9.7} xl={props.isExpanded? 10.05 : 9.7}>
        <Box  
           sx={{justifyContent: 'space-between'}} 
          >
            <Button
              onClick={() => toggleActiveButton('input')}              
              variant={activeComponent === 'input' ? "contained" : "outlined"}

              sx={{
                flexGrow: 1,
                marginRight: '4px', 
                minWidth: '150px'
              }}
            >
              Inputs
            </Button>
            <Button
              variant={activeComponent === 'output' ? "contained" : "outlined"}
              onClick={() => toggleActiveButton('output')}
              sx={{
                flexGrow: 1,
                minWidth: '150px'
              }}
            >
              Outputs
            </Button>
          </Box>

        
          <Box sx={{backgroundColor:"transparent"}}>
            {activeComponent === 'input' &&
          <Grid container spacing={2} item xs={12} sm={12} md={12} lg={props.isExpanded?12.65:12} xl={props.isExpanded?12.65:12}>    
          <Grid item xs={12} sm={12} md={8} lg={props.isExpanded?9.9:9.15} xl={props.isExpanded?9.7:9.15}>
            <Box sx={{backgroundColor:"transparent"}}>            
              {/* Content for Box 2 */}
              {/*<Typography>Here Graphics</Typography>*/}


              <TreeJs></TreeJs>
            </Box>
          </Grid>
      
          <Grid 
          item xs={12} sm={12} md={8} lg={props.isExpanded?2.3:2.85} xl={props.isExpanded?2.3:2.85}
          >
            <Box
              sx={{
                backgroundColor: colors.primary.primary,
                borderRadius: 2.5,
                padding: 4,
                height: '667px',
                overflowY: 'auto'
              }}
            >
      
              <Box 
                    sx={{
                 
                      borderRadius: 0,
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'center', 
                      alignItems: 'center', 
                    }}            
                    >
              <Typography variant="h5" fontWeight="bold" sx={{color:colors.primary.third}}>Loads & Combinations</Typography>             
              </Box>
      
              <Box sx={{
                '& > :not(style)': { mt: 2}, 
              }}>

              
                <Typography variant="h5" fontWeight="bold" sx={{color:colors.primary.third}}>Loads</Typography>  
                   
      
              <IngenniSelectRestricted label="Type Load" options={typeLoad}                  
              OnSetChange={(newValue,units,typeunit) => setTypeLoad(newValue)}
              ></IngenniSelectRestricted>

              <IngenniSelectRestricted label="Load Patter" options={typeLoadASCE7}                  
              OnSetChange={(newValue,units,typeunit) => setLoadPatter(newValue)}
              ></IngenniSelectRestricted>


      
              
             {TypeLoad === "Point Load" && (
              <Box sx={{
                '& > :not(style)': { mt: 2}, 
              }}>

              <IngenniInput 
                       label={N["Position Load"] ? N["Position Load"] : "Loading..."} valueType="real" width="90%" placeholder="Position Load" units={unitData ? unitData.length : "Loading..."}                                      
                       OnSetChange={(newValue, units, typeunit) => setPositionLoad(prev => ({
                        ...prev,
                        Value: { value: newValue, units, typeunit }
                      }))}
                       error={false}
                       labelError="error 1"
              />

                <IngenniInput 
                  label={N["Point Load"] ? N["Point Load"] : "Loading..."}
                  valueType="real"
                  width="90%"
                  placeholder="Point Load"
                  units={unitData ? unitData.force : "Loading..."}
                  OnSetChange={(newValue, units, typeunit) => setLoad({        
                      value: newValue,
                      units: units,
                      typeunit: typeunit
                  })}
                  error={false}
                  labelError="error 1"
                />

                <Button 
                onClick={() => addPointLoad(                   
                  `pointload_${Date.now()}`,                  
                  Load.value,
                  Load.units,
                  Load.typeunit,
                  positionLoad.Value.value, 
                  positionLoad.Value.units, 
                  positionLoad.Value.typeunit, 
                  LoadPatter
                )} 
                startIcon={<AddIcon />} 
                variant="outlined">
                Add Point Load
              </Button>
              </Box>


              )}


            {TypeLoad === "Distributed Load" && (
              <Box sx={{
                '& > :not(style)': { mt: 2}, 
              }}>

              <IngenniInput 
                label={N["start position load"] ? N["start position load"] : "Loading..."} 
                valueType="real" 
                width="90%" 
                placeholder="start position load" 
                units={unitData ? unitData.length : "Loading..."}                                      
                OnSetChange={(newValue, units, typeunit) => setPositionLoad(prev => ({
                  ...prev,
                  start: { value: newValue, units, typeunit }
                }))}
                error={false}
                labelError="error 1"
              />

              <IngenniInput 
                label={N["end position load"] ? N["end position load"] : "Loading..."} 
                valueType="real" 
                width="90%" 
                placeholder="end position load" 
                units={unitData ? unitData.length : "Loading..."}                                      
                OnSetChange={(newValue, units, typeunit) => setPositionLoad(prev => ({
                  ...prev,
                  end: { value: newValue, units, typeunit }
                }))}
                error={false}
                labelError="error 1"
              />


                <IngenniInput 
                  label={N["Value Distributed Load"] ? N["Value Distributed Load"] : "Loading..."}
                  valueType="real"
                  width="90%"
                  placeholder="Value Distributed Load"
                  units={unitData ? unitData.force : "Loading..."}
                  OnSetChange={(newValue, units, typeunit) => setLoad({        
                      value: newValue,
                      units: units,
                      typeunit: typeunit
                  })}
                  error={false}
                  labelError="error 1"
                />

                <Button 
                onClick={() => addDistributedLoad(                   
                  `pointload_${Date.now()}`,                  
                  Load.value,
                  Load.units,
                  Load.typeunit,
                  positionLoad.start.value, 
                  positionLoad.end.value, 
                  positionLoad.start.units, 
                  positionLoad.start.typeunit, 
                  LoadPatter
                )} 
                startIcon={<AddIcon />} 
                variant="outlined"
              >
                Add Distributed Load
              </Button>
              </Box>


              )}
              {/* Render Point Loads */}
              <Box>
                <Typography variant="h6" fontWeight="bold" sx={{ color: colors.primary.third, mt: 2 }}>
                  Point Loads
                </Typography>
                {formData['loads-PointLoads'] && Object.entries(formData['loads-PointLoads']).map(([key, load], index) => (
                  <ListItem
                    key={key}
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete" onClick={() => removePointLoad(key)}>
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={`Load #${index + 1}: ${load.loadvalue.value} ${load.loadvalue.units}, Pattern: ${load.LoadPatter}`}
                      secondary={`Position: ${load.Position.value} ${load.Position.units}`}
                    />
                  </ListItem>
                ))}
              </Box>
                  
              {/* Render Distributed Loads */}
              <Box>
                <Typography variant="h6" fontWeight="bold" sx={{ color: colors.primary.third, mt: 2 }}>
                  Distributed Loads
                </Typography>
                {formData['loads-DistributedLoads'] && Object.entries(formData['loads-DistributedLoads']).map(([key, load], index) => (
                  <ListItem
                    key={key}
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete" onClick={() => removeDistributedLoad(key)}>
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText
                      primary={`Load #${index + 1}: ${load.loadvalue.value} ${load.loadvalue.units}, Pattern: ${load.LoadPatter}`}
                      secondary={`Start: ${load.PositionStar.value} ${load.PositionStar.units} - End: ${load.PositionEnd.value} ${load.PositionEnd.units}`}
                    />
                  </ListItem>
                ))}
              </Box>



             
      
              <Box>
                <Typography variant="h5" fontWeight="bold" sx={{color:colors.primary.third}}>Combinations</Typography>  
              </Box>   
              </Box>      
              
            </Box>
          </Grid>
        </Grid>
      
          }
            {activeComponent === 'output' && <OutputM27 />}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default IngenniM27;
