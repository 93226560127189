import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Typography,Box } from '@mui/material';
import { PDFDocument,StandardFonts } from 'pdf-lib';
import logoAycardi from './LogotipoOficialAycardiIngenierosCiviles.jpeg';


function DragAndDrop() {



    const [files, setFiles] = React.useState([]);

    const onDrop = useCallback((acceptedFiles) => {
        setFiles((prev) => [...prev, ...acceptedFiles]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'application/pdf',
    });

    const mergePDFs = async () => {
        if (files.length < 2) {
            alert('Sube al menos 2 archivos para unir.');
            return;
        }
    
        const mergedPdf = await PDFDocument.create();
    
        for (let file of files) {
            const fileBuffer = await file.arrayBuffer();
            const pdfDoc = await PDFDocument.load(fileBuffer);
            const pages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
    
            for (let page of pages) {
                // Obtiene el tamaño y el centro de la página
                const { width } = page.getSize();
                const xCenter = width / 2;
    
                // Escalar el contenido de la página
                const scaleAmount = 100;
                {/*
                page.scaleContent(xCenter, 0, { xSkew: 0, ySkew: 0, factor: scaleAmount });
        
                // Desplazar el contenido
                const offsety = 0; 
                const offsetx = 0;
                page.translateContent(offsetx, -offsety);
        
                // Asegurar que la página tiene tamaño de carta
                const letterWidth = 612;
                const letterHeight = 792;
                page.setSize(letterWidth, letterHeight);
                */}
        
                // Añadir la página al PDF combinado
                mergedPdf.addPage(page);
            }
        }
    
      
  
    
    
    
    
    
    
    
    
        const mergedPdfFile = await mergedPdf.save();
    
        const blob = new Blob([mergedPdfFile], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'merged_document.pdf';
        link.click();
    };
   
    const url = "https://ingennical-recources-enterprises.s3.amazonaws.com/merged_document+(6).pdf";
    

    const addWatermarkAndDownload = async () => {
        try {
            
            const pdfBuffer = await fetch(url).then(res => res.arrayBuffer());
            const pdfDoc = await PDFDocument.load(pdfBuffer);
            const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
            // Incrusta la imagen en el documento PDF
            const logoBytes = await fetch(logoAycardi).then(res => res.arrayBuffer());
            const logoImage = await pdfDoc.embedJpg(logoBytes);

            // Descargar el PDF original
            ///-------DATA CONTROL//////
            const marginSize = 28.35; // 1cm en puntos
            const marginSizeLeft=32
            const marginSizeRight=28.8
            const padding=2 // distancia de imagen a lineas de margen
            const logoSizeInPoints = 90; // dimenxion cuadrada de logo
            const thicknessline1=1.2 // grosor lineas margenes
            const hcell1=29  //altura de fila, no mofificar
            const hcell2=53  //altura de fila, no mofificar
            const hcell3=92  //altura de fila, no mofificar
            const spacecell1=23 //distancia de logo a inicio de lineas
            const spacepagenumber=100.1 // distancia de margen a linea vertical de numeracion de pagina
            const spacecell2=120 // distancia entre lineas de pagina y linea divisisoria de filas 1 y 2
            ///-------DATA CONTROL//////


            const pages = pdfDoc.getPages();
            for (let page of pages) {
                const { width, height } = page.getSize();        
               // Logo Aycardi Ingenieros Civiles
                page.drawImage(logoImage, {
                    x: marginSizeLeft+padding,
                    y: page.getHeight() - logoSizeInPoints - marginSize-padding,
                    width: logoSizeInPoints,
                    height: logoSizeInPoints,
                  });

                // Dibujar la línea superior
                page.drawLine({
                  start: { x: marginSizeLeft, y: height - marginSize },
                  end: { x: width - marginSize, y: height - marginSize },
                  thickness: thicknessline1,
                  
                });
          
                // Dibujar la línea superior 2
                page.drawLine({
                  start: { x: marginSizeLeft, y: height - marginSize - logoSizeInPoints - 2*padding},
                  end: { x: width - marginSize, y: height - marginSize - logoSizeInPoints - 2*padding},
                  thickness: thicknessline1,
                  
                });
          
                // Dibujar la línea superior 3
                page.drawLine({
                  start: { x: marginSize+logoSizeInPoints+spacecell1, y: height - marginSize - hcell1},
                  end: { x: width - marginSize, y: height - marginSize - hcell1},
                  thickness: thicknessline1,
                  
                });
          
                // Dibujar la línea superior 4
                page.drawLine({
                  start: { x: marginSize+logoSizeInPoints+spacecell1, y: height - marginSize - hcell2},
                  end: { x: width - marginSize, y: height - marginSize - hcell2},
                  thickness: thicknessline1,
                  
                });
          
             
          
                // Dibujar la línea superior 7 Vetical
                page.drawLine({
                  start: { x: width - marginSize - spacepagenumber-spacecell2, y: height-marginSize-hcell1},
                  end: { x: width - marginSize - spacepagenumber-spacecell2, y: height-marginSize-logoSizeInPoints-2*padding+hcell1},
                  thickness: thicknessline1,
                  
                });
          
                // Dibujar la línea inferior
                page.drawLine({
                  start: { x: marginSizeLeft, y: marginSize },
                  end: { x: width - marginSize, y: marginSize },
                  thickness: thicknessline1,
                  
                });
          
                // Dibujar la línea izquierda
                page.drawLine({
                  start: { x: marginSizeLeft ,y: marginSize },
                  end: { x: marginSizeLeft, y: height - marginSize },
                  thickness: thicknessline1,
                  
                });
          
                // Dibujar la línea derecha
                page.drawLine({
                  start: { x: width - marginSizeRight, y: marginSize },
                  end: { x: width - marginSizeRight, y: height - marginSize },
                  thickness: thicknessline1,                  
                });
  
              
              
            }

            // Añadir la imagen al PDF
    

            const pdfBytes = await pdfDoc.save();

            // Descargar el PDF modificado
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'document_with_watermark.pdf';
            link.click();
        } catch (error) {
            console.error("Error al procesar y descargar el PDF:", error);
        }
    };
    return (
        <div>
            <Box>
            <div
                {...getRootProps({
                    className: 'dropzone',
                })}
                style={{ border: '2px dashed gray', padding: '20px', textAlign: 'center' }}
            >
                <input {...getInputProps()} />
                <p>Arrastra y suelta archivos PDF aquí, o haz clic para seleccionarlos</p>
            </div>
            {files.length > 0 && (
                <ul>
                    {files.map((file, idx) => (
                        <li key={idx}>{file.name}</li>
                    ))}
                </ul>
            )}
            <Button variant="contained" color="primary" onClick={mergePDFs}>
                Unir y descargar
            </Button>

            <Box mt={10}>
                <Typography variant='h1'>Url Archivo de ejemplo</Typography>                
                <a href={"https://ingennical-recources-enterprises.s3.amazonaws.com/merged_document+(6).pdf"} download>Archivo Ejemplo</a>
            </Box>
            
            </Box>

            <Box mt={10}>
                <Typography variant='h1'>Descargar memorias de calculo</Typography>
                <Button variant="contained" color="primary" onClick={addWatermarkAndDownload}>
                    CREAR MEMORIAS DE CALCULO
                </Button>
            </Box>
        </div>
    );
}

export default DragAndDrop;
