import React from 'react';
import { Box, Typography } from '@mui/material';

const McustomEnterprise02 = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh" sx={{ border: 1, borderRadius: '16px' }}>
      <Typography variant="h2">Hello from McustomEnterprise02!</Typography>
    </Box>
  );
};

export default McustomEnterprise02;
