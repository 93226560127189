import { FormikContext } from 'formik';
import { useMemo, useEffect } from 'react';

const useUnits = (unidades) => {
  // `useEffect` se usa para efectos secundarios, como en este caso, imprimir en consola.
  useEffect(() => {
    if (unidades == null) {
      console.log('Aún no ha llegado');
    } else if (typeof unidades === 'string' && unidades.trim() !== '') {
      console.log('String que llegó:', unidades);
    } else {
      console.warn('useUnits: Expected a string but received', unidades);
    }
  }, [unidades]); // Se ejecuta al montar y cada vez que `unidades` cambia.

  // `useMemo` se utiliza para memorizar el cálculo de los valores basados en `unidades`.
  return useMemo(() => {
    if (typeof unidades !== 'string' || unidades.trim() === '') {
      // En caso de que `unidades` no sea un string o sea una cadena vacía, retorna un objeto vacío.
      return {};
    }

    const [force, length, stress] = unidades.split(',');

    let areab = 'mm2'; // Valor predeterminado para sistemas no imperiales.
    let temperature='°C'; // Valor predeterminado para sistemas no imperiales.
    let degrees='°'
    if (force === 'kip') {
      areab = 'in2'; // Sistema imperial.
      temperature='°F'
    }



    return { 'force':force, 'length':length, 'length2':`${length}2`,'length3':`${length}3`,
    'stress':stress,'areabar': areab,'forcelength':`${force}/${length}`,'moment':`${force}-${length}`,'temperature':`${temperature}` };
  }, [unidades]);
};

export default useUnits;
