import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTheme } from "@mui/material";
import { tokens } from '../../theme';

const EditEnterpriseDialog = ({ open, onClose, onUpdate }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [newName, setNewName] = useState('');

  const handleUpdate = () => {
    onUpdate(newName);
    onClose(); // Cierra el cuadro de diálogo tras actualizar
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      sx={{
        '& .MuiDialog-paper': { // Aplica estilos al papel del diálogo
          minWidth: '350px', // Ancho mínimo del cuadro de diálogo
          backgroundColor: colors.primary.primary3, // Color de fondo personalizado
        },
      }}
   
    >
      <DialogTitle>Edit Enterprise Name</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="New Enterprise Name"
          type="text"
          fullWidth
          variant="outlined"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={handleUpdate}>Update</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditEnterpriseDialog;