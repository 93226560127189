// handleInputChange.js
export function createHandleInputChange(setDataValues, temporalInputSave) {
    return (event) => {
      const { name, value } = event.target;
      setDataValues(prevValues => {
        const updatedValues = { ...prevValues, [name]: value };
        temporalInputSave(updatedValues);
        return updatedValues;
      });
    };
  }
  