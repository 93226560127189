import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../contexts/ContextDataProvider/DataProvider.js';
import { useTheme, Box, Typography, List, ListItem, ListItemText, ListItemIcon, Button, Collapse, IconButton,TextField,FormControl, InputLabel,Select,MenuItem} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess'; // Importa el icono de contraer
import { tokens } from '../../theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ListItemButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddModalProjects from './AddModalProjects.js';
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog.js';
import EditProjectDialog from './EditProjectDialog.js';
import EditSubProjectDialog from './EditSubProjectDialog.js';

//None

export function ProjectsView() {
  const { projects, sheets, addProject,addSubproject,deleteProject,deleteSubproject,updateSubprojects } = useContext(DataContext);
  const [sortOrder, setSortOrder] = useState('alphabetic');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubproject,setIsSubproject]= useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedProjectName, setSelectedProjectName] = useState('');
  const [selectedSubprojectId, setSelectedSubprojectId] = useState('');
  const [selectedSubprojectName, setSelectedSubprojectName] = useState('');
  const [confirmDeleteSubprojectOpen, setConfirmDeleteSubprojectOpen] = useState(false);
  const [isEditProjectOpen, setIsEditProjectOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState({ id: '', nombre: '', codigo: '' });
  const [isEditSubProjectOpen, setIsEditSubProjectOpen] = useState(false);
  const [currentSubProject, setCurrentSubProject] = useState({ id: '', nombre: '', codigo: '' });




  console.log(projects)

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const safesheets = Array.isArray(sheets) ? sheets : [];
  


  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [searchText, setSearchText] = useState('');
  const [openProjects, setOpenProjects] = useState({});
  const [openSubprojects, setOpenSubprojects] = useState({});
  const [openDefault, setOpenDefault] = useState(false);

  const handleProjectClick = (projectId) => {
    setOpenProjects((prev) => ({ ...prev, [projectId]: !prev[projectId] }));
  };

  const handleSubprojectClick = (projectId, subprojectId) => {
    const key = `${projectId}-${subprojectId}`;
    setOpenSubprojects((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleDefaultClick = () => {
    setOpenDefault(!openDefault);
  };

  const handleRightClickOnProject = (event, proyecto) => {
    event.preventDefault(); // Evita el menú contextual predeterminado
    //setSelectedProject(proyecto); // Asume que tienes un estado para esto
    //setIsSubproject(true);
    setIsModalOpen(true);
  };

  const sortedProjects = React.useMemo(() => {
    switch (sortOrder) {
      case 'alphabetic':
        return [...projects].sort((a, b) => a.nombre.localeCompare(b.nombre));
      case 'code':
        return [...projects].sort((a, b) => a.codigo.localeCompare(b.codigo));
      default:
        return projects;
    }
  }, [projects, sortOrder]);
  

  const filteredProjects = searchText === '' ? sortedProjects : sortedProjects.filter(project => 
    project.nombre.toLowerCase().includes(searchText.toLowerCase()) || 
    project.codigo.toLowerCase().includes(searchText.toLowerCase())
  );
  

  ///Control modal to create projects
  const handleOpenModal = () => {
    setIsSubproject(false); // Asegúrate de que isSubproject sea false cuando añades un nuevo proyecto
    setIsModalOpen(true);
  };
  

  const handleOpenModalSubproject = () => {
    setIsSubproject(true); // Establece isSubproject a true para nuevos subproyectos
    setIsModalOpen(true);
  };
  
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsSubproject(false); // Resetea isSubproject a false al cerrar
  };
  

  const handleAddProject = (projectName, projectCode,subprojects) => {    
    addProject({ nombre: projectName, codigo: projectCode, subproyectos: subprojects });
    handleCloseModal();
  };

  const handleDeleteClick = (projectId, projectName) => {
    setSelectedProjectId(projectId);
    setSelectedProjectName(projectName); // Almacenar el nombre del proyecto seleccionado
    setConfirmDeleteOpen(true);
  };
  

  const onConfirm=(id)=>{
    deleteProject(id)
  }

  const handleDeleteSubprojectClick = (projectId, subprojectName) => {
    setSelectedProjectId(projectId);
    setSelectedSubprojectName(subprojectName);
    setConfirmDeleteSubprojectOpen(true); // Esta línea abre el diálogo de confirmación para subproyectos
  };

  const handleEditProjectClick = (projectId, projectName, projectCode) => {
    setCurrentProject({ id: projectId, nombre: projectName, codigo: projectCode });
    setIsEditProjectOpen(true);
  };
  
  
  


  return (
    <Box sx={{paddingBottom:2, }} >
    <Box
    
    m={7.5} padding={5} borderRadius={2.5} mb={5} sx={{   
      maxHeight: '80%',
    overflow: 'auto',   
      backgroundColor: colors.primary.primary,
      maxWidth: isDesktop ? '600px' : 'auto',
      marginRight: 'auto',
      marginLeft: 'auto',
      '@media (max-width:1280px)': {
        marginLeft: 2,
        marginRight: 2,
        marginBottom:5
      },
      '@media (max-width:600px)': {
        marginLeft: 1,
        marginRight: 1,
        marginBottom:5
      },
      //border: theme.palette.mode === 'light' ? `1px solid ${colors.primary.secondary}` : 'none',
      boxShadow: theme.palette.mode === 'light' ? `0 0 5px ${colors.grey[500]}` : 'none',
      //border: theme.palette.mode === 'light' ? `1px solid ${colors.grey[500]}` : 'none',
      //boxShadow: theme.palette.mode === 'light' ? `0 0 5px ${colors.blueAccent.contrast1}` : 'none',
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        {isDesktop && <AccountTreeIcon sx={{mb:1}}/>}

        <Typography variant="h3" gutterBottom>My Projects</Typography>
        <Box mr={1} ml={1}>
          <Button variant="outlined" onClick={handleOpenModalSubproject}>New SubProject</Button>
        </Box>
        <Box mr={1} ml={1}>
          <Button variant="contained" onClick={handleOpenModal} >New Project</Button>
        </Box>
      </Box>
      <Box mt={2} >
      <TextField 
          label="Search project" 
          variant="outlined" 
          value={searchText}
          size="small"
          onChange={(e) => setSearchText(e.target.value)}
          sx={{ marginBottom: 2, width: '100%' }}
        />
      </Box>

      <Box mt={1}sx={{ minWidth: 120 }}>
        <FormControl fullWidth size="small">
          <InputLabel id="sort-order-label" sx={{ lineHeight: '1.4375em', fontSize: '0.875rem' }}>Sort Order</InputLabel>
          <Select
            labelId="sort-order-label"
            id="sort-order"
            value={sortOrder}
            label="Sort Order"
            sx={{
              height: 42, // Puedes ajustar este valor según tus necesidades
              '.MuiSelect-select': { // Targeting the inner select element for additional styling if needed
                paddingTop: '6px',
                paddingBottom: '6px',
              },
              '.MuiOutlinedInput-notchedOutline': {
                top: 0,
              },
            }}
            onChange={(e) => setSortOrder(e.target.value)}
          >
            <MenuItem value="default">Default</MenuItem>
            <MenuItem value="alphabetic">Alphabetic</MenuItem>
            <MenuItem value="code">Code</MenuItem>
          </Select>
        </FormControl>
      </Box>



      <List>
        {filteredProjects.map((proyecto) => (
          <React.Fragment key={proyecto.id}>
            <ListItemButton onClick={() => handleProjectClick(proyecto.id)}
            
            >
              <ListItemIcon><FolderIcon sx={{ color: colors.blueAccent.contrast1 }} /></ListItemIcon>
              <ListItemText primary={`${proyecto.nombre} - ${proyecto.codigo}`} />
              
              <Box>

                {/* Icono para eliminar */}
                  <IconButton onClick={(event) => {
                  handleDeleteClick(proyecto.id,proyecto.nombre)
                  event.stopPropagation(); // Previene que el click en el icono abra o cierre el ListItem
                  console.log("Eliminar proyecto", proyecto.id);
                  // Aquí podrías agregar tu lógica para manejar la eliminación
                }}>
                  <DeleteIcon sx={{color:colors.redAccent.contrast1}}/>
                </IconButton>


                {/* Icono para editar */}
                <IconButton onClick={(event) => {
                   event.stopPropagation();
                  handleEditProjectClick(proyecto.id, proyecto.nombre, proyecto.codigo)}}               
                >
                  <EditIcon sx={{ color: colors.blueAccent[400] }} />
                </IconButton>

         
              
              </Box>
                
              {openProjects[proyecto.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}

            </ListItemButton>
            
            <Collapse in={openProjects[proyecto.id]} timeout="auto" unmountOnExit>
              {proyecto.subproyectos.map((subproyecto) => (
                <React.Fragment key={`${proyecto.id}-${subproyecto}`}>
                  <ListItemButton style={{ paddingLeft: 32 }} onClick={() => handleSubprojectClick(proyecto.id, subproyecto)}>
                    
                    <ListItemIcon><FolderIcon sx={{ color: colors.yelloAccent.contrast1}} /></ListItemIcon>
                    <ListItemText primary={subproyecto.split(',')[0]}/>
                    
                    <Box>
                        {/* Botón de eliminar para subproyecto */}
                      <IconButton onClick={(event) => {
                        event.stopPropagation(); // Evita que el clic se propague al ListItemButton
                        console.log("Eliminar subproyecto", subproyecto);
                        handleDeleteSubprojectClick(proyecto.id, subproyecto)
                        // Aquí va tu lógica para eliminar el subproyecto
                      }}>
                        <DeleteIcon sx={{color:colors.redAccent.contrast1}}/>
                      </IconButton>

                      {/* Botón de editar para subproyecto */}
                      <IconButton onClick={(event) => {
                          event.stopPropagation(); // Evita que el clic se propague al ListItemButton
                          const [nombreSubproyecto, idSubproyecto] = subproyecto.split(',');
                          setCurrentSubProject({ nombre: nombreSubproyecto, id: idSubproyecto }); 
                          setIsEditSubProjectOpen(true); 
                        }}>
                          <EditIcon sx={{color:colors.blueAccent[400]}}/>
                        </IconButton>

                      
                    </Box>

                    {openSubprojects[`${proyecto.id}-${subproyecto}`] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  
                    

                  </ListItemButton>
                  
                  <Collapse in={openSubprojects[`${proyecto.id}-${subproyecto}`]} timeout="auto" unmountOnExit>
                    {safesheets.filter(sheet => sheet.project === proyecto.id && sheet.subproject.split(',')[1] === subproyecto.split(',')[1]).map((sheet) => (
                      <ListItem key={sheet.id} style={{ paddingLeft: 64 }}>
                        <ListItemIcon><InsertDriveFileIcon /></ListItemIcon>
                        <ListItemText primary={sheet.nameSheet} />
                        {isDesktop && (
                          <Typography>{sheet.isModuleNickName}</Typography>
                        )}
                        <Box ml={2}>
                         <Button>Open</Button>
                         </Box>
                      </ListItem>
                    ))}
                  </Collapse>
                </React.Fragment>
              ))}
              {/* Additional rendering for sheets belonging to the project but not matching any subproject */}
              {safesheets.filter(sheet => sheet.project === proyecto.id && !proyecto.subproyectos.includes(sheet.subproject)).map((sheet) => (
                <ListItem key={sheet.id} style={{ paddingLeft: 32 }}>
                  <ListItemIcon><InsertDriveFileIcon /></ListItemIcon>
                  <ListItemText primary={sheet.nameSheet} />
                  {isDesktop && (
                    <Typography>{sheet.isModuleNickName}</Typography>
                  )}
                  <Box ml={2}>
              <Button>Open</Button>
              </Box>
                </ListItem>
              ))}
            </Collapse>

            </React.Fragment>
        ))}
        {/* Default project section */}
        <ListItemButton onClick={handleDefaultClick}>
          <ListItemIcon><FolderIcon  sx={{ color: colors.blueAccent.contrast1 }} /></ListItemIcon>
          <ListItemText primary="Default" />
          {openDefault ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Collapse in={openDefault} timeout="auto" unmountOnExit>
          {safesheets.filter(sheet => sheet.project === 'default').map((sheet) => (
            <ListItem key={sheet.id} style={{ paddingLeft: 32 }}>
              <ListItemIcon><InsertDriveFileIcon /></ListItemIcon>
              <ListItemText primary={sheet.nameSheet} />
              {isDesktop && (
                <Typography>{sheet.isModuleNickName}</Typography>
              )}
           
              <Box ml={2}>
              <Button>Open</Button>
              </Box>
            </ListItem>
          ))}
        </Collapse>
      </List>
    </Box>
    <AddModalProjects 
      open={isModalOpen} 
      onClose={handleCloseModal} 
      onAddProject={handleAddProject} 
      isSubproject={isSubproject}   
      />



<ConfirmDeleteDialog
  open={confirmDeleteOpen}
  onClose={() => setConfirmDeleteOpen(false)}
  onConfirm={() => {
    deleteProject(selectedProjectId);
    setConfirmDeleteOpen(false);
  }}
  nameproject={selectedProjectName} // No necesitas cambiar esto
  message={`Are you sure you want to delete the project: ${selectedProjectName}?`} // Mensaje personalizado
/>


<ConfirmDeleteDialog
  open={confirmDeleteSubprojectOpen}
  onClose={() => setConfirmDeleteSubprojectOpen(false)}
  onConfirm={() => {
    deleteSubproject(selectedProjectId, selectedSubprojectName); // Asegúrate de tener esta función en tu contexto
    setConfirmDeleteSubprojectOpen(false);
  }}
  nameproject={selectedSubprojectName.split(',')[0]} // Usar solo la parte del nombre para la visualización
  message={`Are you sure you want to delete the subproject: ${selectedSubprojectName.split(',')[0]}?`} // Mensaje personalizado para subproyectos
/>

<EditProjectDialog
  open={isEditProjectOpen}
  onClose={() => setIsEditProjectOpen(false)}
  project={currentProject}
  onUpdate={(newName, newCode) => {
    // Aquí puedes llamar a una función del contexto para actualizar el proyecto
    //updateProject(currentProject.id, newCode, newName);
    setIsEditProjectOpen(false);
  }}
/>

<EditSubProjectDialog
  open={isEditSubProjectOpen}
  onClose={() => setIsEditSubProjectOpen(false)}
  project={currentSubProject} // Aquí puedes necesitar ajustar según cómo estés manejando los subproyectos
  onUpdate={(newName, newCode) => {
    
    setIsEditSubProjectOpen(false);
  }}
/>

<EditSubProjectDialog
  open={isEditSubProjectOpen}
  onClose={() => setIsEditSubProjectOpen(false)}
  subproject={currentSubProject} // Cambiando `project` por `subproject` para reflejar mejor lo que se está editando
  onUpdate={async (newName) => { // Aquí solo pasamos newName porque no usamos newCode
    // Asume que tienes acceso a `selectedProjectId` para identificar el proyecto al cual pertenece el subproyecto
    await updateSubprojects(selectedProjectId, [newName]); // Pasamos newName como parte de un arreglo porque tu API espera un arreglo de nombres
    setIsEditSubProjectOpen(false);
  }}
/>


    </Box>
  );
}
