import { Typography,Box, IconButton, useTheme } from "@mui/material";
import { useContext,useEffect } from "react";

import { ColorModeContext, tokens } from "../../theme";
import CloseIcon from '@mui/icons-material/Close';
import TableRowsIcon from '@mui/icons-material/TableRows';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';
import { Menu, MenuItem,Item } from '@mui/material';
import {Link,NavLink} from 'react-router-dom';


const Topbar = ({onLogout}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  
  
  const appBarStyles = {
    position: 'fixed',
    top: 0,
    left:  isMobile ? 0 : 270.4,
    right: 0,
    zIndex: 9999,
   
  };

  
  

  ///control menu
  const rol=localStorage.getItem('rol'); 
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setIsMenuOpen(false);
  };
  
  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = () => {
    setIsMenuOpen(false);    
  };


  const jump=(vinculo)=>{
    <Link to={vinculo}></Link>
    
    }
    const linkStyles = {
      textDecoration: 'none',
      color: 'inherit',
    }; 

    const [idioma, setIdioma] = useState(localStorage.getItem('idioma') || 'en');
    const getTexts = (languaje) => {
      if (languaje === 'es') {
        // Textos en español
        return {
          new:"New Sheet",
          logOut:"logout",
          myprojects:'My Projects',
          mysheets:'My Sheets',
          myuser:'My User',
          myenterprise:'My Enterprise',
          theme:'light/dark mode'
  
        };
      } else {
        // Textos en inglés 
        return {
          new:"New Sheet",
          logOut:"logout",
          myprojects:'My Projects',
          mysheets:'My Sheets',
          myuser:'My User',
          myenterprise:'My Enterprise',
          theme:'light/dark mode'
        };
      }
    };
    const texts=getTexts(idioma)



    return (
      
      <Box style={appBarStyles} display="flex" justifyContent={isMobile ? 'space-between' : 'flex-end'}  p={0.05}
      >   
        
        {/* BOTON MENU MOBILE */}
        <Box display="flex"
        sx={{display: isMobile ? 'block' : 'none'}}>   
          <IconButton onClick={handleMenuClick}>
            <TableRowsIcon />
          </IconButton>
          <Menu
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        style={{
          marginTop: '20px',
          marginLeft: '0px',
        }}
        anchorEl={null}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        MenuListProps={{
          sx: {
            '& .MuiMenuItem-root': {
              fontSize: '1rem',
              padding: '5px',
            },
          },
        }}
        >

        <MenuItem onClick={handleClose}>
            <NavLink to="/" activeClassName="active"  style={linkStyles}>
              <Typography variant="body1"> {texts.new}</Typography>
            </NavLink>
        </MenuItem>
        {/*
        <MenuItem onClick={handleClose}>
        <NavLink to="/Dashboard" activeClassName="active"  style={linkStyles}>
              <Typography variant="body1">Dashboard</Typography>
            </NavLink> 
        </MenuItem>*/}
      
         
        <MenuItem onClick={handleClose}>
        <NavLink to="/mysheets" activeClassName="active"  style={linkStyles}>
              <Typography variant="body1">{texts.mysheets}</Typography>
            </NavLink> 
        </MenuItem>

        <MenuItem onClick={handleClose}>
        <NavLink to="/myprojects" activeClassName="active"  style={linkStyles}>
              <Typography variant="body1">{texts.myprojects}</Typography>
            </NavLink> 
        </MenuItem>

        
        <MenuItem onClick={handleClose}>
        <NavLink to="/user" activeClassName="active"  style={linkStyles}>
              <Typography variant="body1">{texts.myuser}</Typography>
            </NavLink> 
        </MenuItem>
        {rol === 'owner' && (
        <MenuItem onClick={handleClose}>
        <NavLink to="/myenterprise" activeClassName="active"  style={linkStyles}>
              <Typography variant="body1">{texts.myenterprise}</Typography>
            </NavLink> 
        </MenuItem>)}
       
        <MenuItem >        
          <Typography onClick={colorMode.toggleColorMode} variant="body1">{texts.theme}</Typography>      
        </MenuItem>


        <MenuItem onClick={onLogout}>
        
              <Typography variant="body1">{texts.logOut}</Typography>
            
        </MenuItem>
     
      </Menu>
        </Box>


      
      
    </Box>
  );
};

export default Topbar;
