import React, { useState } from 'react';
import { Button,Typography,Box } from '@mui/material';


function IngenniM10() {
  // ... tu código existente

  return (
    <div>      
        <Box>
        <Typography variant="h1">Has entrado</Typography>
        <Typography variant="h1">Has entrado</Typography>
        <Typography variant="h1">Al modulo M10</Typography>
        </Box>      
    </div>
  );
}

export default IngenniM10;

