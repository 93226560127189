import React, { useEffect, useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material';
import { DataContext } from '../../contexts/ContextDataProvider/DataProvider';
import { tokens } from '../../theme';

// Asumimos que `subproject` es pasado en lugar de `project`
const EditSubProjectDialog = ({ open, onClose, subproject, onUpdate }) => {
  const { updateSubprojects } = useContext(DataContext); // Cambia esto según la función real en tu contexto
  const [newName, setNewName] = useState(subproject?.nombre || '');
  const [newCode, setNewCode] = useState(subproject?.codigo || '');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    setNewName(subproject?.nombre || '');
    setNewCode(subproject?.codigo || '');
  }, [subproject]);

  const handleUpdate = async () => {
    await updateSubprojects(subproject.id, newName, newCode); // Ajusta según tu implementación
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { backgroundColor: colors.primary.primary3 } }}>
      <DialogTitle>Edit Subproject</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Subproject Name"
          type="text"
          fullWidth
          variant="outlined"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Subproject Code"
          type="text"
          fullWidth
          variant="outlined"
          value={newCode}
          onChange={(e) => setNewCode(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">Cancel</Button>
        <Button onClick={handleUpdate} variant="contained">Update</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSubProjectDialog;
