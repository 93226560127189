import { useState, useEffect, useCallback, useContext } from 'react';
import { convertFunction } from '../useConversionUnits/conversiones';
import { DataContext } from '../../../contexts/ContextDataProvider/DataProvider';
import { ConsoleSqlOutlined } from '@ant-design/icons';

const STORAGE_KEY = 'ElementDataForms';

const applyUnitConversionToObject = (dataObject, currentUnits) => {
    // Función recursiva para recorrer los objetos anidados
    const processElement = (element) => {
        // Si el elemento tiene las propiedades `value`, `units` y `typeunit`
        if (element && element.value !== undefined && element.units && element.typeunit && currentUnits[element.typeunit]) {
            const newUnit = currentUnits[element.typeunit];
            const newValue = convertFunction(element.value, element.units, newUnit);
            if (element.units !== newUnit) {
                element.value = newValue;
                element.units = newUnit;
            }
        }
    };

    // Recorrer todos los campos del objeto para aplicar la conversión de ser necesario
    const recursiveProcess = (obj) => {
        for (const key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                processElement(obj[key]); // Aplicar conversión si cumple con la estructura
                recursiveProcess(obj[key]); // Procesar el siguiente nivel del objeto
            }
        }
    };

    recursiveProcess(dataObject);
};

const useFormData = (initialState, activeElement,currentUnits) => {
    const [formData, setFormData] = useState({});
    const {activeSheet,updateActiveSheet } = useContext(DataContext);
  



    
    useEffect(() => {
        console.log("Current Units desde useFormData.js");
        console.log(currentUnits);
        let allData = JSON.parse(localStorage.getItem(STORAGE_KEY)) || {};
        console.log(allData)
        applyUnitConversionToObject(allData, currentUnits);
        setFormData(allData[activeElement] || initialState);
        localStorage.setItem(STORAGE_KEY, JSON.stringify(allData));
    }, [currentUnits]);

useEffect(() => {console.log("Data contex Element Active from useFormData.js")

console.log(activeSheet)
},[activeElement])

useEffect(() => {console.log("Data contex Element Active from useFormData.js")

console.log(formData)
},[formData])
    

    // Cargar todos los datos al montar el componente
    useEffect(() => {
        const allData = JSON.parse(localStorage.getItem(STORAGE_KEY)) || {};
        // Ajusta para cargar el estado inicial si no hay datos para el elemento activo
        setFormData(allData[activeElement] || initialState);
    }, [activeElement]);

    // Actualizar localStorage cuando formData cambia
    useEffect(() => {
        const allData = JSON.parse(localStorage.getItem(STORAGE_KEY)) || {};
        allData[activeElement] = formData;
        localStorage.setItem(STORAGE_KEY, JSON.stringify(allData));
    }, [formData, activeElement]);

    useEffect(() => {
        console.log("Current Units from useFormData.js");
        console.log(currentUnits);
        console.log(activeSheet);
    
        console.log('useEffect triggered by currentUnits change');
        let allData = JSON.parse(localStorage.getItem(STORAGE_KEY)) || {};
        let isDataUpdated = false;
    
        console.log('Initial allData:', JSON.stringify(allData, null, 2));
        console.log('Current Units:', currentUnits);
    
        // Procesamiento existente para actualizar datos en localStorage
        Object.keys(allData).forEach(key => {
            const elementData = allData[key];
            Object.keys(elementData).forEach(fieldName => {
                const field = elementData[fieldName];
                if (field.typeunit && currentUnits[field.typeunit]) {
                    const newUnit = currentUnits[field.typeunit];
                    const newValue = convertFunction(field.value, field.units, newUnit);
                    if (field.units !== newUnit) {
                        field.value = newValue;
                        field.units = newUnit;
                        isDataUpdated = true;
                    }
                }
            });
        });
    
        if (isDataUpdated) {
            console.log('Data after conversion:', JSON.stringify(allData, null, 2));
            localStorage.setItem(STORAGE_KEY, JSON.stringify(allData));
        } else {
            console.log('No data conversion was needed.');
        }
    
        setFormData(allData[activeElement]);
    
        // Nueva funcionalidad para actualizar unidades en el contexto activeSheet
        if (activeSheet.DataSheet?.Elements && isDataUpdated) {
            const updatedElements = activeSheet.DataSheet.Elements.map(element => {
                const updatedElement = {...element};
                Object.keys(updatedElement).forEach(key => {
                    if (typeof updatedElement[key] === 'object' && updatedElement[key].units) {
                        const {value, units, typeunit} = updatedElement[key];
                        if (currentUnits[typeunit]) {
                            updatedElement[key] = {
                                ...updatedElement[key],
                                value: convertFunction(value, units, currentUnits[typeunit]),
                                units: currentUnits[typeunit],
                            };
                        }
                    }
                });
                return updatedElement;
            });
    
            const updatedData = {
                ...activeSheet,
                DataSheet: {
                    ...activeSheet.DataSheet,
                    Elements: updatedElements,
                },
            };
    
            updateActiveSheet(updatedData);
        }
    }, [currentUnits]);

    
//*******************CREATE A REGISTER IN FORM**********/
//*******************CREATE A REGISTER IN FORM**********/

    const handleInputChange = useCallback((fieldName, value, units, typeunit) => {
      // Verifica si el valor es un objeto y, en caso de serlo, si está vacío
      const isObject = typeof value === 'object' && value !== null;
      const isEmptyObject = isObject && Object.keys(value).length === 0;
  
      // Verifica si el valor es null, undefined, una cadena vacía, o un objeto vacío
      if (value !== null && value !== undefined && value !== '' && !isEmptyObject) {
          setFormData(prev => ({
              ...prev,
              [fieldName]: { value, units, typeunit },
          }));
      } else {
          // Manejar cómo responder si el valor es inválido (null, undefined, cadena vacía, o objeto vacío).
          console.log(`Intento de actualizar ${fieldName} con un valor inválido.`);
      }
  }, []);

  useEffect(() => {
        // Asegúrate de que activeSheet y activeSheet.DataSheet están definidos antes de continuar
    if (!activeSheet || !activeSheet.DataSheet) {
            console.log('activeSheet or activeSheet.DataSheet is not defined');
            return; // Detén la ejecución del useEffect si no están definidos
        }

    const newElementData = {
        name: activeElement,
        ...formData // Aquí iría la transformación de formData si es necesaria
    };

    // Verifica si 'Elements' existe dentro de 'DataSheet', si no, lo inicializa
    const elements = activeSheet.DataSheet.Elements ? [...activeSheet.DataSheet.Elements] : [];

    // Busca si el elemento ya existe en 'Elements'
    const elementIndex = elements.findIndex(element => element.name === activeElement);

    if (elementIndex !== -1) {
        // Si el elemento ya existe, actualiza sus datos
        elements[elementIndex] = newElementData;
    } else {
        // Si el elemento es nuevo, lo agrega a 'Elements'
        elements.push(newElementData);
    }

    // Prepara los datos actualizados para 'activeSheet'
    const updatedData = {
        ...activeSheet,
        DataSheet: {
            ...activeSheet.DataSheet,
            Elements: elements // Actualiza 'Elements' con la lista modificada
        }
    };

    // Actualiza el 'activeSheet' en el contexto
    updateActiveSheet(updatedData);

    // Dependencias del useEffect
}, [formData, activeElement]);

//*******************CREATE OBJETS IN FORM**********/
//*******************CREATE OBJETS IN FORM**********/
const handleInputObjectChange = useCallback((path, value, autoIndex = false) => {
    const updateOrCreateOrDeleteNestedObject = (obj, pathArray, value) => {
        const [firstKey, ...rest] = pathArray;
        const updatedObj = { ...obj };

        if (rest.length > 0) {
            if (!updatedObj[firstKey] || typeof updatedObj[firstKey] !== 'object') {
                if (value === undefined) return obj; // No crear si es eliminación
                updatedObj[firstKey] = {};
            }
            updatedObj[firstKey] = updateOrCreateOrDeleteNestedObject(updatedObj[firstKey], rest, value);
            if (Object.keys(updatedObj[firstKey]).length === 0) {
                delete updatedObj[firstKey];
            }
        } else {
            if (value === undefined) {
                delete updatedObj[firstKey];
            } else {
                updatedObj[firstKey] = value;
            }
        }
        return updatedObj;
    };

    if (!path || (typeof path !== 'string' && !Array.isArray(path))) {
        console.error('Invalid path provided. Path must be a non-empty string or an array.');
        return;
    }
    if (path.length === 0) {
        console.error('Path cannot be empty.');
        return;
    }

    let fullPath = path;
    if (autoIndex) {
        const pathParts = typeof path === 'string' ? path.split('.') : path;
        const existingData = formData[pathParts[0]]; // Considerando que la raíz es el primer elemento del path
        if (existingData && typeof existingData === 'object') {
            const nextIndex = Object.keys(existingData).length + 1;
            fullPath = [pathParts[0], `${nextIndex}`]; // Asegurarse de usar solo la raíz y el nuevo índice
        } else {
            fullPath = [pathParts[0], '1']; // Comienza la numeración desde 1
        }
    }

    setFormData(prevFormData => updateOrCreateOrDeleteNestedObject(prevFormData, typeof fullPath === 'string' ? fullPath.split('.') : fullPath, value));
}, [formData]);


console.log(formData)




    return [formData, handleInputChange,handleInputObjectChange];

};
export default useFormData;