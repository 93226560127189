import React from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';

const NotFound = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="background.default"
      color="text.primary"
    >
      <Card sx={{ minWidth: 275, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
            Page Not Found
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default NotFound;
