import React, { useState } from 'react';
import { Button, Menu, MenuItem,useTheme } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box } from '@mui/system';
import { ColorModeContext,tokens } from '../../theme';

const ColorMenu = ({ onColorChange }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  
    const colorCombinations = [
        
            {"name": "Ingenni theme", "primaryColor": "#141b2b", "primaryColor2": "#EAEAEA"},
            {"name": "Ingenni theme 2", "primaryColor": "#EAEAEA", "primaryColor2": "#141b2b"},        
            {"name": "Soft Sky Blue to Pale Blue", "primaryColor": "#B3E5FC", "primaryColor2": "#E1F5FE"},
            {"name": "Mint Green to Light Green", "primaryColor": "#B2DFDB", "primaryColor2": "#E0F2F1"},
            {"name": "Soft Sky Blue to Pale Blue", "primaryColor": "#B3E5FC", "primaryColor2": "#E1F5FE"},
            {"name": "Mint Green to Light Green", "primaryColor": "#B2DFDB", "primaryColor2": "#E0F2F1"},        
            {"name": "Light Gray to Pearl Gray", "primaryColor": "#D3D3D3", "primaryColor2": "#EAEAEA"},
            {"name": "Medium Gray to Silver Gray", "primaryColor": "#A9A9A9", "primaryColor2": "#C0C0C0"},
            {"name": "Charcoal Gray to Smoke Gray", "primaryColor": "#36454F", "primaryColor2": "#738276"},
            {"name": "Light Coral to Peach", "primaryColor": "#FFCDD2", "primaryColor2": "#FFE0B2"},
            {"name": "Lavender to Thistle", "primaryColor": "#E1BEE7", "primaryColor2": "#F3E5F5"},
            {"name": "Powder Blue to Alice Blue", "primaryColor": "#BBDEFB", "primaryColor2": "#E3F2FD"},
            {"name": "Cherry Blossom Pink to Pink Lace", "primaryColor": "#F8BBD0", "primaryColor2": "#FCE4EC"},
            {"name": "Periwinkle to Pale Lavender", "primaryColor": "#C5CAE9", "primaryColor2": "#EDE7F6"},
            {"name": "Apricot to Moccasin", "primaryColor": "#FFCCBC", "primaryColor2": "#FFECB3"},
            {"name": "Pale Spring Bud to Honeydew", "primaryColor": "#DCEDC8", "primaryColor2": "#F0F4C3"},
            {"name": "Antique Brass to Desert Sand", "primaryColor": "#CD9575", "primaryColor2": "#EDC9AF"},
            {"name": "Light Steel Blue to Lavender Blush", "primaryColor": "#B0C4DE", "primaryColor2": "#FFF0F5"},
            {"name": "Pale Robin Egg Blue to Beau Blue", "primaryColor": "#B3E5FC", "primaryColor2": "#B6D0E2"},
            {"name": "Wisteria to Plum", "primaryColor": "#C9A0DC", "primaryColor2": "#DDA0DD"},
            {"name": "Pastel Yellow to Lemon Chiffon", "primaryColor": "#FFF9C4", "primaryColor2": "#FFFACD"},
            {"name": "Light Moss Green to Tea Green", "primaryColor": "#D7CCC8", "primaryColor2": "#D0E8CF"},
            {"name": "Pale Taupe to Tumbleweed", "primaryColor": "#BCAAA4", "primaryColor2": "#DEAB90"},
            {"name": "Morning Blue to Azure", "primaryColor": "#CFD8DC", "primaryColor2": "#E6FFFF"},
            {"name": "Cambridge Blue to Powder Blue", "primaryColor": "#A3C1AD", "primaryColor2": "#B0E0E6"},
            {"name": "Melon to Flavescent", "primaryColor": "#FDB9B9", "primaryColor2": "#FFE8A1"},
            {"name": "Baby Blue to Alice Blue", "primaryColor": "#E7E9FD", "primaryColor2": "#F0F8FF"}
       
        
    ];

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (colors) => {
        onColorChange(colors);
        handleClose();
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Button
            aria-controls="color-menu"
           
            onClick={handleClick}
            sx={{
                minWidth: '30px', // Ajusta este valor según tus necesidades
                padding: '0px', // Mantiene el botón pequeño
                textTransform: 'none', // Evita que el texto se transforme en mayúsculas
                justifyContent: 'flex-start' // Alinea el ícono hacia la izquierda
            }}
            endIcon={<ArrowDropDownIcon sx={{ color:  theme.palette.mode === "dark" ? colors.primary.secondary : colors.primary.primary}} />}
        >
            {/* Sin texto para mantener el botón pequeño */}
        </Button>
        <Menu
            id="color-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            {colorCombinations.map((combo, index) => (
                <MenuItem key={index} onClick={() => handleSelect(combo)}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 16, height: 16, bgcolor: combo.primaryColor, marginRight: 1 }} />
                        <Box sx={{ width: 16, height: 16, bgcolor: combo.primaryColor2, marginRight: 2 }} />
                        {combo.name}
                    </Box>
                </MenuItem>
            ))}
        </Menu>
    </Box>
    );
};

export default ColorMenu;
