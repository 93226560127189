import React, { useState,useEffect,useRef,useCallback } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './1Ma01MemoriasAycardi.css';
import { v4 as uuidv4 } from 'uuid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FormularioProyecto from './2FormularioProyecto';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import VistaPrevia from './3vistaPrevia';
import { Button, Typography,Box} from '@mui/material';
import { NativeTypes } from 'react-dnd-html5-backend';
import { PDFDocument, rgb,degrees } from 'pdf-lib';
import * as PDFLib from 'pdf-lib';

import VentanaParaAbrirFormatoCapitulos from './4VentanaAbriFormato';
import DescargarFormato from './5DescargarFormato';
import { ItemArrastrable } from './6ItemArrastrable';
import logoAycardi2 from './LogotipoOficialAycardiIngenierosCiviles.jpeg';
import logoAycardi from './LogoAycardiprovisional.jpg';
import CourierNew from './cour.ttf';
import CourierNewBold from './courbd.ttf';
import * as fontkit from 'fontkit';


//imprimir memorias

// Tipo de ítem para react-dnd
const ItemType = 'elemento';
const CapituloType = 'capitulo'; // Nuevo tipo para capítulos
let db;

function initDB() {
  const request = indexedDB.open("PDFsDatabase", 1);

  request.onerror = (event) => {
    console.error("Error abriendo la base de datos", event);
  };

  request.onupgradeneeded = (event) => {
    db = event.target.result;
    if (!db.objectStoreNames.contains('pdfs')) {
      db.createObjectStore('pdfs', { keyPath: 'nombre' }); // Usamos 'nombre' como keyPath
    }
  };

  request.onsuccess = (event) => {
    db = event.target.result;
  };
}

initDB();

function savePDF(nombre, binario) {
  const transaction = db.transaction(['pdfs'], 'readwrite');
  const store = transaction.objectStore('pdfs');
  
  const pdfData = {
    nombre: nombre,
    binario: binario
  };
  
  store.add(pdfData);
}

function bufferToBase64(buf) {
  let binStr = Array.prototype.map.call(new Uint8Array(buf), ch => String.fromCharCode(ch)).join('');
  return btoa(binStr);
}





const CapituloArrastrable = ({ capitulo, 
  index,
   moverCapitulo, 
   onEliminar , 
   onEditarNombre,
   onAgregarFormato,
   onEliminarFormato,
   tieneFormato
  }) => {
  const [estaEditando, setEstaEditando] = useState(false);
  const [nombreEditado, setNombreEditado] = useState(capitulo);  
  const [aplicarFormato, setAplicarFormato] = useState(tieneFormato);

  
  const manejarFormatoClick = () => {
    setAplicarFormato((prevEstado) => {
      const nuevoEstado = !prevEstado;
  
      // Si el nuevo estado es `true`, informa al componente padre
      if (nuevoEstado) {
        onAgregarFormato(capitulo);
      } else {
        // Si el estado es `false` (se desactivó el formato), informa al componente padre
        onEliminarFormato(capitulo);
      }
  
      return nuevoEstado;
    });
};

  

  const manejarClickEditar = () => {
    setEstaEditando(true);
  };

  const manejarBlur = () => {
    setEstaEditando(false);
    // aquí deberías llamar a una función que se pase como prop para actualizar el estado en el componente padre
    onEditarNombre(index, nombreEditado);
  };
  
  
  const ref = useRef(null);
  const handleEliminar = () => {
    onEliminar(index); // aquí podrías pasar el índice, o cualquier otro identificador del capítulo que se debe eliminar
  };

  const [, drag] = useDrag(() => ({
    type: CapituloType,
    item: { capitulo, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [capitulo, index]);

  const [, drop] = useDrop({
    accept: CapituloType,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moverCapitulo(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <div ref={ref} className="capitulo-arrastable">
    {estaEditando ? (
      <input
        className="input-estilo"
        value={nombreEditado}
        onChange={(e) => setNombreEditado(e.target.value)}
        onBlur={manejarBlur}
        autoFocus
      />
    ) : (
      <span>{capitulo}</span>
    )}
    <IconButton onClick={manejarClickEditar} size="small">
      <EditIcon style={{ color: '#481b96' }} />
    </IconButton>
    <button 
        className={`boton-formato ${aplicarFormato ? 'formato-activo' : ''}`}
        onClick={manejarFormatoClick}
      >
        {aplicarFormato ? "Formato Activado" : "Formato"}</button>
    <IconButton 
      onClick={() => onEliminar(index)}
      color="error" 
      aria-label="delete"
      size="small"
    >
      <CancelIcon/>
    </IconButton>
  </div>
  )
};


// Componente AreaDrop
const AreaDrop = ({ onDrop }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemType,
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const isActive = isOver;
  let backgroundColor = '#f1f1f1';
  if (isActive) {
    backgroundColor = '#7dbce8';
  }

  return (
    <div ref={drop} className={`area-drop ${isActive ? 'active' : ''}`}>
      {isActive ? 'Suelta para agregar' : 'Arrastra aquí'}
    </div>
  );
};



// Componente para la zona de subida de archivos


const ZonaSubidaArchivos = ({ onSubirArchivo }) => {
  const manejarCambioInput = async (evento) => {
    const archivos = evento.target.files;
    const listaNuevosElementos = [];

    for (let i = 0; i < archivos.length; i++) {
      const archivo = archivos[i];

      if (archivo.type !== "application/pdf") {
        console.warn(`${archivo.name} no es un archivo PDF válido.`);
        continue;
      }

      const reader = new FileReader();
      reader.onload = async (e) => {
        const binario = e.target.result;
        
        // Crea un documento PDF desde el binario
        const pdfDoc = await PDFDocument.load(binario);
        const paginas = pdfDoc.getPages();
        const primeraPagina = paginas[0];
        const { width, height } = primeraPagina.getSize();
        
        // Verifica si la página está en orientación horizontal
        if (width > height) {
          // Gira todas las páginas si el PDF está en horizontal
          for (const pagina of paginas) {
            pagina.setRotation(degrees(270)); // o degrees(270) dependiendo de la orientación necesaria
          }
          
          // Guarda el nuevo PDF rotado como binario
          const nuevoBinario = await pdfDoc.save();
          // Agrega el PDF original y el rotado a la lista
          listaNuevosElementos.push(
            { id: uuidv4(), nombre: archivo.name, binario },
            { id: uuidv4(), nombre: `${archivo.name}-rotado`, binario: nuevoBinario }
          );
        } else {
          // Agrega el PDF sin rotar si está en vertical
          listaNuevosElementos.push({ id: uuidv4(), nombre: archivo.name, binario });
        }

        onSubirArchivo(listaNuevosElementos);
      };
      reader.readAsArrayBuffer(archivo);
    }}

  return (
    <div className="zona-arrastre">
      <input
        type="file"
        multiple
        onChange={manejarCambioInput}
        style={{ display: 'none' }}
        id="input-subida-archivos"
        accept="application/pdf" /* Aceptar solo archivos PDF */
      />
      <label htmlFor="input-subida-archivos" style={{ cursor: 'pointer' }}>
        Haz clic para seleccionar archivos a subir
      </label>
    </div>
  );
};
// Componente principal unir pdfs


const rotatePageIfNeeded = (page) => {
  const { width, height } = page.getSize();
  if (width > height) {
    page.setRotation(degrees(270));
  }
};

function dividirTextoEnLineas(texto, maxCaracteresPorLinea) {
  if (texto.length <= maxCaracteresPorLinea) return [texto];

  const palabras = texto.split(' ');
  let lineaActual = '';
  let lineas = [];

  for (let palabra of palabras) {
    if ((lineaActual + palabra).length > maxCaracteresPorLinea) {
      lineas.push(lineaActual.trim());
      lineaActual = '';
    }
    lineaActual += palabra + ' ';
  }

  if (lineaActual) {
    lineas.push(lineaActual.trim());
  }

  return lineas;
}



const unirYDescargarPDFs = async (capitulos, elementosCapitulo, datosProyecto, capitulosConFormato) => {
  const mergedPdf = await PDFDocument.create();
      mergedPdf.registerFontkit(fontkit);
      ///-------DATA CONTROL//////
      const marginSize = 29; // 1cm en puntos
      const marginleft=32
      const marginright=26.8
      const marginbotton=29.1
      const margintop=28

      const h1=28.9
      const h2=23.9
      const h3=19.2
      const h4=21.4
      const hh=h1+h2+h3+h4
      const x1=111
      const x2=103
      const x3=156.5
      const paddingX=10 // distancia de imagen a lineas de margen
      const paddingY=6
      const logoSizeInPointsX = 84; // dimenxion X de logo
      const logoSizeInPointsY = 84; // dimenxion y de logo
      const logoSizeInPointsXportada = 100; // dimenxion X de logo
      const logoSizeInPointsYportada = 100; // dimenxion y de logo
      const thicknessline1=1.1 // grosor lineas margenes    
      const wpag=612
      const hpag=792
      let sizeletterheader=10
      ///-------DATA CONTROL//////
      const imageResponse = await fetch(logoAycardi);
      const imageBuffer = await imageResponse.arrayBuffer();
      const logoImage = await mergedPdf.embedJpg(imageBuffer);
      const colorliner=0

      const courierNewFontBytes = await fetch(CourierNew).then(res => res.arrayBuffer());
      const courierNewFont = await mergedPdf.embedFont(courierNewFontBytes);

      const courierNewBoldFontBytes = await fetch(CourierNewBold).then(res => res.arrayBuffer());
      const courierNewFontBold = await mergedPdf.embedFont(courierNewBoldFontBytes);







  // Agregar los datos generales como la primera página (portada)
  //console.log("*****Comprobacion Inicial de datos en UNIR PDFS****");
  //console.log("Datos del proyecto:", datosProyecto);
  //console.log("Capítulos con formato:", capitulosConFormato);
  //console.log("Elementos de capitulo :", elementosCapitulo);

  
  let yportada0=85
  let Yportada1=145
  let Yportada2=145
  let Yportada5=20 
  let Yportada3=55
  let Yportada4=145


  let sizetextPortada=21
  const textWidth4 = courierNewFont.widthOfTextAtSize(datosProyecto.nombreProyecto, sizetextPortada);
  const centeredX4 = (wpag - textWidth4) / 2;
  let numeroproyecto=`Proyecto No. ${datosProyecto.numeroProyecto}`
  const textWidth5 = courierNewFont.widthOfTextAtSize(numeroproyecto, sizetextPortada);
  const centeredX5 = (wpag - textWidth5) / 2;

  const textWidth6 = courierNewFont.widthOfTextAtSize("MEMORIAS DE CÁLCULO ESTRUCTURAL", sizetextPortada);
  const centeredX6 = (wpag - textWidth6) / 2;
  let cityDate=datosProyecto.ciudad + ", " + datosProyecto.fecha;

  const textWidth7 = courierNewFont.widthOfTextAtSize(cityDate, sizetextPortada);
  const centeredX7 = (wpag - textWidth7) / 2;
  
  const textWidth8 = courierNewFont.widthOfTextAtSize(datosProyecto.SegundonombreProyecto, sizetextPortada);
  const centeredX8 = (wpag - textWidth8) / 2;

  const datosGeneralesPage = mergedPdf.addPage([wpag, hpag]);
  let Ylogoportada=yportada0+logoSizeInPointsYportada
  datosGeneralesPage.drawImage(logoImage, {
      x: wpag/2-logoSizeInPointsXportada*0.5,
      y: hpag -Ylogoportada ,
      width: logoSizeInPointsXportada,
      height: logoSizeInPointsYportada,
    });
  
   datosGeneralesPage.drawText(datosProyecto.nombreProyecto, {
      x: centeredX4,
      y: hpag-Ylogoportada-Yportada1, // posición modificada para dejar espacio para más texto
      size: sizetextPortada,
      color: rgb(0, 0, 0),
      font: courierNewFontBold
    });
  
    datosGeneralesPage.drawText(datosProyecto.SegundonombreProyecto, {
      x: centeredX8,
      y: hpag-Ylogoportada-Yportada1-Yportada5,
      size: sizetextPortada,
      color: rgb(0, 0, 0),
      font:courierNewFontBold
    });

    datosGeneralesPage.drawText(numeroproyecto, {
      x: centeredX5,
      y: hpag-Ylogoportada-Yportada1-Yportada2, // posición modificada para dejar espacio para más texto
      size: sizetextPortada,
      color: rgb(0, 0, 0),
      font: courierNewFontBold
    });

    datosGeneralesPage.drawText("MEMORIAS DE CÁLCULO ESTRUCTURAL", {
      x: centeredX6,
      y: hpag-Ylogoportada-Yportada1-Yportada2-Yportada3, // posición modificada para dejar espacio para más texto
      size: sizetextPortada,
      color: rgb(0, 0, 0),
      font: courierNewFont
    });

    datosGeneralesPage.drawText(cityDate, {
      x: centeredX7,
      y: hpag-Ylogoportada-Yportada1-Yportada2-Yportada3-Yportada4, // posición modificada para dejar espacio para más texto
      size: sizetextPortada,
      color: rgb(0, 0, 0),
      font: courierNewFont
    });


    

  // Inicialización de variables para manejar la tabla de contenido y numeración de páginas
  const inicioCapitulos = {};
  let numeroPaginaActual = 3; // la portada es la página 1

  let aplicarFormato = false; // variable para determinar si se aplica el formato especial

  for (const capitulo of capitulos) {
    inicioCapitulos[capitulo] = numeroPaginaActual;

    const page = mergedPdf.addPage([wpag, hpag]);
    let sizetextchaptersdivison=21


     // Calcular el ancho del texto en base al tamaño y la fuente
     const textWidth = courierNewFont.widthOfTextAtSize(capitulo, sizetextchaptersdivison);
     const textWidth2 = courierNewFont.widthOfTextAtSize("MEMORIAS DE CÁLCULO", sizetextchaptersdivison);
     const textWidth3 = courierNewFont.widthOfTextAtSize(datosProyecto.nombreProyecto, sizetextchaptersdivison);
     const textWidth33 = courierNewFont.widthOfTextAtSize(datosProyecto.SegundonombreProyecto, sizetextchaptersdivison);

     // Calcular la posición x para centrar el texto en la página
     const centeredX = (wpag - textWidth) / 2;
     const centeredX2 = (wpag - textWidth2) / 2;
     const centeredX3 = (wpag - textWidth3) / 2;
     const centeredX33 = (wpag - textWidth33) / 2;
 
    
    /////DIBUJO DE LA PORTADA DE CADA CAPITULO///
    /////DIBUJO DE LA PORTADA DE CADA CAPITULO///
    /////DIBUJO DE LA PORTADA DE CADA CAPITULO///
    const lineasCapitulo = dividirTextoEnLineas(capitulo, 35);
     // Dibuja el nombre del capítulo centrado
     let ymargenespecial=170
     let alturaLinea=30
     
     lineasCapitulo.forEach((linea, indice) => {
      // Calcular el ancho del texto para esta línea específica
      const textWidth = courierNewFont.widthOfTextAtSize(linea, sizetextchaptersdivison);
  
      // Calcular la posición x para centrar esta línea en la página
      const centeredX = (wpag - textWidth) / 2;
  
      // Dibujar la línea del texto
      page.drawText(linea, {
        x: centeredX,
        y: hpag - ymargenespecial - (indice * alturaLinea), 
        size: sizetextchaptersdivison,
        color: rgb(0, 0, 0),
        font: courierNewFont
      });
    });


    page.drawText(datosProyecto.nombreProyecto, {
      x: centeredX3,
      y: hpag*0.5+10,
      size: sizetextchaptersdivison,
      color: rgb(0, 0, 0),
      font:courierNewFontBold
    });

    page.drawText(datosProyecto.SegundonombreProyecto, {
      x: centeredX33,
      y: hpag*0.5-10,
      size: sizetextchaptersdivison,
      color: rgb(0, 0, 0),
      font:courierNewFontBold
    });

    page.drawText("MEMORIAS DE CÁLCULO", {
      x: centeredX2,
      y: ymargenespecial,
      size: sizetextchaptersdivison,
      color: rgb(0, 0, 0),
      font:courierNewFont
    });
 

    // Si el capítulo tiene formato especial, actualiza la variable aplicarFormato
    if (capitulosConFormato && (capitulo in capitulosConFormato)) {
      console.log("El formato especial se aplicará después del capítulo:", capitulo);
      aplicarFormato = true;
    } else {
      aplicarFormato = false; // reset para los siguientes capítulos que no requieren el formato especial
    }

    numeroPaginaActual++;
    
    const elementos = elementosCapitulo[capitulo] || [];
    for (const elemento of elementos) {
      if (elemento.binario) {
        //console.log("elementos binarios desde unir pdfs ")
        //console.log(elemento.binario)

        const pdfDoc = await PDFDocument.load(elemento.binario);
        // Rotar las páginas si es necesario ANTES de copiarlas
      
        const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
        copiedPages.forEach((page) => {
          const newPage = mergedPdf.addPage(page);
          if (aplicarFormato) {
            // Si aplicarFormato es verdadero
                        
            let yprimertexto=22.5
            let ysubrayado=2.5
            // Datos Encabezado
            newPage.drawText("PROYECTO:", {
              x: marginleft+x1,
              y: hpag-margintop-yprimertexto,
              size: sizeletterheader,
              color: rgb(0, 0, 0),
              font:courierNewFont
            });
            // Dibujar la subrayado de proyecto
            newPage.drawLine({
              start: { x: marginleft+x1,y:hpag-margintop-yprimertexto-ysubrayado },
              end: { x: marginleft+x1+52, y: hpag-margintop-yprimertexto-ysubrayado },
              thickness: thicknessline1*0.5,
              color: rgb(colorliner, 0, 0),
            });
            

            newPage.drawText(`${datosProyecto.numeroProyecto} - ${datosProyecto.nombreProyecto} - ${datosProyecto.SegundonombreProyecto}`, {
              x: marginleft+x1+58,
              y: hpag-margintop-yprimertexto,
              size: sizeletterheader,
              color: rgb(0, 0, 0),
              font:courierNewFontBold
            });


            newPage.drawText("CIUDAD:", {
              x: marginleft+x1,
              y: hpag-margintop-yprimertexto-h2,
              size: sizeletterheader,
              color: rgb(0, 0, 0),
              font:courierNewFont
            });
            
            newPage.drawLine({
              start: { x: marginleft+x1,y:hpag-margintop-yprimertexto-ysubrayado-h2 },
              end: { x: marginleft+x1+40, y: hpag-margintop-yprimertexto-ysubrayado-h2 },
              thickness: thicknessline1*0.5,
              color: rgb(colorliner, 0, 0),
            });
            

            newPage.drawText(datosProyecto.ciudad, {
              x: marginleft+x1+47,
              y: hpag-margintop-yprimertexto-h2,
              size: sizeletterheader,
              color: rgb(0, 0, 0),
              font:courierNewFontBold
            });
            let xauxiliar=188
            newPage.drawText("CALCULÓ:", {
              x: marginleft+x1+xauxiliar,
              y: hpag-margintop-yprimertexto-h2,
              size: sizeletterheader,
              color: rgb(0, 0, 0),
              font:courierNewFont
            });
            
            newPage.drawLine({
              start: { x: marginleft+x1+xauxiliar,y:hpag-margintop-yprimertexto-ysubrayado-h2 },
              end: { x: marginleft+x1+xauxiliar+46, y: hpag-margintop-yprimertexto-ysubrayado-h2 },
              thickness: thicknessline1*0.5,
              color: rgb(colorliner, 0, 0),
            });            

            newPage.drawText(datosProyecto.calculo, {
              x: marginleft+x1+xauxiliar+53,
              y: hpag-margintop-yprimertexto-h2,
              size: sizeletterheader,
              color: rgb(0, 0, 0),
              font:courierNewFontBold
            });


            newPage.drawText("FECHA:", {
              x: marginleft+x1,
              y: hpag-margintop-yprimertexto-h2-h3,
              size: sizeletterheader,
              color: rgb(0, 0, 0),
              font:courierNewFont
            });
            
            newPage.drawLine({
              start: { x: marginleft+x1,y:hpag-margintop-yprimertexto-ysubrayado-h2-h3 },
              end: { x: marginleft+x1+34, y: hpag-margintop-yprimertexto-ysubrayado-h2-h3 },
              thickness: thicknessline1*0.5,
              color: rgb(colorliner, 0, 0),
            });
            

            newPage.drawText(datosProyecto.fecha, {
              x: marginleft+x1+41,
              y: hpag-margintop-yprimertexto-h2-h3,
              size: sizeletterheader,
              color: rgb(0, 0, 0),
              font:courierNewFontBold
            });

            newPage.drawText("REVISÓ:", {
              x: marginleft+x1+xauxiliar,
              y: hpag-margintop-yprimertexto-h2-h3,
              size: sizeletterheader,
              color: rgb(0, 0, 0),
              font:courierNewFont
            });
            
            newPage.drawLine({
              start: { x: marginleft+x1+xauxiliar,y:hpag-margintop-yprimertexto-ysubrayado-h2-h3 },
              end: { x: marginleft+x1+xauxiliar+40, y: hpag-margintop-yprimertexto-ysubrayado-h2-h3 },
              thickness: thicknessline1*0.5,
              color: rgb(colorliner, 0, 0),
            });            

            newPage.drawText(datosProyecto.reviso, {
              x: marginleft+x1+xauxiliar+47,
              y: hpag-margintop-yprimertexto-h2-h3,
              size: sizeletterheader,
              color: rgb(0, 0, 0),
              font:courierNewFontBold
            });
            let ajuste=2
            newPage.drawText("OBSERVACIONES:", {
              x: marginleft+x1,
              y: hpag-margintop-yprimertexto-h2-h3-h4+ajuste,
              size: sizeletterheader,
              color: rgb(0, 0, 0),
              font:courierNewFont
            });
            
            newPage.drawLine({
              start: { x: marginleft+x1,y:hpag-margintop-yprimertexto-ysubrayado-h2-h3-h4+ajuste },
              end: { x: marginleft+x1+82, y: hpag-margintop-yprimertexto-ysubrayado-h2-h3-h4+ajuste },
              thickness: thicknessline1*0.5,
              color: rgb(colorliner, 0, 0),
            });            

            newPage.drawText(datosProyecto.observaciones, {
              x: marginleft+x1+89,
              y: hpag-margintop-yprimertexto-h2-h3-h4+ajuste,
              size: sizeletterheader,
              color: rgb(0, 0, 0),
              font:courierNewFontBold
            });

            newPage.drawText("PÁGINA:", {
              x: marginleft+x1+xauxiliar+156,
              y: hpag-margintop-yprimertexto-h2-h3,
              size: sizeletterheader,
              color: rgb(0, 0, 0),
              font:courierNewFont
            });
            newPage.drawLine({
              start: { x: marginleft+x1+xauxiliar+156,y:hpag-margintop-yprimertexto-ysubrayado-h2-h3 },
              end: { x: marginleft+x1+xauxiliar+156+40, y: hpag-margintop-yprimertexto-ysubrayado-h2-h3 },
              thickness: thicknessline1*0.5,
              color: rgb(colorliner, 0, 0),
            }); 


              // Dibujar la línea superior
              newPage.drawLine({
               start: { x: marginleft, y: hpag - margintop-thicknessline1*0.5 },
               end: { x: wpag - marginright, y: hpag - margintop-thicknessline1*0.5 },
               thickness: thicknessline1,
               color: rgb(colorliner, 0, 0),
             });

             
              // Dibujar la línea inferior
              newPage.drawLine({
                start: { x: marginleft, y: marginbotton+thicknessline1*0.5 },
                end: { x: wpag - marginright, y: marginbotton+thicknessline1*0.5 },
                thickness: thicknessline1,
                color: rgb(colorliner, 0, 0),
              });

            // Dibujar lineas horizontales superiores

            newPage.drawLine({
              start: { x: marginleft+x1, y: hpag - margintop-thicknessline1*0.5 -h1},
              end: { x: wpag - marginright, y: hpag - margintop-thicknessline1*0.5-h1 },
              thickness: thicknessline1,
              color: rgb(colorliner, 0, 0),
            });

            newPage.drawLine({
              start: { x: marginleft+x1, y: hpag - margintop-thicknessline1*0.5 -h1-h2},
              end: { x: wpag - marginright, y: hpag - margintop-thicknessline1*0.5-h1-h2 },
              thickness: thicknessline1,
              color: rgb(colorliner, 0, 0),
            });

            newPage.drawLine({
              start: { x: marginleft+x1, y: hpag - margintop-thicknessline1*0.5 -h1-h2-h3},
              end: { x: wpag - marginright-x2, y: hpag - margintop-thicknessline1*0.5-h1-h2-h3 },
              thickness: thicknessline1,
              color: rgb(colorliner, 0, 0),
            });

            newPage.drawLine({
              start: { x: marginleft, y: hpag - margintop-thicknessline1*0.5 -hh},
              end: { x: wpag - marginright, y: hpag - margintop-thicknessline1*0.5-hh },
              thickness: thicknessline1,
              color: rgb(colorliner, 0, 0),
            });

            // Dibujar lineas verticales superiores
            newPage.drawLine({
              start: { x: wpag- marginright-x2, y: hpag - margintop-thicknessline1*0.5 -h1-h2},
              end: { x: wpag-marginright-x2, y: hpag - margintop-thicknessline1*0.5-hh },
              thickness: thicknessline1,
              color: rgb(colorliner, 0, 0),
            });
            newPage.drawLine({
              start: { x: wpag- marginright-x2-x3, y: hpag - margintop-thicknessline1*0.5 -h1},
              end: { x: wpag-marginright-x2-x3, y: hpag - margintop-thicknessline1*0.5-h1-h2-h3 },
              thickness: thicknessline1,
              color: rgb(colorliner, 0, 0),
            });



      
      
      
            // Dibujar la línea izquierda
            newPage.drawLine({
              start: { x: marginleft, y: marginbotton },
              end: { x: marginleft, y: hpag - margintop },
              thickness: thicknessline1,
              color: rgb(colorliner, 0, 0),
            });
      
            // Dibujar la línea derecha
            newPage.drawLine({
              start: { x: wpag - marginright, y: marginbotton },
              end: { x: wpag - marginright, y: hpag - margintop },
              thickness: thicknessline1,
              color: rgb(colorliner, 0, 0),
            });

              // Añadir la imagen al PDF
      
      
              newPage.drawImage(logoImage, {
              x: marginSize+paddingX,
              y: page.getHeight() - logoSizeInPointsY - marginright-paddingY,
              width: logoSizeInPointsX,
              height: logoSizeInPointsY,
            });
            

      ///////*---------//////
          }
          
          numeroPaginaActual++;
        });


      }
    }
  }

  // Crear la tabla de contenido
  const Yindice=175
  const Y1=10
  const Y2=15
  const baseX1 = 40; // valor base para una letra
  const incrementX1 = 2.5; // incremento por cada letra adicional
  const tline=1.5
  let htext1=12
  const tocPage = mergedPdf.insertPage(1, [wpag, hpag]); // inserta después de la portada
  



  tocPage.drawText('ÍNDICE', {
    x: (wpag*0.5-10),
    y: (hpag-Yindice),
    size: htext1,
    color: rgb(0, 0, 0),
    font:courierNewFont
  });
  tocPage.drawText('Página', {
    x: (wpag-85),
    y: (hpag-Yindice-Y1-Y2),
    size: htext1,
    color: rgb(0, 0, 0),
    font:courierNewFont
  });

  tocPage.drawLine({
    start: { x: marginleft, y: hpag-Yindice-Y1 },
    end: { x: wpag - marginright, y: hpag-Yindice-Y1 },
    thickness: tline,
    color: rgb(colorliner, 0, 0),
  });

  tocPage.drawLine({
    start: { x: marginleft, y: hpag-Yindice-Y1-1.8 },
    end: { x: wpag - marginright, y: hpag-Yindice-Y1-1.8 },
    thickness: tline,
    color: rgb(colorliner, 0, 0),
  });
  
  let y=Yindice+Y1+Y2+Y2
  let alturaLinea=15
  for (const capitulo of capitulos) {

    const lineasCapitulo = dividirTextoEnLineas(capitulo, 50);
    let longitudTexto = String(inicioCapitulos[capitulo]).length;
    let X1 = baseX1 + (longitudTexto - 1) * incrementX1;
    
      // Dibuja cada línea del capítulo
    let alturadesplazamiento
  lineasCapitulo.forEach((linea, indice) => {
    tocPage.drawText(linea, {
      x: marginleft,
      y: hpag - y - (indice * alturaLinea), // alturaLinea es el espacio vertical entre líneas
      size: 10,
      color: rgb(0, 0, 0),
      font: courierNewFont
    });
    alturadesplazamiento=indice * alturaLinea
  });
  
    tocPage.drawText('.........................', {
      x: (wpag-270),
      y: (hpag-y),
      size: htext1,
      color: rgb(0, 0, 0),
      font:courierNewFont
    });

    tocPage.drawText(`${inicioCapitulos[capitulo]}`, {
      x: wpag-marginright-X1,
      y: hpag-y,
      size: 10,
      color: rgb(0, 0, 0),
      font:courierNewFont
    });

    y =y + 17.5 + alturadesplazamiento; // moverse hacia abajo para el próximo ítem
  }

  // Segunda pasada: agregar números de página
  const totalPages = mergedPdf.getPageCount();
  for (let i = 2; i < totalPages; i++) {
    const page = mergedPdf.getPage(i);
    const pageSize = page.getSize();
    const pageWidth = pageSize.width;
    page.drawText(`${i + 1}/${totalPages}`, {
      x: wpag-marginright-50, // para que quede centrado
      y: hpag-margintop-h1-h2-h3-h4/2, // ajustado para que quede en el pie de página
      size: sizeletterheader,
      color: rgb(0, 0, 0),
      font:courierNewFontBold
    });
  }

  // Última pasada para verificar la orientación y rotar las páginas si es necesario
  //
  //for (let i = 0; i < mergedPdf.getPageCount(); i++) {
  //  const page = mergedPdf.getPage(i);
  //  rotatePageIfNeeded(page);
  //}
  //}

  // Generar el PDF unido
  const pdfBytes = await mergedPdf.save();

  // Crear un Blob para descargar el PDF
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });

  // Descargar el PDF
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Memorias.pdf'; // Puedes cambiar el nombre del archivo aquí
  a.click();

  // Limpiar
  window.URL.revokeObjectURL(url);
};



// Componente principal Ma01MemoriasAycardi
const MfreeEnterprise02 = () => {
  const [capitulos, setCapitulos] = useState([]);
  const [elementosCapitulo, setElementosCapitulo] = useState({}); // Nuevo estado para elementos en capítulos
  const [nuevoCapitulo, setNuevoCapitulo] = useState('');
  const [datosProyecto, setDatosProyecto] = useState({});
  const [capitulosConFormato, setCapitulosConFormato] = useState({});
  const [estructuraElementosCap,setestructuraElementosCap]=useState({});
  const inputFileRef = useRef(null);

  const [elementosCapituloSubir, setElementosCapituloSubir] = useState({}); // Nuevo estado para elementos en capítulos
  

  const guardarDatosProyecto = (datos) => {
    setDatosProyecto(datos);
    console.log("Prueba Impresion de los Datos desde el formulario")
    console.log(datos); // Solo para verificar, puedes eliminar esta línea después
  };


  // Lista Elementos PDF
  const [elementos, setElementos] = useState([]);
  const manejarSubidaArchivo = (nuevosElementos) => {
    setElementos((prevElementos) => {
      // Crear una copia de los elementos actuales
      const elementosActualizados = [...prevElementos];
  
      // Iterar sobre los nuevos elementos
      for (const nuevoElemento of nuevosElementos) {
        const indiceExistente = prevElementos.findIndex(e => e.nombre === nuevoElemento.nombre);
  
        if (indiceExistente !== -1) {
          // Si el elemento ya existe, reemplazarlo
          elementosActualizados[indiceExistente] = nuevoElemento;
        } else {
          // Si el elemento es nuevo, agregarlo a la lista
          elementosActualizados.push(nuevoElemento);
        }
      }
  
      return elementosActualizados;
    });
  };
  
  
  const agregarCapitulo = () => {
    if (nuevoCapitulo) {
      // Verificar si el capítulo ya existe en la lista
      if (capitulos.includes(nuevoCapitulo)) {
        alert("Ya existe un capitulo con este nombre"); // Puedes reemplazar esto con tu propio manejo de errores
        return; // No continuar con la función
      }
  
      // Si el capítulo no existe, proceder a agregarlo
      setCapitulos([...capitulos, nuevoCapitulo]);
      setElementosCapitulo({ ...elementosCapitulo, [nuevoCapitulo]: [] }); // Inicializar lista de elementos para el nuevo capítulo
      setNuevoCapitulo(''); // Resetear el input
    } else {
      alert("Ingreasa nombre para el nuevo capítulo."); // Puedes reemplazar esto con tu propio manejo de errores
    }
  };
  

  const agregarCapituloMultiple = (nombreCapitulo) => {
    if (nombreCapitulo) {
      setCapitulos((prevCapitulos) => [...prevCapitulos, nombreCapitulo]);
      setElementosCapitulo((prevElementos) => ({ ...prevElementos, [nombreCapitulo]: [] }));
      // Nota: setNuevoCapitulo ya no es necesario aquí si estás agregando capítulos directamente.
    }
  };

  const agregarMultiplesCapitulos = (nombresNuevosCapitulos) => {
    // Define la lista de nombres de nuevos capítulos que quieres agregar
  
    // Agrega cada capítulo usando la función original agregarCapitulo
    nombresNuevosCapitulos.forEach((nombreCapitulo) => {
      agregarCapituloMultiple(nombreCapitulo);
    });
  };

  const moverCapitulo = useCallback(
    (dragIndex, hoverIndex) => {
      setCapitulos((prev) => {
        const copia = [...prev];
        const dragCapitulo = copia[dragIndex];
        copia.splice(dragIndex, 1);
        copia.splice(hoverIndex, 0, dragCapitulo);
        return copia;
      });
  
   
  
    },
    [capitulos] // Dependencia en los capítulos.
  );
  
  
  // Modificado para manejar el elemento soltado
  const manejarSoltado = (capitulo, item) => {
    setElementosCapitulo((prevElementos) => {
      const newElementos = { ...prevElementos };
  
      // Aquí, en lugar de almacenar el nombre del archivo, almacena el binario
      newElementos[capitulo] = newElementos[capitulo] || [];
      newElementos[capitulo].push({
        id: item.id,
        nombre: item.nombre,
        binario: item.binario, // Asegúrate de pasar el binario correctamente
      });
  
      return newElementos;
    });
  
    // Nuevo: elimina el elemento de 'elementos' cuando se suelta en un capítulo
    setElementos((prevElementos) => prevElementos.filter((e) => e.id !== item.id));
  };



  const eliminarElemento = (capitulo, index) => {
    // Declara elementoEliminado aquí para que esté disponible en todo el alcance de la función
    let elementoEliminado;
  
    setElementosCapitulo(prevElementos => {
      const newElementosCapitulo = [...prevElementos[capitulo]];
      // Asigna el elemento eliminado a la variable previamente declarada
      [elementoEliminado] = newElementosCapitulo.splice(index, 1);
      return { ...prevElementos, [capitulo]: newElementosCapitulo };
    });
  
    // Ahora elementoEliminado está definido y puede ser utilizado aquí
    if (elementoEliminado) {
      setElementos(prevElementos => [...prevElementos, elementoEliminado]);
    }
  };
  







  const eliminarCapitulo = (index) => {
    const nombreCapitulo = capitulos[index];
    const elementosCapituloEliminado = [...(elementosCapitulo[nombreCapitulo] || [])]; // copia de los elementos del capítulo
  
    // Actualizar la lista de capítulos
    const nuevosCapitulos = capitulos.filter((_, i) => i !== index);
    setCapitulos(nuevosCapitulos);
  
    // Actualizar los elementos del capítulo
    const nuevosElementosCapitulo = { ...elementosCapitulo };
    delete nuevosElementosCapitulo[nombreCapitulo];
    setElementosCapitulo(nuevosElementosCapitulo);
  
    // Devolver los elementos del capítulo eliminado a la lista de elementos
    setElementos(prev => [...prev, ...elementosCapituloEliminado]);
  };

  const editarNombreCapitulo = (index, nuevoNombre) => {
    setCapitulos((prevCapitulos) => {
      const nuevosCapitulos = [...prevCapitulos];
      nuevosCapitulos[index] = nuevoNombre;
      return nuevosCapitulos;
    });
  
    setElementosCapitulo((prevElementos) => {
      const nuevosElementos = {...prevElementos};
      const nombreCapituloActual = capitulos[index];
      nuevosElementos[nuevoNombre] = [...(nuevosElementos[nombreCapituloActual] || [])];
      delete nuevosElementos[nombreCapituloActual];
      return nuevosElementos;
    });
  };
  const emailCurrent=localStorage.getItem('useremail')
  const handleUnirYDescargarPDFs = async () => {
    // Verifica si tienes al menos un capítulo y un elemento en algún capítulo
    if (capitulos.length === 0 || !Object.values(elementosCapitulo).some(arr => arr.length > 0)) {
      alert('Agrega al menos un capítulo y un PDF en algún capítulo antes de unirlos.');
      return;
    }
  
    try {
      await unirYDescargarPDFs(capitulos, elementosCapitulo, datosProyecto, capitulosConFormato);
  
      // Muestra un mensaje de éxito
      alert('Los PDFs se han unido y descargado con éxito.');
  
      // Realiza la llamada a la API
      const response = await fetch('https://ss0pnhnrj9.execute-api.us-east-1.amazonaws.com/dev/modulestrack', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user: emailCurrent,
          module: 'MemoriasAycardi',
          action: 'DescargarMemoriasPDF'
        })
      });
  
      if (!response.ok) {
        throw new Error('Error en la llamada a la API');
      }
  
      const data = await response.json();
      console.log('Respuesta de la API:', data);
  
      // Puedes realizar otras acciones con la respuesta de la API si es necesario
    } catch (error) {
      console.error('Error al unir los PDFs o en la llamada a la API:', error);
      alert('Ocurrió un error al unir los PDFs. Probablemente hay archivos con formato diferente a PDF.');
    }
  };
  

  

  const agregarCapituloConFormato = (nombre) => {
    setCapitulosConFormato((prevCapitulos) => {
      return { ...prevCapitulos, [nombre]: true };
    });
  };

  const eliminarCapituloConFormato= (nombre) => {
    setCapitulosConFormato((prevCapitulos) => {
      const nuevosCapitulos = { ...prevCapitulos };
      delete nuevosCapitulos[nombre];
      return nuevosCapitulos;
    });
};

function AgregarElementosCapitulo(capitulo,elemento){
  console.log("Contiene:")
  console.log("capitulo")
  console.log("elemento")

}

function getAllPDFs(callback) {
  const transaction = db.transaction(['pdfs'], 'readonly');
  const store = transaction.objectStore('pdfs');
  
  const request = store.getAll();
  
  request.onsuccess = (event) => {
    callback(event.target.result);
  };
}






function onChequeoFormato(capitulo) {
  return !!capitulosConFormato[capitulo];
}


  
{/*
useEffect(() => {
  console.log('Datos de portada:', datosProyecto);
}, [datosProyecto]);

useEffect(() => {
  console.log('Capítulos actualizados:', capitulos);
}, [capitulos]);


useEffect(() => {
  console.log('Capitulos con formato:', capitulosConFormato);
}, [capitulosConFormato]);

useEffect(() => {
  console.log('Elementos del capítulo actualizados:', elementosCapitulo);
}, [elementosCapitulo]);


useEffect(() => {
  console.log('********Datos de portada Nuevamente*********:', datosProyecto);
}, [datosProyecto]);

console.log("****Datos Proyecto***")
console.log(datosProyecto)
console.log("****Elementos de Capitulos***")
console.log(elementosCapitulo)




*/}
//console.log("****Datos Proyecto***")
//console.log(datosProyecto)
//console.log("****Elementos de Capitulos***")
//console.log(elementosCapitulo)
//console.log("****Elementos***")
//console.log(elementos)




  

  return (
    <DndProvider backend={HTML5Backend}>
      
      <div className="barra-menu">      
      {/*
      <button className="input-estilo-abrir" className="boton-menu" onClick={generarArchivoProyecto}>Guardar Como</button>
      <div>
      <input ref={inputFileRef} type="file" onChange={cargarDesdeArchivo} style={{ display: 'none' }} />
      <button className="boton-menu" onClick={() => inputFileRef.current.click()}>Abrir Archivo</button>
      </div>
      */}

      <VentanaParaAbrirFormatoCapitulos agregarMultiplesCapitulos={agregarMultiplesCapitulos} 
      capitulosExistentes={capitulos}
      />
      
      <DescargarFormato elementos={capitulos} ElementosCapitulo={elementosCapitulo} AgregarElementosCapitulo={AgregarElementosCapitulo} />

        <button className="boton-menu" onClick={handleUnirYDescargarPDFs} >Imprimir Memorias</button>
      </div>
      <div className="contenedor">
      
      
       <div className="columnaElementos">
        {/* Zona de elementos arrastrables */}
        <div className="zonaPdf">        
        <div className="tituloZonaPdf">
        <h2>3. Asigna tus PDF</h2>
        </div>

        {elementos.map((elemento, index) => (
          <ItemArrastrable key={elemento.id} item={{ ...elemento, nombre: elemento.nombre }} tipo={ItemType} 
          setElementos={setElementos} // pasar setElementos
          elementos={elementos} // pasar elementos
          />
        ))}

        </div>
        </div>

        {/* Zona de capítulos */}
        <div className="columnaCapitulos">
          {/* Agregar nuevo capítulo */}
           {/* Zona de subida de archivos */}
           <div className="TituloSubirPDF">
           <h2 > 1.0 Subir tus PDF</h2>
           </div>
        <div style={{ backgroundColor: '#FFAB2D', border: '1px solid black' }}>
          <ZonaSubidaArchivos onSubirArchivo={manejarSubidaArchivo} />
        </div>
        <div>
          <div className="TituloSubirPDF2">
          <h2 > 2.0 Crea tus Capitulos</h2>
          </div>
          
          <div className="creacionCapitulos">
            <input
              className="input-estilo"
              type="text" 
              value={nuevoCapitulo} 
              onChange={(e) => setNuevoCapitulo(e.target.value)} 
              placeholder="Nombre del Capítulo" 
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  agregarCapitulo();
                }
              }} // Se añade este manejador de evento              
            />
            <button
              className="boton-estilo"
              onClick={agregarCapitulo}
            >
              Nuevo Capítulo
            </button>
          </div>


        </div>

        <div className="bloquecapitulos">
        {capitulos.map((capitulo, index) => (
          <div key={capitulo}> {/* Asegúrate de envolver todo en un div o fragment */}
            <Box >
            <CapituloArrastrable index={index} capitulo={capitulo} moverCapitulo={moverCapitulo} onEliminar={eliminarCapitulo} onEditarNombre={editarNombreCapitulo}  onAgregarFormato={agregarCapituloConFormato}
            onEliminarFormato={eliminarCapituloConFormato} tieneFormato={onChequeoFormato(capitulo)}
            />            
            <AreaDrop onDrop={(item) => manejarSoltado(capitulo, item)} />
            <div>              
              <ul>
              {elementosCapitulo[capitulo] &&
                elementosCapitulo[capitulo].map((item, indexElemento) => (
                  <li key={item.id} 
                  style={{ display: 'flex', alignItems: 'center', color: 'black' }}
                  >
                    {item.nombre} 
                    <IconButton 
                      onClick={() => eliminarElemento(capitulo, indexElemento)}
                      color="error" 
                      aria-label="delete"
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </li>
                ))
              }
              </ul>
            </div>
            </Box>
          </div>
          
        ))}
        </div>

        </div>

        {/* Agregar nuevo capítulo */}
        <div className='columnaTercera' >
          <div className="TituloSubirPDF">
          <h2>4. Ingresa Datos Generales</h2>
          </div>
          <FormularioProyecto guardarDatosProyecto={guardarDatosProyecto} />

          <div className="TituloSubirPDF2">
          <h2 > 5. Vista Previa Datos </h2>
          </div>

          
          <div className='vistapreviaPortada'>          
          <VistaPrevia datosGenerales={datosProyecto} ></VistaPrevia>
          </div>
          <div  style={{ margin: '10px' }}>          
           </div>           
           <div className='boton-contenedor'>
            <button className='boton-estilo2' onClick={handleUnirYDescargarPDFs}>Imprimir Memorias</button>
          </div>

        </div>
        
      </div>
    </DndProvider>
  );
};

export default MfreeEnterprise02;
