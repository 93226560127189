import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../contexts/ContextDataProvider/DataProvider';
import { Modal,useTheme, Box, Typography, List, ListItem, ListItemText, ListItemIcon, Button, Collapse, IconButton,TextField,FormControl, InputLabel,Select,MenuItem} from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess'; // Importa el icono de contraer
import { tokens } from '../../theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ListItemButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';





export function ModalOpenFile({ open, handleClose, onOpen, redirectTo }) {
  const { projects, sheets } = useContext(DataContext);
  const [sortOrder, setSortOrder] = useState('alphabetic');

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const safesheets = Array.isArray(sheets) ? sheets : [];
  
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [searchText, setSearchText] = useState('');
  const [openProjects, setOpenProjects] = useState({});
  const [openSubprojects, setOpenSubprojects] = useState({});
  const [openDefault, setOpenDefault] = useState(false);

  const handleProjectClick = (projectId) => {
    setOpenProjects((prev) => ({ ...prev, [projectId]: !prev[projectId] }));
  };

  const handleSubprojectClick = (projectId, subprojectId) => {
    const key = `${projectId}-${subprojectId}`;
    setOpenSubprojects((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleDefaultClick = () => {
    setOpenDefault(!openDefault);
  };

  const sortedProjects = React.useMemo(() => {
    switch (sortOrder) {
      case 'alphabetic':
        return [...projects].sort((a, b) => a.nombre.localeCompare(b.nombre));
      case 'code':
        return [...projects].sort((a, b) => a.codigo.localeCompare(b.codigo));
      default:
        return projects;
    }
  }, [projects, sortOrder]);
  

  const filteredProjects = searchText === '' ? sortedProjects : sortedProjects.filter(project => 
    project.nombre.toLowerCase().includes(searchText.toLowerCase()) || 
    project.codigo.toLowerCase().includes(searchText.toLowerCase())
  );
  

  return (
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Box sx={{     
      maxHeight: '80%',
      overflow: 'auto',
      // Aquí puedes agregar estilos adicionales
    }}>
    <Box sx={{paddingBottom:2}}>
    <Box m={7.5} padding={5} borderRadius={2.5} mb={5} sx={{
      backgroundColor: colors.primary.primary,
      maxWidth: isDesktop ? '1000px' : 'auto',
      marginRight: 'auto',
      marginLeft: 'auto',
      '@media (max-width:1280px)': {
        marginLeft: 2,
        marginRight: 2,
        marginBottom:5
      },
      '@media (max-width:600px)': {
        marginLeft: 1,
        marginRight: 1,
        marginBottom:5
      },
      //border: theme.palette.mode === 'light' ? `1px solid ${colors.primary.secondary}` : 'none',
      boxShadow: theme.palette.mode === 'light' ? `0 0 5px ${colors.grey[500]}` : 'none',
      //border: theme.palette.mode === 'light' ? `1px solid ${colors.grey[500]}` : 'none',
      //boxShadow: theme.palette.mode === 'light' ? `0 0 5px ${colors.blueAccent.contrast1}` : 'none',
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space',
        alignItems: 'center',
      }}>
        <FolderIcon sx={{mb:1}}/>
        <Box ml={1}>
        <Typography variant="h3" gutterBottom>Open File</Typography>
        </Box>
       
      </Box>
      <Box mt={2} >
      <TextField 
          label="Search project" 
          variant="outlined" 
          value={searchText}
          size="small"
          onChange={(e) => setSearchText(e.target.value)}
          sx={{ marginBottom: 2, width: '100%' }}
        />
      </Box>

      <Box mt={1}sx={{ minWidth: 120 }}>
        <FormControl fullWidth size="small">
          <InputLabel id="sort-order-label" sx={{ lineHeight: '1.4375em', fontSize: '0.875rem' }}>Sort Order</InputLabel>
          <Select
            labelId="sort-order-label"
            id="sort-order"
            value={sortOrder}
            label="Sort Order"
            sx={{
              height: 42, // Puedes ajustar este valor según tus necesidades
              '.MuiSelect-select': { // Targeting the inner select element for additional styling if needed
                paddingTop: '6px',
                paddingBottom: '6px',
              },
              '.MuiOutlinedInput-notchedOutline': {
                top: 0,
              },
            }}
            onChange={(e) => setSortOrder(e.target.value)}
          >
            <MenuItem value="default">Default</MenuItem>
            <MenuItem value="alphabetic">Alphabetic</MenuItem>
            <MenuItem value="code">Code</MenuItem>
          </Select>
        </FormControl>
      </Box>



      <List>
        {filteredProjects.map((proyecto) => (
          <React.Fragment key={proyecto.id}>
            <ListItemButton onClick={() => handleProjectClick(proyecto.id)}>
              <ListItemIcon><FolderIcon sx={{ color: colors.blueAccent.contrast1 }} /></ListItemIcon>
              <ListItemText primary={`${proyecto.nombre} - ${proyecto.codigo}`} />
              
              
                
              {openProjects[proyecto.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}

            </ListItemButton>
            
            <Collapse in={openProjects[proyecto.id]} timeout="auto" unmountOnExit>
              {proyecto.subproyectos.map((subproyecto) => (
                <React.Fragment key={`${proyecto.id}-${subproyecto}`}>
                  <ListItemButton style={{ paddingLeft: 32 }} onClick={() => handleSubprojectClick(proyecto.id, subproyecto)}>
                    
                    <ListItemIcon><FolderIcon sx={{ color: colors.yelloAccent.contrast1}} /></ListItemIcon>
                    <ListItemText primary={subproyecto.split(',')[0]}/>
                    
                 

                    {openSubprojects[`${proyecto.id}-${subproyecto}`] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  
                    

                  </ListItemButton>
                  
                  <Collapse in={openSubprojects[`${proyecto.id}-${subproyecto}`]} timeout="auto" unmountOnExit>
                    {safesheets.filter(sheet => sheet.project === proyecto.id && sheet.subproject.split(',')[1] === subproyecto.split(',')[1]).map((sheet) => (
                      <ListItem key={sheet.id} style={{ paddingLeft: 64 }}>
                        <ListItemIcon><InsertDriveFileIcon /></ListItemIcon>
                        <ListItemText primary={sheet.nameSheet} />
                        {isDesktop && (
                          <Typography>{sheet.isModuleNickName}</Typography>
                        )}
                        <Box ml={2}>
                         <Button>Open</Button>
                         </Box>
                      </ListItem>
                    ))}
                  </Collapse>
                </React.Fragment>
              ))}
              {/* Additional rendering for sheets belonging to the project but not matching any subproject */}
              {safesheets.filter(sheet => sheet.project === proyecto.id && !proyecto.subproyectos.includes(sheet.subproject)).map((sheet) => (
                <ListItem key={sheet.id} style={{ paddingLeft: 32 }}>
                  <ListItemIcon><InsertDriveFileIcon /></ListItemIcon>
                  <ListItemText primary={sheet.nameSheet} />
                  {isDesktop && (
                    <Typography>{sheet.isModuleNickName}</Typography>
                  )}
                  <Box ml={2}>
              <Button>Open</Button>
              </Box>
                </ListItem>
              ))}
            </Collapse>

            </React.Fragment>
        ))}
        {/* Default project section */}
        <ListItemButton onClick={handleDefaultClick}>
          <ListItemIcon><FolderIcon  sx={{ color: colors.blueAccent.contrast1 }} /></ListItemIcon>
          <ListItemText primary="Default" />
          {openDefault ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Collapse in={openDefault} timeout="auto" unmountOnExit>
          {safesheets.filter(sheet => sheet.project === 'default').map((sheet) => (
            <ListItem key={sheet.id} style={{ paddingLeft: 32 }}>
              <ListItemIcon><InsertDriveFileIcon /></ListItemIcon>
              <ListItemText primary={sheet.nameSheet} />
              {isDesktop && (
                <Typography>{sheet.isModuleNickName}</Typography>
              )}
           
              <Box ml={2}>
              <Button>Open</Button>
              </Box>
            </ListItem>
          ))}
        </Collapse>
      </List>
    </Box>
    </Box>
    </Box>
    </Modal>
  );
}
