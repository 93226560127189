import React, { useRef, useState } from 'react';
import { Canvas, extend } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';

extend({ OrbitControls });

const coordenadasNodos = [[0, 0, 0], [0, 5, 0], [5, 5, 0], [5, 0, 0]];

const CuboVerde = () => {
  return (
    <mesh position={[2, 2, 2]}>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color='green' />
    </mesh>
  );
};

const Nodo = (props) => {
  const ref = useRef();

  return (
    <mesh
      {...props}
      ref={ref}
    >
      <sphereGeometry args={[0.1, 32, 32]} />
      <meshStandardMaterial color='red' />
    </mesh>
  );
};

const Frame = ({ nodo1, nodo2 }) => {
  const posicion = new THREE.Vector3().lerpVectors(nodo1, nodo2, 0.5);
  const escala = new THREE.Vector3().subVectors(nodo1, nodo2).length();

  return (
    <mesh position={posicion} scale={[0.1, escala, 0.1]}>
      <cylinderGeometry args={[0.1, 0.1, 1, 32]} />
      <meshStandardMaterial color='blue' />
    </mesh>
  );
};

const Escena3D = () => {
  const nodos = coordenadasNodos.map(coord => new THREE.Vector3(...coord));
  const [frames, setFrames] = useState([]);

  // La lógica para conectar nodos puede mantenerse si es relevante para tu aplicación
  const conectarNodos = (indiceNodo1, indiceNodo2) => {
    const nodo1 = nodos[indiceNodo1];
    const nodo2 = nodos[indiceNodo2];
    setFrames([...frames, { nodo1, nodo2 }]);
  };

  return (
    <Canvas style={{ background: '#D9DEE8' }}>
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <CuboVerde />
      {nodos.map((nodo, idx) => (
        <Nodo key={idx} position={nodo} />
      ))}
      {frames.map((frame, idx) => (
        <Frame key={idx} nodo1={frame.nodo1} nodo2={frame.nodo2} />
      ))}
      <OrbitControls />
    </Canvas>
  );
};

export default Escena3D;
