// conversiones.js

const conversionRates = {

  // Adimensional: no requiere conversión
  "adimensional_to_adimensional": 1,
  "percentage_to_percentage": 1,
  // Porcentaje
  "decimal_to_percent": 100, // 0.25 (decimal) * 100 = 25%
  "percent_to_decimal": 0.01, // 25% * 0.01 = 0.25 (decimal)

  // Longitud
  "m_to_mm": 1000,
  "m_to_ft": 1 / 0.3048,
  "m_to_in": 1000 / 25.4, 
  "mm_to_m": 0.001,
  "mm_to_in": 1 / 25.4,
  "mm_to_ft": 1 / (25.4*12),
  "ft_to_m": 0.3048,
  "ft_to_mm": (25.4*12),
  "ft_to_in": 12,   
  "in_to_mm": 25.4,
  "in_to_ft": 1 / 12,   
  "in_to_m": 25.4 / 1000, 

  // Fuerza
  "kN_to_tonf": 0.10197,
  "kN_to_kip": 1 / 4.44822,
  "kN_to_kgf": 100 / 9.80665, // 1 kN es equivalente a aproximadamente 101.971621 kgf  
  "tonf_to_kN": 1 / 0.10197,
  "tonf_to_kgf": 1019.71621, // 1 tonf es equivalente a 1019.71621 kgf, asumiendo 1 tonf = 1000 kgf
  "tonf_to_kip": (1 / 0.10197) / 4.44822, // Convertir tonf a kN y luego a kip  
  "kip_to_kN": 4.44822,
  "kip_to_tonf": 4.44822 * 0.10197, // Convertir kip a kN y luego a tonf
  "kip_to_kgf": 4.44822 * 100 / 9.80665, // Convertir kip a kN y luego a kgf  
  "kgf_to_kN": 9.80665 / 100, // 1 kgf es equivalente a 0.00980665 kN
  "kgf_to_tonf": 1 / 1019.71621, // 1 kgf es equivalente a aproximadamente 0.00098 tonf
  "kgf_to_kip": (9.80665 / 100) / 4.44822, // Convertir kgf a kN y luego a kip

  // Área (length2)
  "m2_to_mm2": 1000 * 1000, // (m_to_mm)^2
  "m2_to_ft2": (1 / 0.3048) * (1 / 0.3048), // (m_to_ft)^2
  "m2_to_in2": (1000 / 25.4) * (1000 / 25.4), // (m_to_in)^2

  "mm2_to_m2": 0.001 * 0.001, // (mm_to_m)^2
  "mm2_to_in2": (1 / 25.4) * (1 / 25.4), // (mm_to_in)^2
  "mm2_to_ft2": (1 / 25.4 / 12) * (1 / 25.4 / 12), // (mm_to_ft)^2

  "ft2_to_m2": 0.3048 * 0.3048, // (ft_to_m)^2
  "ft2_to_mm2": (0.3048 * 1000) * (0.3048 * 1000), // (ft_to_mm)^2
  "ft2_to_in2": 12 * 12, // (ft_to_in)^2

  "in2_to_mm2": 25.4 * 25.4, // (in_to_mm)^2
  "in2_to_m2": (25.4 / 1000) * (25.4 / 1000), // (in_to_m)^2
  "in2_to_ft2": (1 / 12) * (1 / 12), // (in_to_ft)^2

  // Volumen (length3)
  "m3_to_mm3": 1000 * 1000 * 1000, // (m_to_mm)^3
  "m3_to_ft3": (1 / 0.3048) * (1 / 0.3048) * (1 / 0.3048), // (m_to_ft)^3
  "m3_to_in3": 1000 * 1000 * 1000 / (25.4 * 25.4 * 25.4), // m³ a mm³ y luego a in³

  "mm3_to_m3": 0.001 * 0.001 * 0.001, // (mm_to_m)^3
  "mm3_to_in3": (1 / 25.4) * (1 / 25.4) * (1 / 25.4), // (mm_to_in)^3
  "mm3_to_ft3": (0.001 * 0.001 * 0.001) / (0.3048 * 0.3048 * 0.3048), // mm³ a m³ y luego a ft³
  
  "ft3_to_m3": 0.3048 * 0.3048 * 0.3048, // (ft_to_m)^3
  "ft3_to_in3": (12 * 12 * 12), // Directamente de ft³ a in³
  "ft3_to_mm3": (0.3048 * 0.3048 * 0.3048) * (1000 * 1000 * 1000), // ft³ a m³ y luego a mm³
  
  "in3_to_mm3": 25.4 * 25.4 * 25.4, // (in_to_mm)^3
  "in3_to_m3": (25.4 * 25.4 * 25.4) / (1000 * 1000 * 1000), // in³ a mm³ y luego a m³
  "in3_to_ft3": (25.4 * 25.4 * 25.4) / (1000 * 1000 * 1000) / (0.3048 * 0.3048 * 0.3048), // in³ a mm³, mm³ a m³ y luego a ft³
  

  //************** *Momentos************
  "kN-m_to_kN-mm": 1000,
  "kN-m_to_tonf-m": 0.10197,
  "kN-m_to_kgf-m": 101.9716,
  "kN-m_to_kip-ft": 0.737562,
  "kN-m_to_kip-in": 8.85074,
  "kN-mm_to_kN-m": 1 / 1000,
  "tonf-m_to_kN-m": 1 / 0.10197,
  "kgf-m_to_kN-m": 1 / 101.9716,
  "kip-ft_to_kN-m": 1 / 0.737562,
  "kip-in_to_kN-m": 1 / 8.85074,
  //********** */
  "kN-mm_to_tonf-m": 0.10197 / 1000,
  "kN-mm_to_kgf-m": 101.9716 / 1000,
  "kN-mm_to_kip-ft": 0.224809 * 0.00328084,
  "kN-mm_to_kip-in": 0.224809 * 0.0393701,  
  "tonf-m_to_kN-mm": 1000 / 0.10197,
  "kgf-m_to_kN-mm": 1000 / 101.9716,
  "kip-ft_to_kN-mm": 1 / (0.224809 * 0.00328084),
  "kip-in_to_kN-mm": 1 / (0.224809 * 0.0393701),
  //********** */
  "tonf-m_to_kgf-m": 1000,
  "tonf-m_to_kip-ft": 2.24809 * 3.28084,
  "tonf-m_to_kip-in": 2.24809 * 39.3701,  
  "kgf-m_to_tonf-m": 1 / 1000,
  "kip-ft_to_tonf-m": 1 / (2.24809 * 3.28084),
  "kip-in_to_tonf-m": 1 / (2.24809 * 39.3701),
  //********** */
  "kgf-m_to_kip-ft": 0.00723301,
  "kgf-m_to_kip-in": 0.0867961,  
  "kip-ft_to_kgf-m": 1 / 0.00723301,
  "kip-in_to_kgf-m": 1 / 0.0867961,
  //********** */
  "kip-ft_to_kip-in": 12,
  "kip-in_to_kip-ft": 1 / 12,
  //***************Temperature************
  '°C_to_°F':1,
  '°F_to_°C':1,

  //************** */ Presión / Stress************
  "MPa_to_kgf/cm2": 10.19716,
  "MPa_to_tonf/m2": 101.9716, // 1 MPa es aproximadamente 101.9716 tonf/m²
  "MPa_to_ksi": 0.145038, // 1 MPa = 145.038 psi => 0.145038 ksi
  "MPa_to_psi": 145.038, // Directamente de la definición de MPa a psi  
  "tonf/m2_to_MPa": 1 / 101.9716, // Aproximadamente 0.00980665 MPa por tonf/m²    
  "tonf/m2_to_kgf/cm2": 1 / 10, 
  "tonf/m2_to_ksi":  0.00142233,
  "tonf/m2_to_psi": 1.42233,  
  "kgf/cm2_to_MPa": 0.0980665, // Inverso de MPa a kgf/cm²
  "kgf/cm2_to_tonf/m2": 10, // 1 kgf/cm² es aproximadamente igual a 10 tonf/m² (considerando 1 tonf = 1000 kgf y ajustando las áreas)
  "kgf/cm2_to_ksi": 0.0142233, // Convertir kgf/cm² a MPa y luego a ksi
  "kgf/cm2_to_psi": 14.2233, // Convertir kgf/cm² a MPa y luego a psi
  "ksi_to_MPa": 6.89476,
  "ksi_to_kgf/cm2": 70.307, // Convertir ksi a psi y luego a MPa y a kgf/cm²
  "ksi_to_tonf/m2": 703.07, // Convertir ksi a kgf/cm² y luego a tonf/m²
  "ksi_to_psi": 1000, // Directamente, ya que ksi es kilo-psi
  "psi_to_MPa": 0.00689476, // Directamente de psi a MPa
  "psi_to_kgf/cm2": 0.070307, // Convertir psi a MPa y luego a kgf/cm²
  "psi_to_tonf/m2": 0.70307, // Convertir psi a MPa y luego a kgf/cm² y a tonf/m2  
  "psi_to_ksi": 1/1000, // La misma unidad, sin conversión

  // ***************Force / lenght**************  
  "kN/m_to_kN/mm": 1 / 1000, // 1 kN/m es 0.001 kN/mm
  "kN/m_to_tonf/m": 1 / 9.80665, // 1 kN/m es aproximadamente 0.10197 tonf/m
  "kN/m_to_kgf/m": 100 / 9.80665, // 1 kN/m es aproximadamente 101.9716 kgf/m
  "kN/m_to_kip/ft": 1 / (4.44822 * 0.3048), // 1 kN/m es aproximadamente 0.06852 kip/ft
  "kN/m_to_kip/in": 1 / (4.44822 * 0.0254), // 1 kN/m es aproximadamente 0.00571 kip/in  
  "kN/mm_to_kN/m": 1000, // 1 kN/mm es 1000 kN/m  
  "tonf/m_to_kN/m": 9.80665, // 1 tonf/m es aproximadamente 9.80665 kN/m  
  "kgf/m_to_kN/m": 9.80665 / 100, // 1 kgf/m es aproximadamente 0.00980665 kN/m  
  "kip/ft_to_kN/m": 4.44822 * 0.3048, // 1 kip/ft es aproximadamente 14.5939 kN/m  
  "kip/in_to_kN/m": 4.44822 * 0.0254, // 1 kip/in es aproximadamente 1.21616 kN/m
  //********************************/
  "kN/mm_to_tonf/m": 1000 / 9.80665, // 1 kN/mm es aproximadamente 101.9716 tonf/m
  "kN/mm_to_kgf/m": 1000 * 100 / 9.80665, // 1 kN/mm es aproximadamente 101971.6 kgf/m
  "kN/mm_to_kip/ft": 1000 / (4.44822 * 0.3048), // 1 kN/mm es aproximadamente 68.52177 kip/ft
  "kN/mm_to_kip/in": 1000 / (4.44822 * 0.0254), // 1 kN/mm es aproximadamente 5.71015 kip/in  
  "tonf/m_to_kN/mm": 9.80665 / 1000, // 1 tonf/m es aproximadamente 0.00980665 kN/mm  
  "kgf/m_to_kN/mm": 9.80665 / 100, // 1 kgf/m es aproximadamente 0.0980665 kN/mm
  "kip/ft_to_kN/mm": (4.44822 * 0.3048) / 1000, // 1 kip/ft es aproximadamente 0.0145939 kN/mm  
  "kip/in_to_kN/mm": (4.44822 * 0.0254) / 1000, // 1 kip/in es aproximadamente 0.00121616 kN/mm
  //*********************** */    
  "tonf/m_to_kgf/m": 1000, // 1 tonf/m es 1000 kgf/m  
  "tonf/m_to_kip/ft": (1000 * 9.80665) / (4.44822 * 0.3048), // 1 tonf/m es aproximadamente 68.52177 kip/ft  
  "tonf/m_to_kip/in": (1000 * 9.80665) / (4.44822 * 0.0254), // 1 tonf/m es aproximadamente 5.71015 kip/in  
  "kgf/m_to_tonf/m": 1 / 1000, // 1 kgf/m es 0.001 tonf/m
  "kip/ft_to_tonf/m": (4.44822 * 0.3048) / (9.80665 * 1000), // 1 kip/ft es aproximadamente 0.014594 tonf/m  
  "kip/in_to_tonf/m": (4.44822 * 0.0254) / (9.80665 * 1000), // 1 kip/in es aproximadamente 0.001216 tonf/m
  //*********************** */   
 "kgf/m_to_kip/ft": (9.80665 / 100) / (4.44822 * 0.3048), // 1 kgf/m es aproximadamente 0.006854 kip/ft 
 "kgf/m_to_kip/in": (9.80665 / 100) / (4.44822 * 0.0254), // 1 kgf/m es aproximadamente 0.000571 kip/in 
 "kip/ft_to_kgf/m": (4.44822 * 0.3048) / (9.80665 / 100), // 1 kip/ft es aproximadamente 145.939 kgf/m 
 "kip/in_to_kgf/m": (4.44822 * 0.0254) / (9.80665 / 100), // 1 kip/in es aproximadamente 12.1616 kgf/m
  //*********************** */  
  "kip/ft_to_kip/in": 12, // 1 kip/ft es 12 kip/in
  "kip/in_to_kip/ft": 1 / 12, // 1 kip/in es 0.08333 kip/ft

};

// Funciones de conversión de temperatura
const convertCelsiusToFahrenheit = (valueCelsius) => {
  return (valueCelsius * 9/5) + 32;
};

const convertFahrenheitToCelsius = (valueFahrenheit) => {
  return (valueFahrenheit - 32) * 5/9;
};

// Función genérica de conversión
export const convertFunction = (value, fromUnit, toUnit) => {
  if (fromUnit === toUnit) {
    console.log(`No se requiere conversión: ${fromUnit} a ${toUnit}`);
    return value; // No se requiere conversión si las unidades son iguales
  }

  // Convertir de °C a °F
  if (fromUnit === '°C' && toUnit === '°F') {
    return convertCelsiusToFahrenheit(value);
  }

  // Convertir de °F a °C
  if (fromUnit === '°F' && toUnit === '°C') {
    return convertFahrenheitToCelsius(value);
  }

  // Otras conversiones con tasas fijas
  const key = `${fromUnit}_to_${toUnit}`;
  const rate = conversionRates[key];

  if (rate === null || rate === undefined) {
    console.warn(`No conversion rate from ${fromUnit} to ${toUnit}. Returning original value.`);
    return value; // Retorna el valor original si no se encuentra tasa de conversión
  }

  const newValue = value * rate;
  console.log(`Convertido ${value} ${fromUnit} a ${newValue} ${toUnit} utilizando la tasa ${rate}`);
  return newValue;
};

  