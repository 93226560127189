import React, { useState } from 'react';
import { Button,Typography,Box } from '@mui/material';


function IngenniM06() {
  // ... tu código existente

  return (
    <div>      
        <Box>
        <Typography variant="h1">Has entrado</Typography>
        <Typography variant="h1">Has entrado</Typography>
        <Typography variant="h1">Al modulo M06</Typography>
        </Box>      
    </div>
  );
}

export default IngenniM06;

