import { useState, useEffect } from 'react';

function useDesignCode(initialCode) {
  const [codeDesign, setCodeDesign] = useState(initialCode);

  useEffect(() => {
    // Esta función podría ser cualquier lógica que necesitas ejecutar cuando el código de diseño cambia.
    // Por ejemplo, podría ser una validación del nuevo código o una actualización de otro estado relacionado.
    const handleCodeDesignChange = (newCode) => {
      // Implementa tu lógica específica aquí.
      console.log(`Nuevo código de diseño establecido: ${newCode}`);
      // Por ejemplo, actualizar algún estado relacionado o realizar una validación.
    };

    // Aquí es donde detectamos el cambio.
    if (codeDesign !== initialCode) {
      setCodeDesign(initialCode); // Actualiza el estado con el nuevo código de diseño.
      handleCodeDesignChange(initialCode); // Ejecuta cualquier lógica adicional necesaria.
    }
  }, [initialCode]); // Observa cambios en initialCode.

  return codeDesign;
}

export default useDesignCode;
