import React, { useState ,useEffect} from 'react';
import {Box, AppBar, Toolbar, IconButton, Button, TextField, Grid, Menu, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { tokens } from '../../../theme';
import SaveIcon from '@mui/icons-material/Save';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { downloadDataAsJson } from '../../Functions/downloadDataAsJson';
import IosShareIcon from '@mui/icons-material/IosShare';
import IosShare from '@mui/icons-material/IosShare';
import PDFPrinter from '../../../classes/PrintPdf/PDFPrinter';
import ColorMenu from '../../../classes/PrintPdf/ColorMenu';





const ResponsiveMenu = ({ add, namesheetactive, updateActiveSheet, openSaveAs }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedColors, setSelectedColors] = useState(() => {
    const savedColors = localStorage.getItem('selectedColorsPrint');
    return savedColors ? JSON.parse(savedColors) : { primaryColor: '#141b2b', primaryColor2: '#EAEAEA' };
  });


  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [saveAsMenuAnchorEl, setSaveAsMenuAnchorEl] = useState(null);
  const saveAsMenuOpen = Boolean(saveAsMenuAnchorEl);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const STORAGE_KEY = 'ActiveSheetData';

  useEffect(() => {
    localStorage.setItem('selectedColorsPrint', JSON.stringify(selectedColors));
  }, [selectedColors]);

  

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSaveAsMenuOpen = (event) => {
    setSaveAsMenuAnchorEl(event.currentTarget);
  };

  const handleSaveAsMenuClose = () => {
    setSaveAsMenuAnchorEl(null);
  };

  const handleSaveCloudStorageClick = () => {
    openSaveAs();
    handleSaveAsMenuClose();
  };

  const handleSaveAsJsonClick = () => {
    downloadDataAsJson(STORAGE_KEY);
    handleSaveAsMenuClose(); // Cierra el menú después de la acción
  };

  //***************PRINT PDF FUNCTION*****************/
  //***************PRINT PDF FUNCTION*****************/
  const handlePrintPDF = async () => {
    const printer = new PDFPrinter();
    
    let primaryColor, primaryColor2;
    if (isMobile) {
        primaryColor = "#141b2b";
        primaryColor2 = '#EAEAEA';
    } else {
        primaryColor = selectedColors.primaryColor;
        primaryColor2 = selectedColors.primaryColor2;
    }
    
    const textEntries = {
        "Header1": "Header text 1",
        "Header2": "Header text 2",
        "Content": "Here is some content."
    };  
  
    const pdfBytes = await printer.createPDF(primaryColor, primaryColor2, textEntries);
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'example.pdf';
    link.click();
};



  return (
    <AppBar position="static">
      <Toolbar sx={{ backgroundColor: colors.primary.menubar }}>
        {isMobile && (
          <>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar-mobile"
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={() => { add(); handleClose(); }}>Add</MenuItem>
              <MenuItem onClick={handleClose}>Save</MenuItem>
              <MenuItem onClick={handleSaveAsMenuOpen}>Save As</MenuItem>              
              <MenuItem onClick={handleClose}>Open</MenuItem>
              <MenuItem onClick={handleClose}>Import Data</MenuItem>
              <MenuItem onClick={() => { handlePrintPDF(); handleClose(); }}>Print PDF</MenuItem>
              <MenuItem onClick={handleClose}>Select Color</MenuItem> 
            
              <MenuItem onClick={handleClose}>Share</MenuItem>
            </Menu>
          </>
        )}

        {!isMobile && (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', gap: theme.spacing(3) }}>
            <Button color="inherit" onClick={() => add()} startIcon={<AddIcon />}>Add</Button>
            <Button color="inherit" onClick={() => console.log('Open')} startIcon={<SaveIcon />}>Save</Button>
            <Button color="inherit" onClick={handleSaveAsMenuOpen} endIcon={<ArrowDropDownIcon />}>Save As</Button>
            <Button color="inherit" onClick={() => console.log('Open')} startIcon={<FolderOpenIcon />}>Open file</Button>
            <Button color="inherit" onClick={() => console.log('Import')} startIcon={<UploadFileIcon />}>Import Data</Button>
            <ColorMenu onColorChange={setSelectedColors}  />            
            <Button color="inherit" onClick={() => { handlePrintPDF(); handleClose(); }} startIcon={<PictureAsPdfIcon/>}>Print PDF</Button>
            
            <Button color="inherit" onClick={() => console.log('Share with sheet with my partners')} startIcon={<IosShare />} >Share</Button>
          </Box>
          <Box>
            <TextField
              value={namesheetactive || ''}
              label="Project Name"
              variant="outlined"
              size="small"
              onChange={(e) => updateActiveSheet({ nameSheet: e.target.value })}
              InputLabelProps={{ shrink: true }}
              style={{ marginLeft: theme.spacing(2) }}
            />
          </Box>
      
          <Box>
            <Button variant="contained" color="primary" startIcon={<PlayArrowIcon />} style={{ marginLeft: theme.spacing(2) }}>
              Run
            </Button>
            </Box>
            </Box>
          </>
        )}

        {/* Submenú "Save As" */}
        <Menu
          id="save-as-submenu"
          anchorEl={saveAsMenuAnchorEl}
          open={saveAsMenuOpen}
          onClose={handleSaveAsMenuClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          
          sx={{
            '& .MuiPaper-root': { // Esto aplica estilos al papel del menú
              backgroundColor: colors.blueAccent.contrast1, // Cambia el color de fondo del papel del menú
              marginTop: '15px', // Añade un poco de espacio arriba para bajar el menú
            }
          }}
        >
           <MenuItem onClick={handleSaveCloudStorageClick}>Save Cloud Storage</MenuItem>
          <MenuItem onClick={handleSaveAsJsonClick}>Save as JSON file</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default ResponsiveMenu;
