import React from 'react'; // Asegúrate de importar React
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme, Button } from '@mui/material';
import { tokens } from '../../theme';



export const ConfirmDeleteDialog = ({ open, onClose, onConfirm, nameproject, message }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Si no se provee un mensaje específico, se usa un mensaje genérico
  const dialogMessage = message || `Are you sure you want to delete: ${nameproject}?`;

  return (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { backgroundColor: colors.primary.primary3 } }}>
      <DialogTitle variant="h5">{"Confirm Delete"}</DialogTitle>
      <DialogContent>
        <DialogContentText variant="h5">
          {dialogMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} style={{ color: colors.primary.contrastText }}>Cancel</Button>
        <Button variant="outlined" onClick={onConfirm} autoFocus style={{ color: colors.primary.contrastText }}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
