import React from 'react';

function eliminarBinarios(obj) {
  let copiaObj = {};

  for (let clave in obj) {
    if (obj[clave] instanceof Array && obj[clave].length > 0) {
      copiaObj[clave] = obj[clave].map(archivo => {
        let copiaArchivo = { ...archivo };
        delete copiaArchivo.binario;
        return copiaArchivo;
      });
    }
  }

  return copiaObj;
}

const DescargarFormato = ({ elementos, ElementosCapitulo }) => {

  // Corregido el error de redeclarar el ElementosCapitulo
  const ElementosCapituloSinBinarios = eliminarBinarios(ElementosCapitulo);

  const descargarTexto = () => {
    // Verifica si la lista está vacía
    if (elementos.length === 0) {
      alert('Debe haber al menos un capítulo para descargar.');
      return;
    }

    // Crear un objeto con la estructura que deseas
    const data = {
      capitulos: elementos,
      ElementosCapitulos: ElementosCapituloSinBinarios
    };

    // Convertir ese objeto a una cadena JSON
    const textoDeArchivo = JSON.stringify(data, null, 2); // Con formato bonito
    
    // Crear un elemento 'a' virtual
    const elemento = document.createElement("a");
    // Crear un archivo con el texto especificado
    const file = new Blob([textoDeArchivo], {type: 'application/json'});
    // Crear una URL para el archivo
    elemento.href = URL.createObjectURL(file);
    // Establecer el nombre del archivo descargado
    elemento.download = "formatoUnirMemorias.json"; // Cambiamos la extensión a .json
    // Disparar la descarga
    document.body.appendChild(elemento); // Necesario para que funcione en Firefox
    elemento.click();
    // Limpiar después de la descarga
    document.body.removeChild(elemento);
  };

  return (
    <button onClick={descargarTexto} className="boton-menu">
      Descargar Formato
    </button>
  );
};

export default DescargarFormato;
