import React from 'react';
import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, ScatterController, LineElement, PointElement, LinearScale, Title, Tooltip } from 'chart.js';

ChartJS.register(ScatterController, LineElement, PointElement, LinearScale, Title, Tooltip);

const RectangleChart = ({ data, mode = 'dark' }) => {
  const { base, height } = data;

  // Definición de colores en base al modo
  const backgroundColor = mode === 'dark' ? '#0a1929' : '#ffffff'; // fondo oscuro para dark mode y claro para light mode
  const gridColor = mode === 'dark' ? '#cccccc' : '#000000'; // gris claro para las líneas en dark mode y negro para light mode
  const borderColor = '#4bc0c0'; // turquesa
  const fillColor = 'rgba(75, 192, 192, 0.2)'; // turquesa claro

  const maxAxisValueX = base ;
  const maxAxisValueY = height ;

  const coordinates = [
    { x: 0, y: 0 },
    { x: base, y: 0 },
    { x: base, y: height },
    { x: 0, y: height },
    { x: 0, y: 0 }
  ];

  const chartData = {
    datasets: [{
      label: 'Rectangle',
      data: coordinates,
      borderColor: borderColor,
      backgroundColor: fillColor,
      showLine: true,
      fill: true,
      borderWidth: 3
    }]
  };

  const options = {
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        min: 0,
        max: maxAxisValueX,
        grid: {
          color: gridColor,
          borderColor: borderColor
        },
        ticks: {
          color: gridColor
        }
      },
      y: {
        type: 'linear',
        min: 0,
        max: maxAxisValueY,
        grid: {
          color: gridColor,
          borderColor: borderColor
        },
        ticks: {
          color: gridColor
        }
      }
    },
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: 'Section'
      },
      tooltip: {
        enabled: false
      }
    },
    elements: {
      point: {
        radius: 0 // Oculta los puntos en los vértices
      }
    }
  };

  return <Scatter data={chartData} options={options} />;
};

export default RectangleChart;
