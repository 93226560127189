import React,{useState,useEffect,useContext} from 'react';
import {useTheme, Box, Typography, Grid, TextField, Stack, Tabs, Tab, Button, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
///Import Contexto principal
import { DataContext } from '../../../contexts/ContextDataProvider/DataProvider';

///Import colorstheme
import { tokens } from '../../../theme';
///Import Componentes
import IngenniInput from '../../Components/inputs/IngenniInput';
import IngenniSelect from '../../Components/inputs/ingenniSelect';
///Import custom Hooks---Importar en todos los componente
import useUnitsLabels from '../../hooks/useUnitLabels/useUnitLabels';
import useLanguageLabels from '../../hooks/useNamesLabels/useNamesLabels';
import useDesignCode from '../../hooks/useDesignCode/useDesignCode';
import useUnits from '../../hooks/useConversionUnits/useUnits';
import useFormData from '../../hooks/useFormData/useFormData';
// Imports Particulares del mudolo
import names from './names.json';
import InputM05 from './InputM05';
import OutputM05 from './OutputM05'
import './ViewIndexM05.css'; 



function IngenniM05(props) {  
  //-------------------------MANDATORY-------------------------------------------------
  //-------------------------MANDATORY-------------------------------------------------
  //************************************************************************************
  //************Theme Colors******* 
  //************************************************************************************  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);     

  //************************************************************************************
  //************Use Customs Manejo de Labels por idioma y Unidades**********************
  //************************************************************************************  
  const N = useLanguageLabels(names);
  const units=props.unitsDesign
  const currentUnits= useUnits(units);
  const unitData = useUnitsLabels(props.unitsDesign); 
  const currentCodeDesign = useDesignCode(props.codeDesign) 
  //************************************************************************************
  //************Use custom Gestion Estado Principal del componente**********************
  //************************************************************************************  
  const [formData, handleInputChange] = useFormData({},props.activeElement,currentUnits);
  //-------------------------MANDATORY-------------------------------------------------
  //-------------------------MANDATORY-------------------------------------------------
 



  //************************************************************************************
  //************CONTROLS & FUNCIONTS INTERFACE*******
  //************************************************************************************
  const [activeComponent, setActiveComponent] = useState('input');
  const toggleActiveButton = (buttonName) => {
    setActiveComponent(buttonName);
  };

  

  

  //************************************************************************************
  //************Constants*******
  //************************************************************************************
  const optionsSeismicDetail = [
    { value: 'none', label: 'none' },
    { value: 'DMI', label: 'DMI' },
    { value: 'DMO', label: 'DMO' },
    { value: 'DES', label: 'DES' },
  ];

  const optionsStations = [
    { value: 'automatic', label: 'automatic' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
  ];


  
  
  return (
    <Box paddingLeft={2} paddingRight={2} paddingBottom={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={2.8} xl={2.6}>
          <Box sx={{ backgroundColor: colors.primary.primary, borderRadius: 2.5, padding: 4 }}>
            <Box 
              sx={{
           
                borderRadius: 0,
                padding: 0,
                display: 'flex',
                justifyContent: 'center', 
                alignItems: 'center', 
              }}            
              >
              <Typography variant="h5" fontWeight="bold" sx={{color:colors.primary.third}}>Design Information</Typography>            
              </Box>
              <Box
              sx={{
                '& > :not(style)': { mt: 2}, 
              }}                 
              >
                <Typography variant="h6"  textAlign="left">Design code: {currentCodeDesign}</Typography>
                <Typography variant="h6"  textAlign="left">Units: {units}</Typography>
                <IngenniInput 
                 label={N["fc concrete"] ? N["fc concrete"] : "Loading..."} valueType="realPositive" width="90%" placeholder="fc-Concrete Strength" units={unitData ? unitData.stress : "Loading..."}                                      
                 OnSetChange={(newValue,units,typeunit) => handleInputChange("concreteStrength", newValue,units,typeunit)} 
                 valuei={formData['concreteStrength'] ? formData['concreteStrength'].value : ''}  
                 error={false}
                 labelError="error 1"
                />
                <IngenniInput label={N["fy steel"]} valueType="realPositive" width="90%" placeholder="fy-Steel Yield Stress" units={unitData ? unitData.stress : "Loading..."}              
                valuei={formData['fy steel'] ? formData['fy steel'].value : ''}
                OnSetChange={(newValue,units,typeunit) => handleInputChange("fy steel", newValue,units,typeunit)} />                
                <IngenniInput label={N["Beam Span"]} valueType="realPositive" width="90%" placeholder="length span" units={unitData ? unitData.length : "Loading..."} 
                valuei={formData['Beam Span'] ? formData['Beam Span'].value : ''}
                OnSetChange={(newValue,units,typeunit) => handleInputChange("Beam Span", newValue,units,typeunit)}                 />
                <IngenniInput label={N["Base"]} valueType="realPositive" width="90%" placeholder="base section" units={unitData ? unitData.length : "Loading..."}
                valuei={formData['Base'] ? formData['Base'].value : ''}
                OnSetChange={(newValue,units,typeunit) => handleInputChange("Base", newValue,units,typeunit)}  />
                <IngenniInput label={N["Height"]} valueType="realPositive" width="90%" placeholder="height section" units={unitData ? unitData.length : "Loading..."} 
                valuei={formData['Height'] ? formData['Height'].value : ''}
                OnSetChange={(newValue,units,typeunit) => handleInputChange("Height", newValue,units,typeunit)} />
                <IngenniSelect label="Seismic Detail" options={optionsSeismicDetail}  
                valuei={formData['Seismic Detail'] ? formData['Seismic Detail'].value : ''}
                OnSetChange={(newValue,units,typeunit) => handleInputChange("Seismic Detail", newValue,units,typeunit)}                  
                ></IngenniSelect>
                <IngenniSelect label="Stations" options={optionsStations} 
                valuei={formData['Stations'] ? formData['Stations'].value : ''}
                 OnSetChange={(newValue,units,typeunit) => handleInputChange("Stations", newValue,units,typeunit)}                 
                ></IngenniSelect>                
                <IngenniInput label={N["rec-top"]} valueType="realPositive" width="90%" placeholder="top recover " units={unitData ? unitData.length : "Loading..."} 
                valuei={formData['rec-top'] ? formData['rec-top'].value : ''}
                OnSetChange={(newValue,units,typeunit) => handleInputChange("rec-top", newValue,units,typeunit)}  />
                <IngenniInput label={N["rec-bot"]} valueType="realPositive" width="90%" placeholder="bottom recover " units={unitData ? unitData.length : "Loading..."} 
                valuei={formData['rec-bot'] ? formData['rec-bot'].value : ''}
                OnSetChange={(newValue,units,typeunit) => handleInputChange("rec-bot", newValue,units,typeunit)} />
                <IngenniInput label={N["rec-side"]} valueType="realPositive" width="90%" placeholder="lateral recover" units={unitData ? unitData.length : "Loading..."} 
                valuei={formData['rec-side'] ? formData['rec-side'].value : ''}
                OnSetChange={(newValue,units,typeunit) => handleInputChange("rec-side", newValue,units,typeunit)}  />              
              </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={9.35} xl={9.35}>
        <Box  
           sx={{justifyContent: 'space-between'}} 
          >
            <Button
              onClick={() => toggleActiveButton('input')}              
              variant={activeComponent === 'input' ? "contained" : "outlined"}

              sx={{
                flexGrow: 1,
                marginRight: '4px', 
                minWidth: '150px'
              }}
            >
              Inputs
            </Button>
            <Button
              variant={activeComponent === 'output' ? "contained" : "outlined"}
              onClick={() => toggleActiveButton('output')}
              sx={{
                flexGrow: 1,
                minWidth: '150px'
              }}
            >
              Outputs
            </Button>
          </Box>

        
          <Box>
            {activeComponent === 'input' && <InputM05 activeElement={props.activeElement}/>}
            {activeComponent === 'output' && <OutputM05 activeElement={props.activeElement}/>}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default IngenniM05;
