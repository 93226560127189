import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import './2FormularioProyecto.css'; // Asegúrate de que este archivo exista y contenga tus estilos


const FormularioProyecto = ({ guardarDatosProyecto }) => {
    const estadoInicial = {
        nombreProyecto: '',
        SegundonombreProyecto:'',
        numeroProyecto: '',
        ciudad: '',
        calculo: '',
        fecha: '',
        reviso: '',
        observaciones: '',
        fechaFormatoOriginal: ''
    };
    
    // Estado inicial para el formulario
    const [datos, setDatos] = useState(estadoInicial);
    
    
    // Utilizar useEffect para manejar el side-effect de guardar los datos
    useEffect(() => {
        // Aquí puedes agregar validaciones antes de guardar los datos si lo necesitas
        guardarDatosProyecto(datos);
    }, [datos, guardarDatosProyecto]); // Dependencias del useEffect
    
    // Maneja el cambio en los inputs
   

    const handleChange = (e) => {
      let valor = e.target.value;
  
      if (e.target.name === 'fecha') {
          setDatos({
              ...datos,
              fechaFormatoOriginal: valor, // Actualiza el formato original
              fecha: convertirFecha(valor), // Actualiza el formato transformado
          });
      } else {
          setDatos({
              ...datos,
              [e.target.name]: valor,
          });
      }
  };
  
  

    const convertirFecha = (fecha) => {
      const meses = [
          "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
          "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
      ];
      const partes = fecha.split('-');
      const año = partes[0];
      const mes = meses[parseInt(partes[1], 10) - 1]; // -1 porque los arrays son base 0
      return `${mes} de ${año}`;
  }
  

  return (
    <div className="formulario-proyecto"> {/* Cambiado de <form> a <div> ya que no necesitamos enviar el formulario */}
      <label>
        Nombre del Proyecto:
        <input
          type="text"
          name="nombreProyecto"
          value={datos.nombreProyecto}
          onChange={handleChange}
          placeholder="Nombre del Proyecto"
          className="input-formulario"
        />
      </label>
      <label>
        Segundo Nombre del Proyecto:
        <input
          type="text"
          name="SegundonombreProyecto"
          value={datos.segundoNombreProyecto}
          onChange={handleChange}
          placeholder="Segundo Nombre del Proyecto"
          className="input-formulario"
        />
      </label>
      <label>
        Número del Proyecto:
        <input
          type="text"
          name="numeroProyecto"
          value={datos.numeroProyecto}
          onChange={handleChange}
          placeholder="Número del Proyecto"
          className="input-formulario"
        />
      </label>
      <label>
        Ciudad:
        <input
          type="text"
          name="ciudad"
          value={datos.ciudad}
          onChange={handleChange}
          placeholder="Ciudad"
          className="input-formulario"
        />
      </label>
      <label>
        Cálculo:
        <input
          type="text"
          name="calculo"
          value={datos.calculo}
          onChange={handleChange}
          placeholder="Cálculo"
          className="input-formulario"
        />
      </label>
      <label>
    Fecha:
    <input
    type="month"
    name="fecha"
    value={datos.fechaFormatoOriginal} // Utiliza fechaFormatoOriginal aquí
    onChange={handleChange}
    className="input-formulario"
/>

</label>

      <label>
        Revisó:
        <input
          type="text"
          name="reviso"
          value={datos.reviso}
          onChange={handleChange}
          placeholder="Persona que revisó"
          className="input-formulario"
        />
      </label>
      <label>
        Observaciones:
        <textarea
          name="observaciones"
          value={datos.observaciones}
          onChange={handleChange}
          placeholder="Observaciones"
          className="textarea-formulario"
        />
      </label>
     
    </div>
  );
};

FormularioProyecto.propTypes = {
  guardarDatosProyecto: PropTypes.func.isRequired,
};

export default FormularioProyecto;
