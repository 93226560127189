import { ConsoleSqlOutlined } from '@ant-design/icons';
import React, { createContext, useContext, useState,useEffect } from 'react';
import { fetchcustom } from '../../functions/fetch/fetchcustom';

export const DataContext = createContext();

export function useData() {
  return useContext(DataContext);
}
//console FROM DataProvider.js
export const DataProvider = ({ children }) => {

  const [datamodulesAuthorized, setdatamodulesAuthorized] = useState();
  const [modulesAuthorized,setodulesAuthorized]= useState();
  const [projects, setProjects] = useState([]);
  const [subprojects, setSubprojects] = useState([]);
  const [sheets, setSheets] = useState([]);
  const [enterprises, setEnterprise] = useState([]);
  const [UserEnterprise, setUsersEnterprise] = useState([]);
  const [user, setUser] = useState({
    userEmail: 'No Email',
    enterprise: 'No Enterprise',
    userName: 'No Name',
    userJob: 'No Job',
    role: 'No Role',
  });
  
  const[dataEnterprise,setDataEnterprise]=useState({    
      "widthLogo": "200",
      "UsersAuthorized": "10",
      "isCloudStorage": "true",
      "heightLogo": "120",
      "NameEnterprise": "",
      "PlanIngenni": "",
      "UrlLogo": "",
      "dateLastPayment": "",
      "SheetsIngenni": [
      ],
      "id": ""
  });

  const STORAGE_KEY = 'ElementDataForms';
  

  const [activeSheet, setActiveSheet] = useState(() => {    
    const savedData = localStorage.getItem('ActiveSheetData');
    return savedData ? JSON.parse(savedData) : {};
  });


  


  const [numberfecthtoprojects, setnumberfecthtoprojects] = useState(0);
  
  


//******************Load Modules Authorized for User**************************************** */
//******************Load Modules Authorized for User**************************************** */



useEffect(() => {
  loadUserModules();
  loadEnterpriseData()
}, []); 


const loadUserModules = async () => {
  const identerprise = localStorage.getItem('idEnterprise'); // Asegúrate de que 'useremail' esté correctamente escrito según lo guardes en localStorage
  const data = {
    "operation": "get_user_modules_by_enterprise",
    "identerprise": identerprise
  };
  
  try {
    const response = await fetchcustom("https://ss0pnhnrj9.execute-api.us-east-1.amazonaws.com/dev/crud-users", data);
    // Aquí manejas la respuesta. Por ejemplo, podrías guardar esta información en el estado.
    //console.log("User modules loaded successfully:", response.body.modulesAuthorizedIds);
    //console.log("User Data Modules loaded successfully:", response.body.modulesDetails);
    setodulesAuthorized(response.body.modulesAuthorizedIds)
    setdatamodulesAuthorized(response.body.modulesDetails)
    setEnterprise(response.body.enterpriseDetails)
    //console.log("AFER API DATAPROVIDER.js")
    //console.log(response.body.enterpriseDetails)
    //console.log(response.body.modulesDetails)
    //console.log(response.body.modulesAuthorizedIds)
  } catch (error) {
    console.error("Error loading user modules:", error);
  }
};

//************************************************************* */
//********************USE EFFECTS****************************** */
//************************************************************* */

  //******************UPDATE ACTIVE SHEETS**************************************** */
  //******************UPDATE ACTIVE SHEETS**************************************** */

  

  useEffect(() => {
    //console.log("FROM DataProvider.js");
    //console.log("Guardando activeSheet en localStorage:", activeSheet);
    localStorage.setItem('ActiveSheetData', JSON.stringify(activeSheet));
    let data=localStorage.getItem('ActiveSheetData');
    let dataobject=JSON.parse(data)
    //console.log("Recuperando del local Storage")
    //console.log(dataobject)
    
  }, [activeSheet]);


  useEffect(() => {
    console.log("FROM DataProvider.js");
    console.log(enterprises)
    
  }, [enterprises]);
  
//************************************************************* */
//********************PROJECTS CRUD****************************** */
//************************************************************* */

  //******************Load Projects**************************************** */
  //******************Load Projects**************************************** */
  const loadProjects = async () => {
    try {
      const identerprise = localStorage.getItem('idEnterprise');
      const getprojects = {
        "typeOperation": "readprojectsbyenterprise",
        "identerprise": identerprise
      };
      const response = await fetchcustom("https://ss0pnhnrj9.execute-api.us-east-1.amazonaws.com/dev/crud-projects", getprojects);
      // Asumiendo que la respuesta es directamente el objeto que has compartido
      // y response ya ha sido procesado por fetchcustom para ser un objeto JavaScript
      setProjects(response.body); // response.body ya es un arreglo
      const counterprojects=numberfecthtoprojects+1
      //setnumberfecthtoprojects(counterprojects)
      setnumberfecthtoprojects(prevCount => prevCount + 1);

    } catch (error) {
      console.error('Error al cargar proyectos:', error);
    }
  };

  //******************Add Project************************************** */
  //******************Add Project************************************** */

  const addProject = async (projectData) => {
    //console.log('Print From DataProvider.js-addProject Function');
    //console.log('Añadiendo proyecto:', projectData);
  
    // Obtener datos necesarios del localStorage
    //const enterprise = localStorage.getItem('Enterprise');
    const enterprise = enterprises.NameEnterprise || "";
    const identerprise = localStorage.getItem('idEnterprise');
  
    // Preparar el objeto de datos para enviar al backend
    const dataToSend = {
      "typeOperation": "create",
      "codigo": projectData.codigo, // Asumiendo que `codigo` viene en `projectData`
      "enterprise": enterprise,
      "nombre": projectData.nombre, // Asumiendo que `nombre` viene en `projectData`
      "identerprise": identerprise,
      "subproyectos": projectData.subproyectos // Asumiendo que `subproyectos` viene en `projectData`
    };
  
    try {
      const response = await fetchcustom("https://ss0pnhnrj9.execute-api.us-east-1.amazonaws.com/dev/crud-projects", dataToSend);
      if (response.statusCode === 200) {
        //console.log("Proyect added succesfully");
        // Aquí podrías hacer algo después de añadir el proyecto con éxito, por ejemplo:
        // Cargar de nuevo la lista de proyectos o mostrar un mensaje de éxito
        const dataprojectAdded={
          codigo:projectData.codigo,
          id:response.body.id,
          nombre:projectData.nombre,
          subproyectos:response.body.subprojects,          
        }
        console.log("DatapROJECT ADDED")
        console.log("DatapROJECT ADDED")
        console.log(dataprojectAdded)
        
        setProjects(prevprojects=>[...prevprojects,dataprojectAdded]); 

      } else {
        console.error("Error to add the  project:", response.body);
        // Manejar situaciones de error, como mostrar un mensaje al usuario
      }
    } catch (error) {
      console.error('Error to create the project:', error);
      // Manejar errores de red, por ejemplo, mostrando un mensaje al usuario
    }
  };
  
  const updateProject = async (projectId, newProjectData) => {
    console.log('Print From DataProvider.js ');
    console.log(`Actualizando proyecto ${projectId}:`, newProjectData);
    // Lógica para actualizar un proyecto
  };

  //******************Delete Project************************************** */
  //******************Delete Project************************************** */
  const deleteProject = async (projectId) => {
    console.log('Deleting project:', projectId);
    
    // Data to send to the API
    const dataToSend = {
      "typeOperation": "deleteProject",
      "id": projectId
    };
  
    try {
      const response = await fetchcustom("https://ss0pnhnrj9.execute-api.us-east-1.amazonaws.com/dev/crud-projects", dataToSend);
      
      // Check if the API response is successful
      if (response.statusCode === 200) {
        console.log("Project successfully deleted");
  
        // Update the 'projects' state to remove the deleted project
        setProjects(prevProjects => prevProjects.filter(project => project.id !== projectId));
      } else {
        console.error("Error deleting the project:", response);
        alert(response.body)
      }
    } catch (error) {
      console.error('Error calling the API to delete the project:', error);
    }
  };
  
  

  // Subprojects CRUD
  const loadSubprojects = async () => {
    console.log('Print From DataProvider.js ');
    console.log('Cargando subproyectos...');
    // Lógica para cargar subproyectos
  };


  //******************Update Project Name************************************** */
  //******************Update Project Name************************************** */

  const updateProjectName = async (projectId, newCode, newName) => {
    // Check if there's already a project with the new name
    const existingProject = projects.find(project => project.nombre === newName && project.id !== projectId);
  
    if (existingProject) {
      console.error("A project with that name already exists.");
      alert("A project with that name already exists.");
      return; // Or handle this case more appropriately according to your application
    }
  
    // Data structure to send
    const data = {
      typeOperation: "updateProject",
      id: projectId,
      NewCode: newCode,
      NewName: newName,
    };
  
    try {
      const response = await fetch("https://ss0pnhnrj9.execute-api.us-east-1.amazonaws.com/dev/crud-projects", {
        method: 'POST', // or 'PUT' if your API requires
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        const errorBody = await response.text(); // Or .json(), depending on your API
        throw new Error(`Failed to update the project. Status: ${response.status}, Body: ${errorBody}`);
      }
  
      const responseData = await response.json();
      //console.log("Project successfully updated:", responseData);
  
      // If the project name was successfully updated on the backend, update the local state
      const updatedProjects = projects.map(project => {
        if (project.id === projectId) {
          // Only change the name for the project that was updated
          return { ...project, nombre: newName, codigo: newCode };
        }
        return project;
      });
  
      setProjects(updatedProjects); // Update the state with the modified list
  
      //alert("Project updated successfully.");
  
    } catch (error) {
      console.error("Error updating the project name:", error);
      alert("Error updating the project.");
    }
  };

  //******************Update SubProject************************************** */
  //******************Update SubProject************************************** */


  const updateSubprojects = async (projectId, newSubprojectNames) => {
    // Estructura de datos para la operación de añadir nuevos subproyectos
    const data = {
      typeOperation: "addNewSubprojects",
      id: "d045b0b4-9925-4bf5-adf8-6ea6c4e0e5b9",
      newSubprojectNames: newSubprojectNames,
    };
    
  
    try {
      const response = await fetch("https://ss0pnhnrj9.execute-api.us-east-1.amazonaws.com/dev/crud-projects", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      console.log("From DataProvider.js ")
      console.log("From DataProvider.js") 
      console.log("From DataProvider.js")
      console.log(data)
  
      if (!response.ok) {
        const errorBody = await response.text(); // O .json(), dependiendo de tu API
        throw new Error(`Failed to add new subprojects. Status: ${response.status}, Body: ${errorBody}`);
      }
  
      const responseData = await response.json();
      console.log("Subprojects added successfully:", responseData);
  
      // Actualiza el estado local si los subproyectos se añadieron exitosamente en el backend
      // Nota: Asegúrate de adaptar esta parte según cómo quieras reflejar la adición de nuevos subproyectos en tu estado
      const updatedProjects = projects.map(project => {
        if (project.id === projectId) {
          // Suponiendo que los subproyectos están en una propiedad llamada 'subproyectos'
          const updatedSubprojects = [...project.subproyectos, ...newSubprojectNames];
          return { ...project, subproyectos: updatedSubprojects };
        }
        return project;
      });
  
      setProjects(updatedProjects); // Actualiza el estado con la lista modificada
  
    } catch (error) {
      console.error("Error adding new subprojects:", error);
      alert("Error adding new subprojects.");
    }
  };
  
  




  //******************Add SubProject************************************** */
  //******************Add SubProject************************************** */
  const addSubproject = async (subprojectData) => {
    try {
      const response = await fetchcustom("https://ss0pnhnrj9.execute-api.us-east-1.amazonaws.com/dev/crud-projects", subprojectData);
      if (response.statusCode === 200) {
        console.log("Subproyecto actualizado exitosamente", response);
  
        // Actualiza el estado de 'projects' con los nuevos subproyectos
        setProjects((currentProjects) => {
          return currentProjects.map((project) => {
            // Encuentra el proyecto por ID y actualiza sus subproyectos
            if (project.id === subprojectData.id) {
              return {
                ...project,
                // Suponiendo que response.body.newSubprojectNames contiene la lista actualizada de subproyectos
                subproyectos: [...project.subproyectos, ...response.body]
              };
            } else {
              return project; // Retorna los proyectos no afectados sin cambios
            }
          });
        });
      } else {
        console.error("Error al actualizar el subproyecto:", response);
      }
    } catch (error) {
      console.error('Error al añadir subproyecto:', error);
    }
  };
  
  





  
  const updateSubproject = async (subprojectId, newSubprojectData) => {
    console.log('Print From DataProvider.js ');
    console.log(`Actualizando subproyecto ${subprojectId}:`, newSubprojectData);
    // Lógica para actualizar un subproyecto
  };
  //******************Delete SubProject************************************** */
  //******************Delete SubProject************************************** */

const deleteSubproject = async (projectId, subprojectName) => {
  const dataToSend = {
    typeOperation: "deleteSubproject",
    id: projectId,
    subprojectName: subprojectName,
  };

  try {
    const response = await fetchcustom("https://ss0pnhnrj9.execute-api.us-east-1.amazonaws.com/dev/crud-projects", dataToSend);
    if (response.statusCode === 200) {
      console.log("Subproject deleted successfully", response);
      // Ahora actualiza el estado `projects` para reflejar la eliminación
      setProjects(prevProjects => prevProjects.map(project => {
        if (project.id === projectId) {
          // Filtra el subproyecto eliminado de la lista de subproyectos
          const updatedSubprojects = project.subproyectos.filter(subproject => subproject !== subprojectName);
          return { ...project, subproyectos: updatedSubprojects };
        }
        return project;
      }));
    } else {
      alert(response.body)
      console.error("Error deleting the subproject:", response);
    }
  } catch (error) {
    console.error("Error deleting subproject:", error);
  }
};



//************************************************************* */
//********************SHEETS CRUD****************************** */
//************************************************************* */


  //*******************Load Sheets *******************/
  //*******************Load Sheets *******************/
  const loadlistSheets = async () => {
    try {
      const emailuser = localStorage.getItem('useremail');
      const identerprise = localStorage.getItem('idEnterprise');
      const getusersheets = {
        "typeOperation": "readUserRecords",
        "enterprise": identerprise,
        "emailUserCreator": emailuser
      };
      const response = await fetchcustom("https://ss0pnhnrj9.execute-api.us-east-1.amazonaws.com/dev/crudsheets", getusersheets);
      // Asumiendo que la respuesta al endpoint de sheets es similar a la de projects
      setSheets(response.body); // response.body ya es un arreglo
      console.log("loadlistSheets from DataProvider.js")
      console.log(response.body)
    } catch (error) {
      console.error('Error al cargar hojas:', error);
    }
  };
  //*******************Add Sheet *******************/
  //*******************Add Sheet *******************/
  const addSheet = async (sheetData) => {
    //console.log('Print From DataProvider.js');
    //console.log('Añadiendo hoja:', sheetData);
  
    const selectedProject = projects.find(project => project.nombre === sheetData.project);
    
    if (!selectedProject) {
      console.error('Project not found:', sheetData.projectname);
      return; // Detener la ejecución si el proyecto no se encuentra
    }

    let selectedSubproject = "none"; // Valor por defecto si no se especifica subproyecto
    if (sheetData.subproject && sheetData.subproject !== "none") {
      const matchingSubproject = selectedProject.subproyectos.find(sp => sp.split(',')[0] === sheetData.subproject);
      if (matchingSubproject) {
        selectedSubproject = matchingSubproject; // Actualiza con el valor encontrado si existe
      } else {
        console.error('Subproject not found:', sheetData.subproject);
      }
    }
  
    try {
      const emailuser = localStorage.getItem('useremail');
      const identerprise = localStorage.getItem('idEnterprise');
      const createsheet = {
        "typeOperation": "create",
        "emailUserCreator": emailuser,
        "enterprise": identerprise,
        "isModule": sheetData.isModule,
        "nameSheet": sheetData.nameSheet,
        "project": selectedProject.id, // Usar el ID del proyecto seleccionado
        "subproject": selectedSubproject,
        "DataSheet": sheetData.Data
      };
      //console.log("THIS IS ADDSHEET FROM THE DATAPROVIDER")
      //console.log(createsheet)
      const response = await fetchcustom("https://ss0pnhnrj9.execute-api.us-east-1.amazonaws.com/dev/crudsheets", createsheet);
      if (response.statusCode==200){
      const  dataAddsheet={
        "id": response.id,
        "isModule": sheetData.isModule,
        "nameSheet": sheetData.nameSheet,
        "project": selectedProject.id,
        "subproject": selectedSubproject,
        "isModuleNickName": response.isModuleNickName
      }  
      
      alert(response.body)
      setSheets(prevSheets => [...prevSheets, dataAddsheet]); 

      const ActiveSheet={
        "idSheet":response.id,
        "nameSheet":sheetData.nameSheet,
        "DataSheet":sheetData.Data,
        "isModule": sheetData.isModule,
      }
      localStorage.removeItem('ActiveSheetData');
      setActiveSheet(ActiveSheet)
    }
    } catch (error) {
      console.error('Error creating sheet', error);
    }
  };
    
  const updateSheet = async (sheetId, newSheetData) => {
    console.log('Print From DataProvider.js ');
    console.log(`Actualizando hoja ${sheetId}:`, newSheetData);
    // Lógica para actualizar una hoja
  };
  const deleteSheet = async (sheetId) => {
    console.log('Print From DataProvider.js ');
    console.log(`Eliminando hoja ${sheetId}`);
    // Lógica para eliminar una hoja
  };

//************************************************************* */
//******************ACTIVE SHEETS CRUD************************* */
//************************************************************* */

const cleanActiveSheet=()=>{
  localStorage.removeItem('ActiveSheetData');
  setActiveSheet({})
}


const updateActiveSheet = (updatedData) => {
  // Intenta recuperar el activeSheet desde localStorage
  const savedActiveSheet = JSON.parse(localStorage.getItem('ActiveSheetData')) || {
    idSheets: "none",
    nameSheet: "anyName",
    
  };

  // Utiliza el activeSheet recuperado o inicializa con valores predeterminados
  let currentActiveSheet = savedActiveSheet

  const newActiveSheet = {
    ...currentActiveSheet,
    ...updatedData,
    idSheet: currentActiveSheet.idSheet, // Asegura que idSheet no se actualice
    DataSheet: {
      ...currentActiveSheet.DataSheet,
      ...updatedData.DataSheet
    }
  };

  // Actualiza el estado de activeSheet con los nuevos datos
  setActiveSheet(newActiveSheet);

  // Guarda los nuevos datos de activeSheet en el localStorage
  localStorage.setItem('ActiveSheetData', JSON.stringify(newActiveSheet));

  //console.log("Updated Active Sheet Data saved to localStorage");
};


//remove

//************************************************************* */
//********************ENTERPRISE CRUD************************** */
//************************************************************* */

  //*******************Get Enterprise basic Information*******************/
  //*******************Get Enterprise basic Information*******************/

  const loadEnterpriseData = async () => {
    const identerprise = localStorage.getItem('idEnterprise'); // Asegúrate de que 'useremail' esté correctamente escrito según lo guardes en localStorage
    const data = {
      "operation": "read",
      "id": identerprise
    };

    try {
      const response = await fetchcustom("https://ss0pnhnrj9.execute-api.us-east-1.amazonaws.com/dev/crud-enterprises", data);
      setDataEnterprise(response)
      localStorage.setItem('widthLogo',response.widthLogo); 
      localStorage.setItem('heightLogo',response.heightLogo); 
      localStorage.setItem('UrlLogo',response.UrlLogo); 
      localStorage.setItem('Enterprise',response.UrlLogo); 

      console.log("CONSOLE FROM DATAPROVIDER.js")
      console.log(response)      
    } catch (error) {
      console.error("Error loading Enterprise Data:", error);
    }
  };

  //*******************Update Enterprise basic Information*******************/
  //*******************Update Enterprise basic Information*******************/

const updateEnterprise = async (id, newName) => {
  const data = {
    operation: "updateEnterpriseName",
    id: id,
    newName: newName
  };

  try {
    const response = await fetch("https://ss0pnhnrj9.execute-api.us-east-1.amazonaws.com/dev/crud-enterprises", {
      method: 'POST', // o el método HTTP que necesites
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const responseData = await response.json(); // Asume que la respuesta es JSON

    console.log("Enterprise updated successfully:", responseData);
    localStorage.setItem('Enterprise', newName);     
    setEnterprise(prev => ({
      ...prev,
      NameEnterprise: newName
    }))
   
    
  } catch (error) {
    console.error("Error updating enterprise name:", error);
  }
};


//************************************************************* */
//********************USERS CRUD******************************* */
//************************************************************* */

const loadUsers = async () => {
  console.log('Print From DataProvider.js ');
  console.log('Cargando users...');
  // Lógica para cargar empresas
};
const addUser = async (userData) => {
  console.log('Print From DataProvider.js ');
  console.log('Añadiendo user:', userData);
  // Lógica para añadir una empresa
};
const updateUser = async (userId, newUserData) => {
  console.log('Print From DataProvider.js ');
  console.log(`Actualizando user ${userId}:`, newUserData);
  // Lógica para actualizar una empresa
};
const deleteUser = async (userId) => {
  console.log('Print From DataProvider.js ');
  console.log(`Eliminando user ${userId}`);
  // Lógica para eliminar una empresa
};

//************************************************************* */
//********************PRUBEAS RENDERIZADO ********************* */
//************************************************************* */
  //console.log("*******************************")
  //console.log("FROM DataProvider.js--LINE 260")
  //console.log("PRUEBAS DE RENDERIZADO")
  //console.log("Projects")
  //console.log(projects)
  //console.log(numberfecthtoprojects)
  //console.log("Sheets")
  //console.log(sheets)
  //console.log("Active Sheets")
  //console.log(activeSheet)


//************************************************************* */
//********************PRUBEAS RETORNO ********************* */
//************************************************************* */
  const value = {
    projects,
    loadProjects,
    addProject,
    updateProject,
    updateProjectName,
    deleteProject,
    subprojects,
    loadSubprojects,
    addSubproject,
    updateSubproject,
    deleteSubproject,
    sheets,
    loadlistSheets,
    addSheet,
    updateSheet,
    deleteSheet,
    enterprises,   
    updateEnterprise,   
    user,    
    updateActiveSheet,
    activeSheet,
    cleanActiveSheet,
    modulesAuthorized,
    datamodulesAuthorized,
    updateSubprojects,
    dataEnterprise
    
    
    
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

