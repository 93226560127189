import React, { useState, useEffect,useContext } from 'react';
import { useTheme,Modal, Box, Typography, TextField, Button, IconButton, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import {tokens} from '../../theme';
import { DataContext } from '../../contexts/ContextDataProvider/DataProvider';

const AddElementModal = ({ open, onClose, addOptions }) => {
  //Main Context
  const { updateActiveSheet , activeSheet } = useContext(DataContext);
  //Theme colors
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);  
  //Theme colors

  const [nombreElemento, setNombreElemento] = useState('');
  const [opcionSeleccionada, setOpcionSeleccionada] = useState('');
 //***********************UseEffects*************** */



  useEffect(() => {
    if (addOptions.length > 0) {
      setOpcionSeleccionada(addOptions[0]);
    } else {
      setOpcionSeleccionada('');
    }
  }, [addOptions]);

  useEffect(() => {
    if (addOptions.length > 0) {
      setOpcionSeleccionada(addOptions[0]);
    } else {
      setOpcionSeleccionada('');
    }
  }, [nombreElemento]);

  const handleNombreElementoChange = (event) => {
    setNombreElemento(event.target.value);
  };

  const handleOpcionSeleccionadaChange = (event) => {
    setOpcionSeleccionada(event.target.value);
  };

  const agregarElemento = () => {
    if (nombreElemento.trim() !== '' && (!addOptions.length || opcionSeleccionada)) {
      onClose(nombreElemento, opcionSeleccionada);
      setNombreElemento('');
      setOpcionSeleccionada('');
    }
  };

  const cerrarModal = () => {
    onClose();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      agregarElemento();
    }
  };

  return (
    <Modal open={open} onClose={cerrarModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: colors.primary.primary3,
          boxShadow: 24,
          p: {
            xs: 2, // Menos padding en dispositivos extra pequeños
            sm: 3, // Un poco más de padding en dispositivos pequeños
            md: 4, // Padding original en dispositivos medianos y grandes
          },
          width: {
            xs: '90%', // Ancho máximo en dispositivos extra pequeños
            sm: '80%', // Ancho reducido en dispositivos pequeños
            md: 500, // Ancho fijo para dispositivos medianos y grandes
          },
          borderRadius: 1,
          overflow: 'auto', // Asegura que el contenido sea desplazable si excede el alto de la ventana
          maxHeight: '90vh', // Asegura que el modal no sea más alto que el viewport, permitiendo espacio para la barra de direcciones móvil
        }}
      >
        <Typography variant="h6" marginBottom={3}>Add New Section for Design</Typography>
        <TextField
          label="Element Name"
          fullWidth
          value={nombreElemento}
          onChange={handleNombreElementoChange}
          onKeyDown={handleKeyDown}
          marginBottom={3}
        />
        {addOptions.length > 0 && (
          <Box mt={2}>
          <FormControl fullWidth marginBottom={3}>
            <InputLabel
            >Select Option</InputLabel>
            <Select
              value={opcionSeleccionada}
              label="Select Option"
              onChange={handleOpcionSeleccionadaChange}
            >
              {addOptions.map((opcion, index) => (
                <MenuItem key={index} value={opcion}>{opcion}</MenuItem>
              ))}
            </Select>
          </FormControl>
          </Box>
        )}
        <Box mt={2}>
          <Button 
            variant="contained"           
            fullWidth 
            onClick={agregarElemento}
            disabled={addOptions.length > 0 && !opcionSeleccionada}
          >
            Add
          </Button>
        </Box>
        
      </Box>
    </Modal>
  );
};

export default AddElementModal;
