import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

class PDFPrinter {
  constructor() {
    this.start = "Start";
  }

  hexToRgb(hex) {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return [r, g, b];
  }

  async createPDF(primaryColor, primaryColor2, textEntries) {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();
    const { width, height } = page.getSize();
    const rgbPrimaryColor = this.hexToRgb(primaryColor);
    const rgbPrimaryColor2 = this.hexToRgb(primaryColor2);

    page.drawRectangle({
      x: width * 0.25,
      y: height - 70,
      width: width * 0.75,
      height: 70,
      color: rgb(rgbPrimaryColor[0] / 255, rgbPrimaryColor[1] / 255, rgbPrimaryColor[2] / 255),
    });

    page.drawRectangle({
      x: 0,
      y: height - 70,
      width: width * 0.25,
      height: 70,
      color: rgb(rgbPrimaryColor2[0] / 255, rgbPrimaryColor2[1] / 255, rgbPrimaryColor2[2] / 255),
    });

    await this.drawTextLines(page, textEntries);

    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
  }

  async drawTextLines(page, textEntries) {
    const font = await page.doc.embedFont(StandardFonts.Helvetica);
    let yPosition = page.getHeight() - 100; // Start drawing text below the rectangles
    const fontSize = 12;
    const lineSpacing = 15;

    for (const [key, value] of Object.entries(textEntries)) {
      page.drawText(`${key}: ${value}`, {
        x: 50,
        y: yPosition,
        size: fontSize,
        font: font,
        color: rgb(0, 0, 0),
      });
      yPosition -= lineSpacing; // Move down for the next line
    }
  }
}

export default PDFPrinter;
