// 3dview.js (TreeJs)

import React, { useRef, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { GridHelper, Vector3, Quaternion, ArrowHelper } from 'three';

const Grid = ({ size = 10, divisions = 10 }) => {
  const grid = new GridHelper(size, divisions);
  return <primitive object={grid} />;
};

// Crear ejes en el origen como flechas
const Axes = () => {
  const arrowRefX = useRef();
  const arrowRefY = useRef();
  const arrowRefZ = useRef();

  useEffect(() => {
    if (arrowRefX.current) {
      arrowRefX.current.setDirection(new Vector3(1, 0, 0));
      arrowRefX.current.setColor('red');
      arrowRefX.current.setLength(2, 0.5, 0.5);
    }
    if (arrowRefY.current) {
      arrowRefY.current.setDirection(new Vector3(0, 1, 0));
      arrowRefY.current.setColor('green');
      arrowRefY.current.setLength(2, 0.5, 0.5);
    }
    if (arrowRefZ.current) {
      arrowRefZ.current.setDirection(new Vector3(0, 0, 1));
      arrowRefZ.current.setColor('blue');
      arrowRefZ.current.setLength(2, 0.5, 0.5);
    }
  }, []);

  return (
    <>
      <primitive ref={arrowRefX} object={new ArrowHelper()} />
      <primitive ref={arrowRefY} object={new ArrowHelper()} />
      <primitive ref={arrowRefZ} object={new ArrowHelper()} />
    </>
  );
};

// Crear cilindros metálicos para unir nodos
const CylinderConnection = ({ start, end, color }) => {
  const dir = new Vector3().subVectors(end, start);
  const length = dir.length();
  dir.normalize();

  const axis = new Vector3(0, 1, 0).cross(dir);
  const angle = Math.acos(new Vector3(0, 1, 0).dot(dir));
  const quaternion = new Quaternion().setFromAxisAngle(axis, angle);

  return (
    <mesh position={start.clone().add(end).multiplyScalar(0.5)} quaternion={quaternion}>
      <cylinderGeometry args={[0.1, 0.1, length, 32]} />
      <meshStandardMaterial color={color} metalness={0.8} roughness={0.2} />
    </mesh>
  );
};

const TreeJs = () => {
  // Nodos para conectar
  const nodes = [
    new Vector3(0, 0, 0),
    new Vector3(0, 5, 0),
    new Vector3(0, 0, 5),
    new Vector3(0, 5, 5),
    new Vector3(5, 0, 0),
    new Vector3(5, 0, 5),
    new Vector3(5, 5, 0),
    new Vector3(5, 5, 5),
  ];

  // Todas las conexiones entre los nodos
  const connections = [
    { start: nodes[0], end: nodes[1], color: 'silver' },
    { start: nodes[0], end: nodes[2], color: 'silver' },
    { start: nodes[0], end: nodes[4], color: 'silver' },
    { start: nodes[1], end: nodes[3], color: 'silver' },
    { start: nodes[1], end: nodes[6], color: 'silver' },
    { start: nodes[2], end: nodes[3], color: 'silver' },
    { start: nodes[2], end: nodes[5], color: 'silver' },
    { start: nodes[3], end: nodes[7], color: 'silver' },
    { start: nodes[4], end: nodes[5], color: 'silver' },
    { start: nodes[4], end: nodes[6], color: 'silver' },
    { start: nodes[5], end: nodes[7], color: 'silver' },
    { start: nodes[6], end: nodes[7], color: 'silver' },
  ];

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Canvas style={{ width: '100%', height: '70vh' }}>
        <OrbitControls />
        <ambientLight intensity={0.5} />
        <directionalLight position={[5, 5, 5]} intensity={1} />
        <Grid size={20} divisions={20} />
        <Axes />
        {connections.map(({ start, end, color }, index) => (
          <CylinderConnection key={index} start={start} end={end} color={color} />
        ))}
        {nodes.map((position, index) => (
          <mesh key={index} position={position}>
            <boxGeometry args={[0.5, 0.5, 0.5]} />
            <meshStandardMaterial color="orange" />
          </mesh>
        ))}
      </Canvas>
    </div>
  );
};

export default TreeJs;
