import {DataContext} from './contexts/ContextDataProvider/DataProvider.js';
import { useState,useEffect,useContext } from "react";
import { DataProvider } from "./contexts/ContextDataProvider/DataProvider";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import {BrowserRouter as Router,Route,Routes,Navigate} from 'react-router-dom'
import { CssBaseline, ThemeProvider,Box,useTheme } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import useMediaQuery from '@mui/material/useMediaQuery';
import { MyUser } from './scenes/user/user.js';
import HomePage from "./scenes/Home/Home";



//Others Pages
import ConstructionCard from "./scenes/UnderConstruccionCard/ConstructionCard";
import NotFound from "./scenes/NotFound/NotFound";
import { ProjectsView } from './scenes/Projects/Projects.js';
import { Mysheets } from './scenes/Sheets/mysheets.js';
import { MyEnterprise } from './scenes/Enterprise/Enterprise.js';
import RecoverPassword from "./scenes/recover/recover";


/// Modulos Enterprises


import McustomEnterprise01 from './scenes/CustomEnterpriseModules/McustonEnterprise0/McustomEnterprise01/McustomEnterprise01.js';
import McustomEnterprise02 from './scenes/CustomEnterpriseModules/McustonEnterprise0/McustomEnterprise02/McustomEnterprise02.js'; 
import McustomEnterprise03 from './scenes/CustomEnterpriseModules/McustonEnterprise0/McustomEnterprise03/McustomEnterprise03.js'; 
//Moulos Ingenni
import AppIngenniHandler from "./@IngenniCalc/Components/@AppIngenniHandler";
import Escena3D from "./IngenniCad/AppIngenniCad";
import FileUploader from "./IngenniCad/ReadGeometry";
import MfreeEnterprise01 from './scenes/CustomFreeMoudules/MfreeEnterprise01/main.js';
import MfreeEnterprise02 from './scenes/CustomFreeMoudules/MfreeEnterprise02/1Ma01MemoriasAycardi.js';



function PrivateRoutes({ onLogout,usernameoficial,plans2,idioma }) {
  //Data context
  const { loadProjects,modulesAuthorized, loadlistSheets} = useContext(DataContext);   

  useEffect(() => {
    loadProjects(); 
    loadlistSheets();
  }, [])

  useEffect(() => {
    console.log("modulesauthorized form AppPrivateRoutes.js"); 
    console.log(modulesAuthorized);
  }, [modulesAuthorized])

//Custom Components
const moduleToComponentMap = {
  McustomEnterprise01: McustomEnterprise01,
  McustomEnterprise02: McustomEnterprise02,
  McustomEnterprise03: McustomEnterprise03,
};

//Free Components
const moduleToComponentMapFree = {
  MfreeEnterprise01: MfreeEnterprise01,
  MfreeEnterprise02 :MfreeEnterprise02 

};



  
  
  const [theme,colorMode]=useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const usernameoficial2=usernameoficial  
  
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg')); // Pantallas grandes
  const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg')); // Pantallas medianas
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Pantallas pequeñas



  return (
  
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">  
        
          <Sidebar isSidebar={isSidebar} usernamesidebar={usernameoficial2} onLogout={onLogout} idioma={idioma}  />
          <main className="content">              
            {(isMobile || isTablet) && <Topbar setIsSidebar={setIsSidebar} onLogout={onLogout} idioma={idioma} />}
                    
              <Routes>   
              {/*RUTAS PARA DINAMICAS PARA MODULOS DE INGENNI*/}             
              {modulesAuthorized && modulesAuthorized.filter(moduleId => moduleId.startsWith("IngenniM")).map((moduleId, index) => (
                <Route
                  key={index}
                  path={`/${moduleId}`}
                  element={<AppIngenniHandler access={true} idioma={idioma} idtypemodule={moduleId} />}
                />
              ))}

              {/*RUTAS PARA DINAMICAS PARA MODULOS PERSONALIZADOS DE EMPRESAS*/}             
              {modulesAuthorized && modulesAuthorized
                                .filter(moduleId => moduleId.startsWith("McustomEnterprise"))
                                .map((moduleId, index) => {
                                    const Component = moduleToComponentMap[moduleId]; // Get the specific component from the map
                                    return Component ? (
                                        <Route
                                            key={index}
                                            path={`/${moduleId}`}
                                            element={<Component access={true} idioma={idioma} />}
                                        />
                                    ) : null;
                                })
                            }

              {/*RUTAS PARA DINAMICAS PARA FREE*/}    
              {modulesAuthorized && modulesAuthorized
                                .filter(moduleId => moduleId.startsWith("MfreeEnterprise"))
                                .map((moduleId, index) => {
                                    const Component = moduleToComponentMapFree[moduleId]; // Get the specific component from the map
                                    return Component ? (
                                        <Route
                                            key={index}
                                            path={`/${moduleId}`}
                                            element={<Component access={true} idioma={idioma} />}
                                        />
                                    ) : null;
                                })
                            }         


              

              {/*principal private routes*/}            
                <Route path='/myprojects' element={<ProjectsView/>}/>  
                <Route path='/mysheets' element={<Mysheets/>}/>                
                <Route path='/myenterprise' element={<MyEnterprise/>}/>                
                <Route path='/newproject' element={<ConstructionCard/>}/>                
                <Route path='/Dashboard' element={<ConstructionCard/>}/>  
                <Route path='/recover' element={<RecoverPassword/>}/>  
                <Route path='/' element={<HomePage/>}/>  
                <Route path='/user' element={<MyUser/>}/>   
                <Route path='*' element={<NotFound/>}/>      
                <Route path='/3d' element={<Escena3D/>} />
                <Route path='/ingennicad' element={<FileUploader/>} />     
              </Routes>            
          </main>
          
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>

  );
}

export default PrivateRoutes;
