import { useDrag} from 'react-dnd';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';


// Componente para elementos arrastrables


export const ItemArrastrable = ({ item, tipo, setElementos, elementos }) => {
    const [, drag] = useDrag(() => ({
      type: tipo,
      item,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }));
  
    const eliminarElementoDeLista = (id) => {
      const elementosFiltrados = elementos.filter((elemento) => elemento.id !== id);
      setElementos(elementosFiltrados);
    };
  
    return (
      <div 
        ref={drag} 
        className="contenedor-elemento" // Usa una clase para los estilos, en lugar de estilos en línea.
      >
        <span className="texto-elemento">{item.nombre}</span> {/* Contenedor para el texto, para controlar sus estilos. */}
        <IconButton
          onClick={() => eliminarElementoDeLista(item.id)}
          color="error"
          aria-label="delete"
          size="small"
        >
          <DeleteIcon />
        </IconButton>
      </div>
  
    );
  };