import React, { useState, useEffect,useContext } from 'react';
import { useTheme, Box, Modal, Button, TextField, Typography } from '@mui/material';
import useUserProfile from '../../hooks/useUserProfile/useUserProfile';
import { tokens } from '../../theme';
import { CircularProgress } from '@mui/material';
import UserProfileContext from '../../contexts/UserProfileContext/UserProfileContext';
import useMediaQuery from '@mui/material/useMediaQuery';



function UserProfileModal({ open, handleClose, identificationCode, initialUserName, initialJob }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userName, userJob } = useContext(UserProfileContext);
  const [userNameLocal, setUserName] = useState(userName);
  const [job, setJob] = useState(userJob);
  //const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Estado para manejar la visibilidad de un mensaje de error general
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { isLoading, error, response, performOperation } = useUserProfile({
    operation: "updateUserProfile",
    identificationCode,
    nameUser: userNameLocal,
    job,
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
   
    setShowSuccessMessage(false);
    
    //setShowSuccessMessage(false); // Añadido para resetear el mensaje de éxito
  }, [open]);

  useEffect(() => { 
    setShowSuccessMessage(false);    
    
  }, [userNameLocal, job]);
  

  const handleUpdate = async () => {
    // Revisa si alguno de los campos está vacío
    if (!userNameLocal.trim() || !job.trim()) {
      // Muestra el mensaje de error general y previene la ejecución del fetch
      setShowErrorMessage(true);
      return;
    } else {
      // Si todos los campos están llenos, oculta el mensaje de error y procede con el fetch
      setShowErrorMessage(false);
    }
    await performOperation();
    setShowSuccessMessage(true);
  };
  
  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        setShowErrorMessage(false); // Oculta el mensaje de error al cerrar el modal
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: colors.primary.primary2,
          border:theme.palette.mode === "dark" ? `0.5px solid ${colors.primary.third}` : `0.5px solid ${colors.primary.primary3}`,
          borderRadius: 1.5,
          boxShadow: 24,
        }}
      >
        <Box m={4}
         sx={{
          
            p: 0, // padding aplicado al Box para asegurar espaciado uniforme
            '& > *': { mb: 2 }, // Espaciado uniforme entre elementos
          }}


        >
          <Typography variant="h6">Update Profile</Typography>
          <TextField
            label="Email"
            variant="outlined"
            value={identificationCode}
            disabled
            fullWidth
            margin="normal"
            size="small"
          />
          <TextField
            label="User Name"
            variant="outlined"
            value={userNameLocal}
            onChange={(e) => setUserName(e.target.value)}
            fullWidth
            margin="normal"
            size="small"
            error={!userNameLocal.trim()} // Muestra el borde rojo si el campo está vacío
          />
          <TextField
            label="Job"
            variant="outlined"
            value={job}
            onChange={(e) => setJob(e.target.value)}
            fullWidth
            margin="normal"
            size="small"
            error={!job.trim()} // Muestra el borde rojo si el campo está vacío
          />
          {/* Muestra el mensaje de error si alguno de los campos está vacío */}
          {showErrorMessage && (
            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
              All fields must be filled out.
            </Typography>
          )}
        <Button
          onClick={handleUpdate}
          variant="contained"
          disabled={isLoading}
          fullWidth
          startIcon={isLoading && <CircularProgress size={20} color="inherit" />}
        >
          {isLoading ? 'Updating...' : 'Update'}
        </Button>


        {showSuccessMessage && (
          <Typography variant="body2" color="primary" sx={{ mt: 2 }}>
            Profile updated successfully!
          </Typography>
        )}

          {error && (
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default UserProfileModal;
