import React, { useContext, useEffect, useState} from 'react';
import { DataContext } from '../../contexts/ContextDataProvider/DataProvider.js';
import { Avatar,useTheme, Box, Typography, List, ListItem, ListItemText, ListItemIcon, Button, Collapse, IconButton,TextField,FormControl, InputLabel,Select,MenuItem} from '@mui/material';
import { tokens } from '../../theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import UserAvatar from '../../components/UserAvatar/UserAvatar.js';
import UserProfileModal from './UserProfileModal.js';
import PasswordRecoveryModal from '../recover/modalRecover.js';
import UserProfileContext from '../../contexts/UserProfileContext/UserProfileContext.js';





export function MyUser() {
  const { user,dataEnterprise } = useContext(DataContext);  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userName, userJob } = useContext(UserProfileContext);
  const [openModalr, setOpenModalr] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const identificationCode = localStorage.getItem("useremail")
  
  

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    // Estados iniciales vacíos o valores predeterminados
    // Estados iniciales con valores predeterminados
    const [userEmail, setUserEmail] =useState( user.userEmail);
    const [enterprise, setEnterprise] = useState(user.enterprise);
    //const [userName1, setUserName] = useState(userName);
    const userName1=userName
    const userJob1=userJob
    //const [userJob1, setUserJob] = useState(userJob);
    const [role, setRole] = useState(user.role);

    const passwordPlaceholder = "********"; //
  
    useEffect(() => {
      // Cargar los datos desde el localStorage
      const userEmail = localStorage.getItem('useremail') || 'No Email';
      const enterprise = dataEnterprise.NameEnterprise || 'No Enterprise';      
      const role = localStorage.getItem('rol') || 'No Role';
  
      setUserEmail(userEmail);
      setEnterprise(enterprise);
      setRole(role);
    }, []);
  


    const getInitials = (name) => {
        // Asegurar que name es una cadena antes de proceder
        name = name || '';
        
        const words = name.split(' ');
        let initials = '';
        
        if (words.length > 1 && words[0] && words[1]) {
          initials = words[0][0] + words[1][0];
        } else if (name.length > 1) {
          initials = name.substring(0, 2);
        } else if (name.length === 1) {
          initials = name[0];
        }
        
        return initials.toUpperCase();
      };
      
  

  

  return (
    <Box sx={{paddingBottom:2}}>
    <Box m={7.5} padding={5} borderRadius={2.5} mb={5} sx={{
      backgroundColor: colors.primary.primary,
      maxWidth: isDesktop ? '600px' : 'auto',
      marginRight: 'auto',
      marginLeft: 'auto',
      '@media (max-width:1280px)': {
        marginLeft: 2,
        marginRight: 2,
        marginBottom:5
      },
      '@media (max-width:600px)': {
        marginLeft: 1,
        marginRight: 1,
        marginBottom:5
      },
      //border: theme.palette.mode === 'light' ? `1px solid ${colors.primary.secondary}` : 'none',
      boxShadow: theme.palette.mode === 'light' ? `0 0 5px ${colors.grey[500]}` : 'none',
      //border: theme.palette.mode === 'light' ? `1px solid ${colors.grey[500]}` : 'none',
      //boxShadow: theme.palette.mode === 'light' ? `0 0 5px ${colors.blueAccent.contrast1}` : 'none',
    }}>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
      <AccountCircleIcon sx={{ fontSize: 40 }} /> {/* Ajusta el tamaño del icono según necesites */}
      <Typography variant="h3" gutterBottom>My User Profile</Typography>
    </Box>

    <Box display="flex" justifyContent="center" mt={2}>
        <Box display="flex" justifyContent="center" mt={2}> 
            <UserAvatar name={userName} width={75} height={75} />
        </Box>

        </Box>

        <Box mt={2}>
        
          <TextField
            label="User Email"
            variant="outlined"
            value={userEmail}
            size="small"
            fullWidth
            margin="normal"
            disabled
          />
          <TextField
            label="Enterprise"
            variant="outlined"
            value={enterprise}
            size="small"
            fullWidth
            margin="normal"
            disabled
          />
          <TextField
            label="Username"
            variant="outlined"
            value={userName1}
            size="small"
            fullWidth
            margin="normal"
            disabled
          />
          <TextField
            label="Job"
            variant="outlined"
            value={userJob1}
            size="small"
            fullWidth
            margin="normal"
            disabled
          />
          <TextField
            label="Role"
            variant="outlined"
            value={role}
            size="small"
            fullWidth
            margin="normal"
            disabled
          />
          <TextField
            label="Password"
            variant="outlined"
            value={passwordPlaceholder}
            size="small"
            fullWidth
            margin="normal"
            disabled
          />
          <Box mt={2} display="flex" gap={2}>
            <Button variant="outlined" onClick={() => setModalOpen(true)}>
              Edit Username or job
            </Button>

            <Button variant="outlined"
            onClick={()=>setOpenModalr(true)}
            >Reset Password</Button>
          </Box>
        </Box>
          </Box>

        <UserProfileModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        identificationCode={identificationCode}
        initialUserName={userName1}
        initialJob={userJob1}
      />
      <PasswordRecoveryModal
          open={openModalr}
          onClose={() => setOpenModalr(false)}
      />
    </Box>
  );
}
