import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate,Link } from "react-router-dom";
import { fetchcustom } from '../../functions/fetch/fetchcustom';
import {DataContext} from '../../contexts/ContextDataProvider/DataProvider.js';


const useAuth = () => {
  //const contextValue = useContext(DataContext);
  //console.log("print from useAuth.js");
  //const { loadProjects } = useContext(DataContext);    
  //console.log(loadProjects); // Esto debería mostrarte qué contiene `contextValue`

  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const navigateOut=useNavigate()

  useEffect(() => {
    const user = localStorage.getItem('user');
    const token = localStorage.getItem('token');
   
    if (user && token) {
      setLoggedIn(true);
      setUsername(localStorage.getItem('username'));
    }
  }, []);

  const handleLogin = async (email, password) => {
    try {
      const response = await fetch('https://ss0pnhnrj9.execute-api.us-east-1.amazonaws.com/dev/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });
      
      const data = await response.json();

      if (data.Authentication === "true") {
        // Establecer datos de sesión en localStorage
        localStorage.setItem('user', email);
        localStorage.setItem('token', 'your_token_value'); // Asume que obtienes un token real aquí
        navigateOut('/');      
        localStorage.setItem('useremail', data.email);  
        localStorage.setItem('username', data.username);      
        localStorage.setItem('userjob', data.job); 
        localStorage.setItem('rol', data.rol);    
        localStorage.setItem('idEnterprise', data.idEnterprise); 
        setLoggedIn(true);
        setUsername(data.username);

      } else {
        alert(data.additional_message);
      }
    } catch (error) {
      console.error('Error during login:', error);
      alert('Login failed');
    }
  };


  const handleLogout = () => {
    // Guardar temporalmente los datos que quieres conservar
    const recentModules = localStorage.getItem('recentModules');
  
    // Limpiar todo el localStorage
    localStorage.clear();
  
    // Restaurar en localStorage los datos que se decidieron conservar
    if (recentModules) {
      localStorage.setItem('recentModules', recentModules);
    }
  
    // Proceder con el resto de la lógica de cierre de sesión
    setLoggedIn(false);
    navigateOut('/');
   
  };
  

  return { loggedIn, username, handleLogin, handleLogout };
};

export default useAuth;
