import React, { useState,useContext } from 'react';
import {Autocomplete,useTheme, Modal, Box, Typography, TextField, Button, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';
import { tokens } from '../../theme';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataContext } from '../../contexts/ContextDataProvider/DataProvider';

const AddModalProjects = ({ open, onClose, onAddProject, isSubproject,onAddSubProject}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); 
  const { projects, addSubproject} = useContext(DataContext); 
  //console.log(projects)

  const [projectName, setProjectName] = useState('');
  const [projectCode, setProjectCode] = useState('');
  const [subProject, setSubProject] = useState('');
  const [subProjects, setSubProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectid, setSelectedProjectid] = useState(null);


  if(isSubproject){
    console.log("hola subproyecto")
  }

  const handleAddProject = () => {
    if (projectName && projectCode) {
      onAddProject(projectName, projectCode, subProjects);
      setProjectName('');
      setProjectCode('');
      setSubProjects([]);
      onClose(); // Cerrar el modal después de añadir el proyecto
    }
  };


  const handleAddSubProjects = () => {
    if (selectedProject && projectCode && subProjects.length > 0) {
      const dataToSend = {
        "typeOperation": "addNewSubprojects",
        "id": selectedProjectid,
        "newSubprojectNames": subProjects
      };
  
      // Llama a addSubproject desde tu contexto global con los datos recogidos
      addSubproject(dataToSend);
  
      // Restablece los estados locales después de enviar los datos
      setProjectName('');
      setProjectCode('');
      setSubProjects([]);
      setSelectedProject(null);
      setSelectedProjectid(null);
      onClose(); // Cerrar el modal
    }
  };
  
  const handleAddSubProject = () => {
    if (subProject.trim() !== '' && !subProjects.includes(subProject.trim())) {
      setSubProjects([...subProjects, subProject.trim()]);
      setSubProject('');
    }
  };

  const handleDeleteSubProject = (index) => {
    setSubProjects(subProjects.filter((_, i) => i !== index));
  };
  const name= isSubproject? "Add New Subprojects": "Add New Project"
  const codelabel=  isSubproject? "Project Code": "Enter Project Code"
  const aceptbuttonlabel=  isSubproject? "Save Subprojects": "Save Project"
  const buttonsubproject=  isSubproject? "contained": "outlined"

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: colors.primary.primary3,
          boxShadow: 24,
          p: 4,
          width: 400,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography variant="h5" marginBottom={1}>{name}</Typography>
        { !isSubproject && (
          <Box>
        
        <TextField
          label="Enter Project Name"
          fullWidth
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          size="small"
        />
        </Box>
        )
        }

      {isSubproject && (
        <Autocomplete
          value={selectedProject}
          onChange={(event, newValue) => {
            setSelectedProject(newValue); // Actualizas el proyecto seleccionado
            setProjectCode(newValue?.codigo || ''); // Actualizas el código del proyecto
            setSelectedProjectid(newValue?.id || null); // Aquí actualizas el id del proyecto seleccionado
          }}
          options={projects.sort((a, b) => a.nombre.localeCompare(b.nombre))}
          getOptionLabel={(option) => option.nombre || ''} // Asegura mostrar el nombre del proyecto
          renderInput={(params) => (
            <TextField {...params} label="Select a Project" margin="normal" size="small" />
          )}
        />
      )}




        <TextField
          label={codelabel}
          fullWidth
          value={projectCode}
          onChange={(e) => setProjectCode(e.target.value)}
          size="small"
          disabled={isSubproject}
        />
        <Box sx={{ display: 'flex', gap: 1 }}>
          <TextField
            label="Add Subproject"
            fullWidth
            value={subProject}
            onChange={(e) => setSubProject(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleAddSubProject()}
            size="small"
          />
          <Button
            variant={buttonsubproject}
            onClick={handleAddSubProject}
            size="small"
          >
            Add
          </Button>
        </Box>
        <List>
          {subProjects.map((subProject, index) => (
            <ListItem key={index} divider>
              <ListItemText primary={subProject} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteSubProject(index)}>
                  <DeleteIcon sx={{"color":colors.redAccent.contrast1}} />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <Button
          variant="contained"
          onClick={isSubproject ? handleAddSubProjects : handleAddProject}
          sx={{ mt: 2 }}
        >
          {aceptbuttonlabel}
        </Button>


        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', top: 4, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Modal>
  );
};

export default AddModalProjects;

