import { useState, useEffect } from 'react';


function useLanguageLabels(names) {
  const [labels, setLabels] = useState({});
  useEffect(() => {
    const language = localStorage.getItem('idioma') || 'en'; // Asume 'en' como idioma predeterminado si no se encuentra nada en el almacenamiento local.
    setLabels(names[language]);
  }, []); 
  return labels;
}
export default useLanguageLabels;
