import React, { useState } from 'react';
import { Box, Button, CircularProgress, TextField, Typography, Modal, useMediaQuery, useTheme } from '@mui/material';
import PasswordRecoveryModal from '../../scenes/recover/modalRecover';
import { tokens,ColorModeContext } from '../../theme';
//import { tokens,ColorModeContext } from "../../theme";

const LoginV1_0 = ({ onLogin }) => {
    //Colors theme
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);  

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);

   
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleLoginClick = async () => {
        setIsLoading(true);
        try {
            await onLogin(email, password);
        } catch (error) {
            console.error('Error during login:', error);
        }
        setIsLoading(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleLoginClick();
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                height: '93vh',
                '& .MuiTextField-root': { mb: 2 }, // margin bottom for all text fields
            }}
        >
            {!isMobile && (
                <Box
                    sx={{
                        bgcolor: '#141b2b',
                        width: '80%',
                        height: '100%',
                    }}>
                          <img
                        src="/ingenniLoginReferencial.jpg"
                        alt="Ingenni Login"
                        style={{ width: '100%', height: '99%' }} // Center image and add bottom margin
                    />
                    </Box>

                    
                
            )}
            <Box
                sx={{
                    bgcolor: isMobile ? 'transparent' : colors.primary.primary,
                    width: isMobile ? '100%' : '20%',
                    height: isMobile ? '75vh' : '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: isMobile ? 2 : 3,
                }}
            >
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        width: '95%',
                        maxWidth: '400px',
                        padding: isMobile ? 2 : 0,
                    }}
                >
                    <img
                        src="/onlyLogoIngenni.png"
                        alt="Ingenni Logo"
                        style={{ width: '80%', maxWidth: '120px', margin: '0 auto 20px auto' }} // Center image and add bottom margin
                    />
                    <Typography variant="h4" textAlign="center" gutterBottom>
                        Login in Ingenni
                    </Typography>
                    <TextField
                        id="username"
                        label="Email User"
                        variant="outlined"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        size="medium"
                        
                    />
                    <TextField
                       size="medium"
                        id="password"
                        label="Password"
                        variant="outlined"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <Button
                
                        type="submit"
                        variant="contained"
                       
                        disabled={isLoading}
                   
                        endIcon={isLoading && <CircularProgress color="inherit" size={20} />}
                    >
                        {isLoading ? 'Loading...' : 'Enter'}
                    </Button>
                    <Button
                
                        sx={{
                            textTransform: 'none',
                            color: colors.primary.secondary3,
                           
                            justifyContent: 'flex-end',
                            mt: 1, // margin top
                        }}
                        onClick={handleOpenModal}
                    > 
                        Forgot your password?
                    </Button>
                </Box>
            </Box>
            {openModal && (
                <PasswordRecoveryModal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                />
            )}
        </Box>
    );
};

export default LoginV1_0;
