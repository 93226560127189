import React, { useContext, useState } from 'react';
import { DataContext } from '../../contexts/ContextDataProvider/DataProvider.js';
import { useTheme, Box, Typography, IconButton, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { tokens } from '../../theme';
import ListAltIcon from '@mui/icons-material/ListAlt';
import useMediaQuery from '@mui/material/useMediaQuery';

export function Mysheets() {
  const { sheets, projects } = useContext(DataContext);
  const [searchText, setSearchText] = useState('');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const safesheets = Array.isArray(sheets) ? sheets : [];

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Función para obtener el nombre del subproyecto basado en el número después de la coma
  const getSubprojectNameFromId = (sheetSubprojectId) => {
    // Verificar primero si el subproyecto es "none"
    if (sheetSubprojectId === "none") {
      return "none"; // Retorna directamente el valor deseado para "none"
    }
  
    let subprojectName = 'none'; // Valor por defecto si no se encuentra coincidencia
  
    projects.forEach(project => {
      const foundSubproject = project.subproyectos.find(subproject => {
        const [, subprojectId] = subproject.split(','); // Divide por la coma y toma el segundo elemento
        return subprojectId === sheetSubprojectId.split(',')[1];
      });
  
      if (foundSubproject) {
        [subprojectName] = foundSubproject.split(','); // Actualiza subprojectName con el nombre antes de la coma
      }
    });
  
    return subprojectName;
  };
  


  // Transformar las hojas (sheets) para incluir el nombre del proyecto
  const sheetsWithProjectName = safesheets.map(sheet => {
    const project = projects.find(project => project.id === sheet.project);
    const subprojectName = getSubprojectNameFromId(sheet.subproject);
    return {
      ...sheet,
      projectName: project ? project.nombre : 'Default', // Asume 'No Project' si no se encuentra
      subprojectName,
    };
  });

  const columns = [
    { field: 'nameSheet', headerName: 'Sheet Name', width: 200 },
    { field: 'isModuleNickName', headerName: 'Module type', width: 180 },
    { field: 'projectName', headerName: 'Project', width: 180 }, // Usar 'projectName'
    { field: 'subprojectName', headerName: 'Subproject', width: 180 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton color="primary" aria-label="edit" size="small">
            <EditIcon fontSize="inherit" />
          </IconButton>
          <IconButton color="error" aria-label="delete" size="small">
            <DeleteIcon fontSize="inherit" />
          </IconButton>
          <IconButton color="secondary" aria-label="open" size="small">
            <OpenInNewIcon fontSize="inherit" />
          </IconButton>
        </Box>
      ),
    },
  ];

  // Filtra las filas basándose en la búsqueda
  const filteredSheets = sheetsWithProjectName.filter(sheet =>
    sheet.nameSheet.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <Box sx={{ paddingBottom: 2 }}>
      <Box m={7.5} padding={5} borderRadius={2.5} mb={5} 
      sx={{
        backgroundColor: colors.primary.primary,
        maxWidth: isDesktop ? '1000px' : 'auto',
        marginRight: 'auto',
        marginLeft: 'auto',
        '@media (max-width:1280px)': {
          marginLeft: 2,
          marginRight: 2,
          marginBottom:5
        },
        '@media (max-width:600px)': {
          marginLeft: 1,
          marginRight: 1,
          marginBottom:5
        },
        //border: theme.palette.mode === 'light' ? `1px solid ${colors.primary.secondary}` : 'none',
        boxShadow: theme.palette.mode === 'light' ? `0 0 5px ${colors.grey[500]}` : 'none',
        //border: theme.palette.mode === 'light' ? `1px solid ${colors.grey[500]}` : 'none',
        //boxShadow: theme.palette.mode === 'light' ? `0 0 5px ${colors.blueAccent.contrast1}` : 'none',
      }}
      >
        <Typography variant="h3" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <ListAltIcon />
          My Sheets
        </Typography>
        <TextField
          fullWidth
          label="Search Sheets"
          variant="outlined"
          size="small"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          sx={{ mb: 2 , mt:1 }}
        />
        <DataGrid
          rows={filteredSheets}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          getRowId={(row) => row.id}
        />
      </Box>
    </Box>
  );
}
