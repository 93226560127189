import React, { useState, useEffect, useContext } from "react";
import { useTheme,Grid, Box, Typography, CircularProgress, TextField } from "@mui/material";
import Autocomplete from "@mui/lab/Autocomplete";
import { DataContext } from '../../contexts/ContextDataProvider/DataProvider';
import { extractModuleData } from "../../functions/extractModuleData/extractModuleData";
import SheetsList from '../../components/App/cardmain';
import { tokens } from '../../theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import Divider from '@mui/material/Divider';



function HomePage() {
  const theme = useTheme();
  const { datamodulesAuthorized } = useContext(DataContext);   
  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);    
  const [classificationOptions, setClassificationOptions] = useState([]);
  const [selectedClassification, setSelectedClassification] = useState(null);
  const [recentModulesData, setRecentModulesData] = useState([]);
  const [notifications, setNotifications] = useState([{ id: 1, text: 'New structural design available!' }, { id: 2, text: 'Updated compliance guidelines released.' }]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const colors = tokens(theme.palette.mode);
  const height = datamodulesAuthorized ? "auto" : "100vh";


  useEffect(() => {
    if (datamodulesAuthorized) {      
      const extractFilter = extractModuleData(datamodulesAuthorized);      
      setClassificationOptions(extractFilter || []);
      console.log(extractFilter)
    }
  }, []);
  





  useEffect(() => {
    // Asegúrate de que datamodulesAuthorized está cargado antes de intentar filtrar
    if (datamodulesAuthorized) {
      const filtered = datamodulesAuthorized.filter(item =>
        item.nameModule.toLowerCase().includes(filter.toLowerCase())
      );
      setFilteredData(filtered);
      const extractFilter = extractModuleData(datamodulesAuthorized);      
      setClassificationOptions(extractFilter || []);
      console.log(extractFilter)


      const recentModules = JSON.parse(localStorage.getItem('recentModules')) || [];
      const filteredRecentModules = recentModules.filter(moduleId => moduleId !== undefined || null || "")
      console.log("recentModules---inside")
      console.log(filteredRecentModules)

      const filteredModules = datamodulesAuthorized.filter(module => 
        recentModules.includes(module.id)
      );
    
      setRecentModulesData(filteredModules)

      
      

    }
  }, [filter, datamodulesAuthorized]);

  useEffect(() => {
    if (datamodulesAuthorized) {
      const filtered = datamodulesAuthorized.filter(item =>
        item.nameModule.toLowerCase().includes(filter.toLowerCase())
      );
      setFilteredData(filtered);
      
      
    }
  }, [filter, datamodulesAuthorized]);

//onst
useEffect(() => {
  if (datamodulesAuthorized) {
    const newFilteredData = datamodulesAuthorized.filter(module => {
      // Filtrado por nombre o descripción
      const matchesNameOrDescription = module.nameModule.toLowerCase().includes(filter.toLowerCase()) ||
        module.DataModule.description.toLowerCase().includes(filter.toLowerCase());

      // Verifica si se seleccionó "Custom Module Enterprise"
      const isCustomModuleSelection = selectedClassification === "Custom Module Enterprise";

      // Si se seleccionó "Custom Module Enterprise", verifica si isCustomModule es true.
      // De lo contrario, realiza el filtrado basado en las clasificaciones.
      const matchesClassification = isCustomModuleSelection ? 
        module.DataModule.isCustomModule == "true" : 
        selectedClassification ? (
          module.DataModule.clasificationelement === selectedClassification ||
          module.DataModule.clasificationmaterial === selectedClassification ||
          module.DataModule.clasificationuse === selectedClassification ||
          module.DataModule.clasificationtypecalcsheet === selectedClassification ||
          module.DataModule.structuralCodeAvailable.includes(selectedClassification)
        ) : true; 

      return matchesNameOrDescription && matchesClassification;
    });
    setFilteredData(newFilteredData);
  }
}, [filter, selectedClassification, datamodulesAuthorized]);

const handleNotificationClick = () => {
  setIsDrawerOpen(true);
};

const handleRemoveNotification = (id) => {
  setNotifications(prev => prev.filter(notification => notification.id !== id));
};

  

  return (
    <Box sx={{ width: '100%', height: height, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      {!datamodulesAuthorized ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
          <Typography variant="h6" sx={{ mt: 2 }}>Loading...</Typography>
        </Box>
      ) : (
        <>
        
        <Box
          
          sx={{
            width: '95%', // Asegura que el Box ocupe todo el ancho disponible
            borderBottom: 1, // Agrega una línea debajo del Box
            borderColor: 'divider', // Usa el color 'divider' para la línea, puedes cambiarlo por cualquier color de tu tema
            pb: 1, // Agrega un poco de padding debajo del texto para separarlo de la línea
            
          }}
        >

<Box mt={5} ml={5} sx={{ display: 'flex', alignItems: 'center' }}>
  

<Box sx={{ 
    ml: 2, 
    mr: 3, 
    backgroundColor: colors.primary.textblack, 
    borderRadius: '50%', 
    width: 48, 
    height: 48, 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center',
    border: '2px solid',
    borderColor: notifications.length > 0 ? colors.primary.third : colors.primary.textblack 
}}> 
  <IconButton color="inherit" onClick={handleNotificationClick}>
    <Badge badgeContent={notifications.length > 5 ? '+5' : notifications.length} sx={{color: colors.primary.third}}>
      <NotificationsIcon sx={{ color: notifications.length > 0 ? theme.palette.primary.third : theme.palette.grey[500] }} />
    </Badge>
  </IconButton>
</Box>


  <Box>
    <Typography variant="h3" component="div" sx={{ textAlign: 'left' }}>New Sheet</Typography>
  </Box>

</Box>


        </Box>

        <Box sx={{backgroundColor:colors.primary.primary,borderRadius:2.5,
          width: { xs: '400', md: '500px' },
          boxShadow: theme.palette.mode === 'light' ? `0 0 5px ${colors.grey[500]}` : 'none',
        }} p={2} m={1} mt={4} mb={4}>
          <Box mt={1} mb={3} sx={{ textAlign: 'center' }}> {/* Agrega textAlign: 'center' para centrar el texto */}
            <Typography variant="h4">Search structural design modules</Typography>
          </Box>
          <Box>

          <Box>
          <TextField
            label="Search Module by Name"
            variant="outlined"
            fullWidth
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            sx={{ mb: 2, "& .MuiInputBase-root": { color: colors.blueAccent.contrast1 } }} // Ajusta el color del texto a rojo si lo deseas
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: colors.yelloAccent.contrast1 }} /> {/* Ajusta el color del icono a rojo */}
                </InputAdornment>
              ),
            }}
          />

          </Box>
          <Box>
          
          <Autocomplete
            options={classificationOptions}
            value={selectedClassification}
            onChange={(event, newValue) => {
              setSelectedClassification(newValue);
            }}
            freeSolo
            sx={{ width: '100%', mb: 2 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search by Classification"
                variant="outlined"
                size='small'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon sx={{ color: colors.yelloAccent.contrast1 }} />
                    </InputAdornment>
                  ),
                  // Agrega estilos directamente a los inputProps para cambiar el color del texto
                  sx: {
                    "& .MuiInputBase-input": {
                      color: colors.blueAccent.contrast1, // Cambia 'red' por el código de color específico que necesitas
                    }
                  }
                }}
              />
            )}
          />




          </Box>
          </Box>
          </Box>

          <Drawer anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
  <Box sx={{ width: 250 }} role="presentation">
    <List>
      {notifications.map((notification) => (
        <ListItem 
          key={notification.id} 
          secondaryAction={
            <IconButton edge="end" onClick={() => handleRemoveNotification(notification.id)}>
              <CheckIcon sx={{ color: colors.blueAccent.contrast1 }} />
            </IconButton>
          }
          sx={{ 
            marginTop: 1,
            marginBottom: 0.5,  // Agrega un margen inferior a cada ListItem
            backgroundColor: colors.primary.primary2, // Establece el color de fondo para cada ListItem
            borderRadius: '4px'  // Opcional: Agrega un borde redondeado a cada ListItem
          }}
        >
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>
          <ListItemText primary={notification.text} />
        </ListItem>
      ))}
      {notifications.length === 0 && <ListItem><ListItemText primary="No new notifications" /></ListItem>}
    </List>
    <Divider />

  </Box>
</Drawer>



          
          {(!filter && !selectedClassification) && (
          <Box          
            sx={{
              width: '95%', // Asegura que el Box ocupe todo el ancho disponible
              borderBottom: 1, // Agrega una línea debajo del Box
              borderColor: 'divider', // Usa el color 'divider' para la línea, puedes cambiarlo por cualquier color de tu tema
              pb: 1, // Agrega un poco de padding debajo del texto para separarlo de la línea
            }}
          >
            <Box ml={5}>
            <Typography variant="h3" component="div" sx={{ textAlign: 'left' }}>Last opened structural modules</Typography>
            </Box>
          </Box>)}
          {(!filter && !selectedClassification) && (
          <Box sx={{
         px: {
          xs: 2, // Padding horizontal para móviles
          md: 4, // Padding horizontal para tabletas
          lg: 6 // Padding horizontal para desktop
        }
        }}>
          <Box mt={3} mb={3}>
          <SheetsList cardDataList={recentModulesData} wmax={250} image="false"></SheetsList>
          </Box>
        </Box>)}      
          <Box          
            sx={{
              width: '95%', // Asegura que el Box ocupe todo el ancho disponible
              borderBottom: 1, // Agrega una línea debajo del Box
              borderColor: 'divider', // Usa el color 'divider' para la línea, puedes cambiarlo por cualquier color de tu tema
              pb: 1, // Agrega un poco de padding debajo del texto para separarlo de la línea
            }}
          >
            <Box ml={5}>
            <Typography variant="h3" component="div" sx={{ textAlign: 'left' }}>Structural modules</Typography>
            </Box>
          </Box>

          <Box sx={{
         px: {
          xs: 2, // Padding horizontal para móviles
          md: 4, // Padding horizontal para tabletas
          lg: 6 // Padding horizontal para desktop
        }
        }}>
          <Box mt={3} mb={3}>
          <SheetsList cardDataList={filteredData} wmax={250} image="false"></SheetsList>
          </Box>
        </Box>
        </>
      )}
    </Box>
  );
}

export default HomePage;
