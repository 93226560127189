import unitsNames from '../UnitsFile/UnitsFile.json';

export function find_unit_category(unit) {
    for (const categories of Object.values(unitsNames)) {
      for (const [key, value] of Object.entries(categories)) {
        if (value === unit) {
          return key; // Retorna la categoría a la que pertenece la unidad
        }
      }
    }
    return "adimensional"; // En caso de no encontrar la unidad en ninguna categoría
  }