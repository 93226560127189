import React, { useState,useEffect } from 'react';
import { Button, Typography, Box, TextField } from '@mui/material';
import { loadActiveElementData } from '../../Functions/loadActiveElementData';
import { createHandleInputChange } from '../../Functions/handleInputChange';

function IngenniM01({ temporalInputSave, activeElement }) {
  //Obligatorio en todos los componentes para el correcto funcionamiento
  const [dataValues, setDataValues] = useState(() => loadActiveElementData(activeElement));
  const handleInputChange = createHandleInputChange(setDataValues, temporalInputSave);

  useEffect(() => {
    const loadedData = loadActiveElementData(activeElement);
    setDataValues(loadedData);
  }, [activeElement]);
  

  return (
    <div>      
      <Box>
        <Typography variant="h1">Has entrado</Typography>
        <Typography variant="h1">Has entrado</Typography>
        <Typography variant="h1">Al modulo M01</Typography>

        {/* Campos de entrada */}
        <TextField
          label="Campo 1"
          name="campo1"
          value={dataValues.campo1 || ''}
          onChange={handleInputChange}
        />
        <TextField
          label="Campo 2"
          name="campo2"
          value={dataValues.campo2 || ''}
          onChange={handleInputChange}
        />
        <TextField
          label="Campo 3"
          name="campo3"
          value={dataValues.campo3 || ''}
          onChange={handleInputChange}
        />
      </Box>      
    </div>
  );
}

export default IngenniM01;

