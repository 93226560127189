import React, { useState, useEffect } from 'react';
import { Typography ,Box,TextField,InputAdornment} from '@mui/material';
import { find_unit_category } from '../../Functions/find_unit_category';
import { convertFunction } from '../../hooks/useConversionUnits/conversiones';
import { evaluate } from 'mathjs';

const IngenniInput = ({
  placeholder = "Enter a number",
  units = '',
  valueType = 'any',
  label = "any",
  defaultValue = '',
  valuei,
  error = false,
  labelError ,
  // Estilos predeterminados
  backgroundColor = '#ffffff',
  focusBackgroundColor = '#ffffff',
  borderColor = '#dcdcdc',
  focusBorderColor = '#007BFF',
  borderRadius = '4px',
  fontSize = '14px',
  color = '#000000',
  padding = '10px 14px',
  width = '100%',
  OnSetChange,
  variant = 'outlined' // Valor predeterminado
    
}) => {
  const [value, setValue] = useState(defaultValue);
  const [isFocused, setIsFocused] = useState(false); // Estado para manejar el enfoque
  const [inputId] = useState(() => `input-${Math.random().toString(16).slice(2)}`);
  const [previousUnits, setPreviousUnits] = useState(units); // Almacena las unidades anteriores
  const [timer, setTimer] = useState(null);

      




  const validateValue = (newValue) => {
    switch (valueType) {
      case 'realPositive':
        // Permite números positivos con o sin decimales, y el punto decimal sin seguir de números
        return /^[\d+./*^-]+$/g.test(newValue) || newValue === '';
    
      case 'realNegative':
        // Permite números negativos con o sin decimales, y el signo negativo solo
        return /^-?[\d.+\-*/^]*\.?\d*$/g.test(newValue) || newValue === '';
      case 'integerPositive':
        // Solo permite números enteros positivos
        return /^[\d+/*^-]+$/g.test(newValue) || newValue === '';
      case 'integer': // Renombrar a 'integer' si ahora acepta ambos, positivos y negativos.
        //return /^-?\d+([+\-*/^]-?\d+)*$/.test(newValue) || newValue === '';
        return /^[\d+/*^-]+$/g.test(newValue) || newValue === '';
        
      case 'real':
        // Ajuste para permitir el punto decimal al inicio y números negativos con o sin decimales
        return /^-?[\d.+\-*/^]*\.?\d*$/g.test(newValue) || newValue === '';
        

      case 'any':
      default:
        // Acepta cualquier valor
        return true;
    }
  };
  
  

  useEffect(() => {
    // Este useEffect se encargaba de actualizar el valor cuando cambian las unidades.
    // Se ha eliminado la lógica de conversión para cumplir con tu solicitud.
    setPreviousUnits(units);
  }, [units]);

// Suponiendo que todo lo demás en IngenniInput se mantiene igual
const handleChange = (event) => {
  let newValue = event.target.value;

  // Solo procede con la validación y posible reseteo para casos no 'any'
  if (valueType !== 'any') {
    // Verifica si hay tres o más operadores matemáticos seguidos en cualquier parte de la entrada
    if (/[\+\-\*\/\^]{3,}/.test(newValue)) {
      // Si se encuentra una secuencia de tres o más operadores seguidos, resetea el valor del campo
      newValue = '';
    }
  }

  if (validateValue(newValue)) {
    setValue(newValue); // Actualiza el valor inmediatamente para reflejar la entrada del usuario

    clearTimeout(timer); // Limpia el temporizador anterior

    // Para el caso 'any', evita la evaluación y reseteo basado en operadores
    if (valueType !== 'any') {
      const newTimer = setTimeout(() => {
        try {
          // Para otros casos que no sean 'any', elimina todos los operadores al final de la cadena
          const sanitizedValue = valueType === 'any' ? newValue : newValue.replace(/[\+\-\*\/\^]+$/, '');
          let evaluatedValue = valueType === 'any' ? newValue : evaluate(sanitizedValue);

          // Aplica el redondeo o valor absoluto específico según el valueType, excepto para 'any'
          switch (valueType) {
            case 'realPositive':
              evaluatedValue = isNaN(evaluatedValue) ? '' : Math.abs(evaluatedValue);
              break;
            case 'integerPositive':
              evaluatedValue = isNaN(evaluatedValue) ? '' : Math.abs(Math.floor(evaluatedValue));
              break;
            
              case 'integer':
              evaluatedValue = isNaN(evaluatedValue) ? '' : Math.floor(evaluatedValue);
              break;
            
             
              
            // No se necesita ajustar específicamente para realNegative y otros,
            // ya que la evaluación maneja positivos y negativos de forma genérica.
          }

          setValue(evaluatedValue.toString()); // Convierte el resultado a string si es necesario

          if (OnSetChange) {
            const typeUnit = find_unit_category(units);
            OnSetChange(evaluatedValue, units, typeUnit);
          }
        } catch (error) {
          console.error("Error al evaluar la expresión: ", error);
          setValue(''); // Resetea el campo si ocurre un error durante la evaluación
        }
      }, 1000);

      setTimer(newTimer);
    } else {
      // Para 'any', simplemente mantén el valor ingresado sin intentar evaluar o modificar
      setValue(newValue);
    }
  }
};



  useEffect(() => {  
  setValue(valuei || defaultValue);
    }, [valuei, defaultValue]);

  return (
    <Box>
    <TextField
     error={error} 
     label={error ? labelError : placeholder} 
     variant={variant}
      size="small"
      fullWidth
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
        type="text"
      InputProps={{
          endAdornment: <InputAdornment position="end">{units}</InputAdornment>,
        }}   
      onFocus={() => setIsFocused(true)} 
      onBlur={() => setIsFocused(false)}
    />
      
</Box>
  );
};

export default IngenniInput;
