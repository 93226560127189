import { createContext, useState, useMemo,useEffect } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
        primary: {
          "primary":"#0f1322",
          "primary2":"#141b2b",
          "primary3":"#2E3950",
          "primary4":"#061423",
          "primary5":"#1E1E1E",
          "secondary":"#FFFFFF",
          "secondary2":"#FCFCFC",
          "secondary3":"#EAEAEA",
          "third":"#0AE98A",
          "third2":"#82E8A0",
          "third3":"#E8FFEE",
          "primarycontrast1":"#f4f4f4",
          "background":"#2E3950",
          "sidebar1":"#0f1322",
          "sidebar2":"#0f1322", 
          "text1": "#132334",
          "text2": "#000000",
          "textwhite":"#ffffff",
          "textblue":"#0f1322",
          "textblack":"#000000",
          "textcontrast":"#000000",
          "menubar":"#0f1322",
          100: "#d0d1d5",
          200: "#a1a4ab",
          300: "#727681",
          400: "#1F2A40",
          500: "#141b2d",
          600: "#101624",
          700: "#0c101b",
          800: "#080b12",
          900: "#040509",
        },
        greenAccent: {
          "contrast1":"#0AE98A",
          100: "#dbf5ee",
          200: "#b7ebde",
          300: "#94e2cd",
          400: "#70d8bd",
          500: "#4cceac",
          600: "#3da58a",
          700: "#2e7c67",
          800: "#1e5245",
          900: "#0f2922",
        },
        redAccent: {
          "contrast1":"#D32F2F",
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },
        blueAccent: {
          "contrast1":"#2FAEFD",
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#2b7cd3",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },
        yelloAccent:{
          contrast1:"#FFAB2D",
          contrast2:"#FAC16C",
          contrast3:"#FFD79B"

        }
      }
    : {
        grey: {
               
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
        },
        primary: {
          "primary":"#E7E6E6",
          "primary2":"#E7E6E6",
          "primary3":"#E7E6E6",
          "primary4":"#F5F5F5",
          "primary5":"#F5F5F5",
          "secondary":"#0f1322",
          "secondary2":"#141b2b",
          "third":"#0AE98A",
          "third2":"#82E8A0",
          "third3":"#E8FFEE",
          "primarycontrast1":"#f4f4f4",
          "background":"#D9D9D9",          
          "sidebar1":"#E7E6E6",
          "sidebar2":"#FFFFFF",
          "text1": "#132334",  
          "text2": "#FFFFFF",
          "textcontrast": "#ffffff",
          "textwhite":"#ffffff",
          "textblue":"#0f1322",
          "textblack":"#000000",
          "menubar":"#0f1322",
          
          100: "#040509",
          200: "#080b12",
          300: "#0c101b",
          400: "#f2f0f0", // manually changed
          500: "#141b2d",
          600: "#1F2A40",
          700: "#727681",
          800: "#a1a4ab",
          900: "#d0d1d5",
        },
        greenAccent: {
          "contrast1":"#0AE98A",
          100: "#0f2922",
          200: "#1e5245",
          300: "#2e7c67",
          400: "#3da58a",
          500: "#4cceac",
          600: "#70d8bd",
          700: "#94e2cd",
          800: "#b7ebde",
          900: "#dbf5ee",
        },
        redAccent: {
          "contrast1":"#F64854",
          100: "#2c100f",
          200: "#58201e",
          300: "#832f2c",
          400: "#af3f3b",
          500: "#db4f4a",
          600: "#e2726e",
          700: "#e99592",
          800: "#f1b9b7",
          900: "#f8dcdb",
        },
        blueAccent: {
          "contrast1":"#4B39EF",
          "contrast2":"#0f1322",
          100: "#151632",
          200: "#2a2d64",
          300: "#3e4396",
          400: "#185abd",
          500: "#6870fa",
          600: "#868dfb",
          700: "#a4a9fc",
          800: "#c3c6fd",
          900: "#e1e2fe",
        },
        yelloAccent:{
          contrast1:"#FFAB2D",
          contrast2:"#FAC16C",
          contrast3:"#FFD79B"

        }
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.greenAccent.contrast1,
            },
            secondary: {
              main: colors.blueAccent.contrast1,
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[100],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary.background
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.blueAccent.contrast2,
            },
            secondary: {
              main: colors.blueAccent.contrast1,
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary.background,
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },

    components: {
      MuiMenu: {
        styleOverrides: {
          // Estilos para el papel del menú
          list: {
            backgroundColor: colors.primary.primary5, // Usar el color de fondo deseado aquí
          },
        },
      },

      MuiMenuItem: {
        styleOverrides: {
          // Estilos para los elementos del menú
          root: {
            '&:hover': {
              backgroundColor: colors.primary.third, // Color al pasar el mouse
            },
            '&.Mui-selected': {
              backgroundColor: colors.primary.primary3, // Color al seleccionar
              '&:hover': {
                backgroundColor: colors.primary.primary2, // Color al pasar el mouse sobre un elemento seleccionado
              },
            },
          },
        },
      },


      MuiFab: {
        styleOverrides: {
          root: {            
            color: colors.primary.textcontrast, // Ejemplo de color de texto personalizado            
          },
        },
      },

    }
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState(() => localStorage.getItem("colorMode") || "dark");
  useEffect(() => {
    // Guarda el modo actual en el localStorage cuando cambie
    localStorage.setItem("colorMode", mode);
  }, [mode]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => {
          const newMode = prev === "light" ? "dark" : "light";          
          return newMode;
        }),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
};

