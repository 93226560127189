import React, { useState,useEffect,useContext } from 'react';
import { Box, styled,Typography,useTheme } from '@mui/material';
import { tokens } from '../../theme';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { DataContext } from '../../contexts/ContextDataProvider/DataProvider';

const ScrollableBox = styled(Box)(({ theme, colorScrollBar,colorScrollBarElementsList2 }) => ({
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
      backgroundColor: colorScrollBarElementsList2 , // Usa el colorScrollBar si está presente, de lo contrario usa el valor por defecto
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: colorScrollBar, 
    },
    // Para Firefox
    scrollbarWidth: 'thin',
    scrollbarColor: `rgba(0,0,0,0.5) ${colorScrollBar || '#E1E1E1'}`,
  }));

     
const ElementsList = ({ elements,add, colorElementsList, colorBoxElementsList,colorTextElementsList,colorTextBoxElementsList,colorElementsListHover,
    colorScrollBarElementsList,colorScrollBarElementsList2,activeElementcolor,editcolor,deletecolor,copyIconcolor,
    onEditElement,onDeleteElement,activeElement,activesheet,label
}) => {
    
    
    //Data Context
    const {updateActiveSheet , activeSheet } = useContext(DataContext);


    //Colors theme
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);  
    //Colors theme
    // Estado para manejar el elemento al que se le hizo hover
    const [hoveredElement, setHoveredElement] = useState(null);
    // Estado para manejar el elemento al que se le hizo click
    const [clickedElement, setClickedElement] = useState(null);
    const [ActiveSheet, setActiveSheet] = useState(null);

    //Estado para manejar el elemnto editable
    const [editingElement, setEditingElement] = useState(null);
    const [newElementName, setNewElementName] = useState('');

//**************************Use Effects************************ */
//**************************Use Effects************************ */



    const handleElementClick = (elementName) => {
        // No hagas nada si el elemento clickeado es el que estás editando
        if(editingElement === elementName) return;
    
        //alert(`Youi have cliked on : ${elementName}`);
        setClickedElement(prev => prev === elementName ? null : elementName);

        // Llamar a la función activeElement pasada como prop para actualizar el estado en el componente principal
        activeElement(elementName);
    };
    
    

    const handleMouseEnter = (elementName) => {
        setHoveredElement(elementName);
    };

    const handleMouseLeave = () => {
        setHoveredElement(null);
        
    };
    const handleEditClick = (e, currentName) => {
        e.stopPropagation();
        setEditingElement(currentName);
        setNewElementName(currentName);
        activeElement(currentName);
        
    };
    
    const handleDeleteClick = (e, index) => {
        e.stopPropagation();
        const elementNameToDelete = elements[index].nombre;
        onDeleteElement(index);
        if (clickedElement === elementNameToDelete) {
            setClickedElement(null); // Resetea el clickedElement a null
        }
    };
    
    

    const handleNameChange = (e) => {
        setNewElementName(e.target.value);
    };
    
    const handleEndEditing = () => {
        if (newElementName && newElementName !== editingElement && !elements.some(el => el.nombre === newElementName)) {
            onEditElement(editingElement, newElementName);
            setEditingElement(null);
        } else if (newElementName === editingElement) {
            setEditingElement(null);  // Si el nombre no cambió, simplemente termina la edición
        } else if (elements.some(el => el.nombre === newElementName)) {
            alert('This Element Already exist!');
        }
        setClickedElement(newElementName)
        //console.log(`***The Element selected is: ${clickedElement}`);

    };


    useEffect(() => {
        // Código que se ejecutará cuando el componente se monte o cuando clickedElement cambie
        activeElement(clickedElement);        
        // Verificar que clickedElement no sea vacío o null
        if (clickedElement) {
            setActiveSheet(true)
            activesheet(true);  
        } 
        else{
            setActiveSheet(false)
            activesheet(false);  
        }     
    }, [clickedElement]);


    //Control tamaño del Elemento
    function getScreenSize() {
        if (window.innerWidth < 600) {
            return 'mobile'; // Pantallas menores a 600px
        } else if (window.innerWidth >= 600 && window.innerWidth < 900) {
            return 'tablet'; // Pantallas entre 600px y 900px
        } else {
            return 'desktop'; // Pantallas mayores a 900px
        }
    }
    const [screenSize, setScreenSize] = useState(getScreenSize());
    useEffect(() => {
        const handleResize = () => {
            setScreenSize(getScreenSize());
        };

        // Escucha el evento resize
        window.addEventListener('resize', handleResize);

        // Limpieza al desmontar
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Determina el alto basado en el tamaño de la pantalla
    const heightScrollableBox = screenSize === 'mobile' ? '20vh' :
                      screenSize === 'tablet' ? '35vh' : '50vh';

    

    let colorletralista, colorletralista2;
    const color = (() => {
      
      
      // Asume que tienes acceso a theme de alguna manera, como por props o useContext
      if (theme.palette.mode === "dark") {
        colorletralista = "#141b2b" // Asumiendo que quieres blanco en modo oscuro
        
      } else {
        colorletralista = "#141b2b" // Asumiendo que quieres blanco en modo oscuro
      }
    
      
    });
                      
    //console.log("this is the elements list")
    //console.log(elements)
    return (
        <Box m={0}>
        <ScrollableBox 
   
    colorScrollBar={colorScrollBarElementsList}
    colorScrollBarElementsList2={colorScrollBarElementsList2}
    style={{ 
        width: '100%',             
        position: 'relative', 
        backgroundColor: colors.primary.primary,
        padding: '5px',
        borderRadius: '5px',
        height: heightScrollableBox
    }}>
             <Box >
             
             <Typography variant="h6" style={{ fontWeight:"bold"}}>
                        {label}
             </Typography>
             <Typography variant="h6" style={{ fontWeight:"bold"}}>
                        Designs Navigator
             </Typography>
             </Box>
            {elements.map((element, index) => (
                <Box 
                    key={index}
                    style={{
                        backgroundColor: 
                            clickedElement === element.nombre ? colors.primary.third2 : 
                            hoveredElement === element.nombre ? colorElementsListHover : colors.primary.secondary, 
                        borderRadius: '5px',
                        padding: '2px',
                        marginBottom: '5px',
                        cursor: 'pointer',
                        transition: 'background-color 0.5s',
                        display: 'flex', alignItems: 'space-between',justifyContent:"space-between"
                    }}
                    
                    onClick={() => handleElementClick(element.nombre)}
                    onMouseEnter={() => handleMouseEnter(element.nombre)}
                    onMouseLeave={handleMouseLeave}
                >
                    {editingElement === element.nombre ? (
                        <input
                        value={newElementName}
                        onChange={(e) => {
                            e.stopPropagation();
                            handleNameChange(e);
                        }}
                        onBlur={(e) => {
                            e.stopPropagation();
                            handleEndEditing();
                        }}
                        
                        autoFocus
                        style={{
                            flex: 1,
                            marginRight: '0px',
                            padding: '5px 10px', // Espacio interno para que el texto no esté pegado al borde.
                            borderRadius: '5px', // Bordes redondeados.
                            border: '1px solid #ccc', // Un borde sutil.
                            outline: 'none', // Elimina el borde azul que aparece al seleccionarlo.
                            transition: 'border-color 0.3s, box-shadow 0.3s', // Transiciones suaves.
                            ':focus': {
                                borderColor: '#007BFF', // Cambia el color del borde cuando el input está enfocado.
                                boxShadow: '0 0 5px rgba(0,123,255,0.5)' // Añade un brillo sutil alrededor del input cuando está enfocado.
                            },
                            fontSize: '1rem', // Tamaño de letra adecuado.
                            color: '#333' // Color de texto oscuro.
                        }}
                    />
                    ) : (
                        <Typography sx={{color:colors.primary.textblue}} >
                            {element.nombre}
                        </Typography>
                    )}

                    <EditIcon fontSize="small" style={{ cursor: 'pointer',color:editcolor, }} onClick={(e) => handleEditClick(e, element.nombre)} />
                    <ContentCopyIcon fontSize="small" style={{ cursor: 'pointer', color:copyIconcolor, marginLeft: '5px' }} onClick={add}/>
                    <DeleteIcon fontSize="small" style={{ cursor: 'pointer', color:deletecolor, marginLeft: '5px' }} onClick={(e) => handleDeleteClick(e, index)} />
                    
                  
                </Box>
            ))}
               <Box style={{ display: 'flex', justifyContent: 'flex-end' }} mr={0}>
                  <Fab onClick={add} size="small" aria-label="add"  style={{ marginTop: '25px', backgroundColor: colors.primary.secondary2 }}>
                    <AddIcon />
                  </Fab>
                </Box>


       </ScrollableBox>
       </Box>
    );
};

export default ElementsList;

