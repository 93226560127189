import React from 'react';
import { Avatar, useTheme } from '@mui/material';
import { tokens } from '../../theme';

const UserAvatar = ({ name, width, height }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const getInitials = (name) => {
    if (!name) return "";
    
    const words = name.trim().split(' ');
    const initials = words.length > 1 ? words[0][0] + words[1][0] : name.substring(0, 2);
    return initials.toUpperCase();
  };

  return (
    <Avatar sx={{
      bgcolor:colors.primary.secondary, // Color de fondo
      color: colors.primary.contrastText, // Color de texto
      border: `2px solid ${colors.primary.text1}`, // Borde
      width: width, // Ancho personalizado
      height: height, // Alto personalizado
      fontSize: `${width / 2}px`, // La mitad del ancho para el tamaño de la fuente
      fontWeight: 'bold', // Texto en negrita
    }}>
      {getInitials(name)}
    </Avatar>
  );
};

export default UserAvatar;
