import { Box, Typography, Button, Grid,ListItem ,ListItemIcon,Stack,Link } from '@mui/material';
import DragAndDrop from './drapAndDropPdf';
import PdfComponent from './drapAndDropPdf2';
import ExcelDropzone from './subirexcel.js';
import logoAycardi from './LogoProexIngenieria.png';
import SplitComponentConstructor from './constructor';



function MfreeEnterprise01 () {
  

  return (
    <div>
     <Box
      display="flex"
      justifyContent="center" // Centrar horizontalmente
      alignItems="center"     // Centrar verticalmente
      minHeight="5vh"       // Usamos minHeight en lugar de mt para garantizar que la caja ocupe todo el alto de la pantalla
      mt={10}
    >
      <img
        src={logoAycardi}
        alt="Imagen"
        width="350"             // Ajusta el ancho como desees
        height="160"            // Ajusta la altura como desees
        style={{
          maxWidth: '100%',     // Ajusta el tamaño de la imagen como desees
          maxHeight: '100%',
        }}        
      />
       {/* Mensaje debajo de la imagen */}
      
    </Box>
    <Box 
      display="flex"
      justifyContent="center" // Centrar horizontalmente
      alignItems="center"     // Centrar verticalmente
      minHeight="5vh"       // Usamos minHeight en lugar de mt para garantizar que la caja ocupe todo el alto de la pantalla
      mt={5}
    >
    <Typography variant="h2" style={{ marginTop: '1px' }}>
          Diseños estructurales entregados a tiempo
        </Typography>       
    </Box>
    <Box 
      display="flex"
      justifyContent="center" // Centrar horizontalmente
      alignItems="center"     // Centrar verticalmente
      minHeight="5vh"       // Usamos minHeight en lugar de mt para garantizar que la caja ocupe todo el alto de la pantalla
      mt={1}
    >
    
        <Link href="https://www.proexingenieria.com" target="_blank" style={{ marginTop: '1px' }}>
          Visita Proex Ingeniería
        </Link>
    </Box>
    <Box m={10} >
        <Typography variant={'h1'}>Unir Memorias</Typography>        
        <Box mb={10}>
        <DragAndDrop></DragAndDrop>
        </Box>
    </Box>
    
  
    
    
   
    </div>

    
  );
}

export default MfreeEnterprise01;
