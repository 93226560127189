import React, { useState } from 'react';
import { S3 } from 'aws-sdk';

// Configuración inicial de AWS S3
const s3 = new S3({
  region: 'us-west-1', // Ejemplo: 'us-west-2'
  accessKeyId: 'AKIA5KYC2NMWW3PPRRUE',
  secretAccessKey: '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',
});

const FileUploader = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log('Archivo seleccionado para cargar:', file.name);
      setSelectedFile(file);
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      console.log('No hay archivo seleccionado.');
      return;
    }

    const params = {
      Bucket: 'ingennicad',
      Key: selectedFile.name,
      Body: selectedFile,
    };

    try {
      console.log('Comenzando la carga a S3...');
      const uploadResult = await s3.upload(params).promise();
      console.log('Archivo cargado con éxito:', uploadResult);
    } catch (error) {
      console.error('Error al cargar archivo:', error.message);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileInput} />
      <button onClick={handleFileUpload}>Cargar a S3</button>
    </div>
  );
};

export default FileUploader;
