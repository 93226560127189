export function extractModuleData(modules) {
  const extractedData = modules.map(module => {
    return {
      id: module.id,
      clasificationelement: module.DataModule.clasificationelement || 'none',
      clasificationmaterial: module.DataModule.clasificationmaterial || 'none',
      clasificationuse: module.DataModule.clasificationuse || 'none',
      clasificationtypecalcsheet: module.DataModule.clasificationtypecalcsheet || 'none',
      isCustomModule: module.DataModule.isCustomModule === undefined ? 'none' : module.DataModule.isCustomModule,
      structuralCodeAvailable: module.DataModule.structuralCodeAvailable || [],
    };
  });

  let clasificationElements = new Set();
  let clasificationMaterials = new Set();
  let clasificationUses = new Set();
  let clasificationTypes = new Set();
  let structuralCodesAvailable = new Set();

  modules.forEach(module => {
    clasificationElements.add(module.DataModule.clasificationelement || 'none');
    clasificationMaterials.add(module.DataModule.clasificationmaterial || 'none');
    clasificationUses.add(module.DataModule.clasificationuse || 'none');
    clasificationTypes.add(module.DataModule.clasificationtypecalcsheet || 'none');
    
    if (module.DataModule.structuralCodeAvailable) {
      module.DataModule.structuralCodeAvailable.forEach(code => {
        if (code !== 'none' && code !== '') {
          structuralCodesAvailable.add(code);
        }
      });
    }
  });

  const extractUniqueClassifications = {
    clasificationElements: [...clasificationElements].filter(e => e !== 'none'),
    clasificationMaterials: [...clasificationMaterials].filter(e => e !== 'none'),
    clasificationUses: [...clasificationUses].filter(e => e !== 'none'),
    clasificationTypes: [...clasificationTypes].filter(e => e !== 'none'),
  };

  // Extraer y combinar clasificaciones y códigos estructurales únicos en una sola lista, excluyendo 'none'.
  const classificationsAndCodes = [
    ...new Set([
      'Custom Module Enterprise',
      ...[...structuralCodesAvailable].filter(e => e !== 'none'),
      ...extractUniqueClassifications.clasificationElements,
      ...extractUniqueClassifications.clasificationMaterials,
      ...extractUniqueClassifications.clasificationUses,
      ...extractUniqueClassifications.clasificationTypes,
     
    ])
  ];

  const finalData = classificationsAndCodes
  

  return finalData;
}
