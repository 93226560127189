import React, { useState, useEffect,useContext } from 'react';
import { tokens,ColorModeContext } from "../../theme";
import { DataContext } from '../../contexts/ContextDataProvider/DataProvider';

import { useTheme,Grid, Card, CardContent, CardActions, Button, Typography,Box } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import ModalNewSheet from '../../@IngenniCalc/Components/ModalNewSheet';
import { crudSheets } from '../../@IngenniCalc/Functions/crudSheets';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import LaunchIcon from '@mui/icons-material/Launch';
import { ModalOpenFile } from '../../@IngenniCalc/Components/ModalOpenFile';


const SheetsList = ({ cardDataList,wmax,image }) => {
  //Data Context
  const { projects, addSheet,cleanActiveSheet} = useContext(DataContext);
  //Context Colors theme
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);  
  const colorMode = useContext(ColorModeContext);

  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null); // Para saber qué tarjeta fue seleccionada
  const [selectedModule, setSelectedModule] = useState("defaultModule");
  

  const [openModalOpenFile, setOpenModalOpenFile] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  
  const updateRecentModules = (moduleId) => {    
    const recentModules = JSON.parse(localStorage.getItem('recentModules')) || [];        
    const moduleIndex = recentModules.indexOf(moduleId);  
    if (moduleIndex !== -1) {
      recentModules.splice(moduleIndex, 1);
    }      
    recentModules.unshift(moduleId); 
    if (recentModules.length > 4) {
      recentModules.pop();
    }
    localStorage.setItem('recentModules', JSON.stringify(recentModules));
  };
  

  const handleFileOpenIconClick = (cardData) => {
    setRedirectUrl(`/${cardData.to}`); // Guarda la URL de redirección
    setOpenModalOpenFile(true); // Abre el modal
  };



  const handleButtonClick = (cardData) => {
    updateRecentModules(cardData.id)
    if (cardData.typesheet === 'free') {
      // Si el tipo de hoja es 'free', navega directamente
      navigate(`/${cardData.to}`);
      localStorage.removeItem('elements');
      cleanActiveSheet()
    } else {
      // De lo contrario, abre el modal para crear una nueva hoja
      cleanActiveSheet()
      localStorage.removeItem('elements');
      setSelectedCard(cardData);
      setSelectedModule(cardData.to)
      setOpenModal(true);
      
    }
  };

    // Función para manejar clic en LaunchIcon
  const handleLaunchIconClick = (cardData) => {
      cleanActiveSheet()
      updateRecentModules(cardData.id)
      localStorage.removeItem('fileName');
      localStorage.removeItem('SaveInfo');
      localStorage.removeItem('selectedUnits');
      localStorage.removeItem('selectedCodeDesign');
      localStorage.removeItem('elements');
      localStorage.removeItem('inputData');      
      localStorage.removeItem("ElementDataForms")          
      navigate(`/${cardData.DataModule.to}`);
    };
  
 
  const useremail=localStorage.getItem('useremail') 
  const enterprise=localStorage.getItem('NitEnterprise')
  

  const gradientcolorcard = `linear-gradient(to right, ${colors.primary.primary2}, ${colors.primary.primary2})`;

 
  const CardContainer = styled(Card)(({ theme, gradientColors, bordercolor }) => ({
    maxWidth: 350, // Mantiene el maxWidth para consistencia
    margin: '0 auto',
    background: gradientcolorcard,
    borderRadius: 10,
    boxShadow: theme.palette.mode === 'light' ? `0 0 5px ${colors.grey[500]}` : 'none',
    borderColor: theme.palette.mode === 'light' ? colors.grey[500] : colors.primary.secondary,
    borderWidth: '1px',
    
    borderStyle: theme.palette.mode === 'light' ? "none" : 'solid',
    
    
    paddingLeft:10,
    paddingRight:10,
    [theme.breakpoints.down('sm')]: {
      width: '320px',// Menor minWidth para pantallas pequeñas
      minheight:300
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 250, // Aumenta el minWidth para pantallas más grandes
      minheight:320
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 345, // minWidth estándar para desktop
      minheight:320
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 12,
    letterSpacing: 1,
  }));

  const Image = styled('img')(({ theme }) => ({
    width: '32%',
    height: 'auto',
    objectFit: 'cover',
    borderRadius: '10px 10px 0 0',
   
  
    [theme.breakpoints.down('sm')]: {
      width: '32%',
      height: 'auto',
      borderRadius: '10px',
      marginTop: '5px',
    },
  }));

  const ImageContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  });

  const Actions = styled(CardActions)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
  }));

  const ButtonStyled = styled(Button)(({ theme }) => ({
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 1,
  }));

  

  const createNewSheet = (formData) => {
    // Preparación de los datos que se pasarán a addSheet
    const sheetData = {
      nameSheet: formData.nameSheet,
      project: formData.project,
      subproject: formData.subproject,
      isModule: selectedModule,
      // Asumiendo que formData incluye todos los datos necesarios.
      Data: {
        units: formData.unit,
        structuralCodeDesign: formData.codeDesign
      }
    };
  
    // Invoca addSheet de DataContext pasando los datos preparados
    addSheet(sheetData).then(() => {
      // Puedes manejar acciones post-creación aquí. Por ejemplo, redireccionar o actualizar el estado.
      navigate(`/${selectedModule}`);
    }).catch(error => {
      console.error('Error when creating the spreadsheet from SheetsList', error);
    });
  };

  const sortedCardDataList = [...cardDataList].sort((a, b) => {
    // Convert names to lowercase for case-insensitive sorting
    const nameA = a.nameModule.toLowerCase();
    const nameB = b.nameModule.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
  


  return (
    <Grid container spacing={2} justifyContent="center" alignItems="stretch" >
      {sortedCardDataList.map((cardData, index) => (
        <Grid item key={index}>
          <CardContainer gradientColors={cardData.DataModule.gradientColors} bordercolor={cardData.DataModule.bordercolor}>
          
            <ImageContainer>
              <Image src={cardData.DataModule.imageUrl} alt="Card image" />
            </ImageContainer>

            <CardContent>
              <Title color="text.primary" variant="h2" component="div">
                {cardData.nameModule}
              </Title>
              <Typography variant="body1" component="div" color="text.secondary">
                {cardData.DataModule.description}
              </Typography>    
            </CardContent>
            <Actions>
              <Box mb={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width:"100%" }}>
              <ButtonStyled onClick={() => handleButtonClick(cardData.DataModule)} variant="contained" color="primary">
                New Sheet
              </ButtonStyled>
              {cardData.DataModule.typesheet === "pay" && (
                <>
                  <FileOpenIcon 
                  sx={{ color: colors.blueAccent.contrast1  }}
                  onClick={() => handleFileOpenIconClick(cardData.DataModule)}  />
                  <LaunchIcon 
                   sx={{ color: colors.redAccent.contrast1 }}
                  onClick={() => handleLaunchIconClick(cardData)} />
                </>
              )}
              </Box>
            </Actions>
          </CardContainer>
        </Grid>
      ))}
      <ModalNewSheet
        open={openModal}
        handleClose={() => setOpenModal(false)}       
        onAcceptNewSheet={createNewSheet} 
        codeDesigns={selectedCard?.structuralCodeAvailable || []} // Pasa structuralCodeAvailable
        units={selectedCard?.unistAvailable || []} // Pasa unistAvailable
        module={selectedModule}      
      />
    
      <ModalOpenFile
        open={openModalOpenFile}
        handleClose={() => setOpenModalOpenFile(false)}
        onOpen={() => {}}
        redirectTo={redirectUrl}
      />

    </Grid>
  );
};

export default SheetsList;
