import React, { useState,useEffect } from 'react';
import { Box, Typography,Button } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

// Importación de los módulos
import IngenniM01 from '../M01/view/ViewIndexM01';
import IngenniM02 from '../M02/view/ViewIndexM02';
import IngenniM03 from '../M03/view/ViewIndexM03';
import IngenniM04 from '../M04/view/ViewIndexM04';
import IngenniM05 from '../M05/view/ViewIndexM05';
import IngenniM06 from '../M06/view/ViewIndexM06';
import IngenniM07 from '../M07/view/ViewIndexM07';
import IngenniM08 from '../M08/view/ViewIndexM08';
import IngenniM09 from '../M09/view/ViewIndexM09';
import IngenniM10 from '../M010/view/ViewIndexM10';
import IngenniM11 from '../M011/view/ViewIndexM11';
import IngenniM12 from '../M012/view/ViewIndexM12';
import IngenniM23 from '../M23/view/ViewIndexM23';
import IngenniM27 from '../M27/view/ViewIndexM27';

const MAINRENDERBOX = ({ nameModule, typeModule, activeElement, ActiveSheet, SaveData, colorbackgroundInputs, 
                        codeDesign, units, label, colorbackgroundArea, addOptions, idioma, temporalInputSave,toggleElementsVisibility,isElementsVisible }) => {                          
  if (activeElement === null) {
    activeElement = "";
  }
  const [elementActive,setelementActive]=useState(activeElement)
  //console.log("UNITS FROM MAINRENDERBOX COMPONENT")
  //console.log(units)
  //console.log(units)
  //console.log("CODE FROM MAINRENDERBOX COMPONENT")
  //console.log(codeDesign)
  //console.log(codeDesign)

  const RenderModuleView = (colorbackgroundInputs,isExpanded) => {
    
    switch(typeModule) {
      case "IngenniM01":
        return <IngenniM01 unitsDesign={units} codeDesign={codeDesign}  temporalInputSave={temporalInputSave} ActiveSheet={ActiveSheet} activeElement={activeElement } isExpanded={isExpanded}/>;
      case "IngenniM02":
        return <IngenniM02 unitsDesign={units} codeDesign={codeDesign}  temporalInputSave={temporalInputSave} ActiveSheet={ActiveSheet} activeElement={activeElement } isExpanded={isExpanded}/>;
      case "IngenniM03":
        return <IngenniM03 unitsDesign={units} codeDesign={codeDesign}  temporalInputSave={temporalInputSave} ActiveSheet={ActiveSheet} activeElement={activeElement } isExpanded={isExpanded}/>;
        case "IngenniM04":
        return <IngenniM04 unitsDesign={units} codeDesign={codeDesign}  temporalInputSave={temporalInputSave} ActiveSheet={ActiveSheet} activeElement={activeElement } isExpanded={isExpanded}/>;
      case "IngenniM05":
        return <IngenniM05 unitsDesign={units} codeDesign={codeDesign} temporalInputSave={temporalInputSave} ActiveSheet={ActiveSheet} activeElement={activeElement } isExpanded={isExpanded}/>;
      case "IngenniM06":
        return <IngenniM06 unitsDesign={units} codeDesign={codeDesign} temporalInputSave={temporalInputSave} ActiveSheet={ActiveSheet} activeElement={activeElement } isExpanded={isExpanded}/>;
        case "IngenniM07":
        return <IngenniM07 unitsDesign={units} codeDesign={codeDesign} temporalInputSave={temporalInputSave} ActiveSheet={ActiveSheet} activeElement={activeElement } isExpanded={isExpanded}/>;
      case "IngenniM08":
        return <IngenniM08 unitsDesign={units} codeDesign={codeDesign} temporalInputSave={temporalInputSave} ActiveSheet={ActiveSheet} activeElement={activeElement } isExpanded={isExpanded}/>;
      case "IngenniM09":
        return <IngenniM09 unitsDesign={units} codeDesign={codeDesign} temporalInputSave={temporalInputSave} ActiveSheet={ActiveSheet} activeElement={activeElement } isExpanded={isExpanded}/>;
        case "IngenniM10":
        return <IngenniM10 unitsDesign={units} codeDesign={codeDesign} temporalInputSave={temporalInputSave} ActiveSheet={ActiveSheet} activeElement={activeElement } isExpanded={isExpanded}/>;
      case "IngenniM11":
        return <IngenniM11 unitsDesign={units} codeDesign={codeDesign} temporalInputSave={temporalInputSave} ActiveSheet={ActiveSheet} activeElement={activeElement } isExpanded={isExpanded}/>;
      case "IngenniM12":
        return <IngenniM12 unitsDesign={units} codeDesign={codeDesign} temporalInputSave={temporalInputSave} ActiveSheet={ActiveSheet} activeElement={activeElement } isExpanded={isExpanded}/>;      
      case "IngenniM23":
          return <IngenniM23 unitsDesign={units} codeDesign={codeDesign} temporalInputSave={temporalInputSave} ActiveSheet={ActiveSheet} activeElement={activeElement } isExpanded={isExpanded}/>;      
      case "IngenniM27":
          return <IngenniM27 unitsDesign={units} codeDesign={codeDesign} temporalInputSave={temporalInputSave} ActiveSheet={ActiveSheet} activeElement={activeElement } isExpanded={isExpanded}/>;
      
      default:
        return null; 
    }
  }

  const [isExpanded, setIsExpanded] = useState(false);

  const togglePanel = () => {
    toggleElementsVisibility()
    setIsExpanded(!isExpanded);
    
  };

  useEffect(()=>{
    console.log("Console From @MainRenderBox.js")
    console.log(isExpanded)
  },[isExpanded])
  //{ActiveSheet && renderModuleView(colorbackgroundInputs)}
  return (
    <Box 
      
      sx={{ 
        width: '100%',             
        borderRadius: "10px",
        backgroundColor:"transparent"
       
      }}
    >
      <Box padding={1} sx={{ display: 'flex', flexDirection: 'row' }} >
      <Box mr={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Button onClick={togglePanel}
         sx={{
          minWidth: '30px', // Establece el ancho mínimo del botón
          height: '30px', // Establece el alto del botón
          padding: '1px', // Ajusta el relleno para reducir el espacio alrededor del ícono
        }}
        
        >
          {isExpanded ? <ArrowForwardIos /> : <ArrowBackIos />}
        </Button>
        <Typography variant="h5" sx={{ fontWeight: 'bold', marginLeft: 1 }}>
          Element: {activeElement}
        </Typography>
      </Box>
    </Box>
        <Box sx={{backgroundColor:"transparent"}}>
      {RenderModuleView(colorbackgroundInputs,isExpanded)}
      </Box>
    </Box>
  );
}

export default MAINRENDERBOX;

