import React, { useState,useEffect } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const IngenniSelectRestricted = ({ label, options, OnSetChange,valuei }) => {
    const [selectedValue, setSelectedValue] = useState('');

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
        OnSetChange(value, "adimensional", "adimensional");
    };

    useEffect(() => {  
        setSelectedValue(valuei);
          }, [valuei]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 120, width: '100%' }}>
                <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedValue}
                    onChange={handleSelectChange}
                    label={label} // Asegúrate de incluir esta prop para que el espacio para el label se calcule correctamente
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default IngenniSelectRestricted;