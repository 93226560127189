import React, { useState, useEffect } from 'react';
import { Box, Autocomplete, TextField } from '@mui/material';

const IngenniSelect = ({ label, options, OnSetChange, valuei }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  // Verificar y establecer el valor predeterminado al inicio
  useEffect(() => {
    if (Array.isArray(options)) {
      const matchingOption = options.find(option => option.value === valuei);
      setSelectedOption(matchingOption || null);
    } else {
      console.error("Error: 'options' must be an array of objects with a 'value' property.");
    }
  }, [valuei, options]);

  // Almacena el cambio y llama a la función de actualización
  const handleChange = (event, newValue) => {
    setSelectedOption(newValue);
    // Asegura que el valor actualizado se transmita
    OnSetChange(newValue?.value || null, "adimensional", "adimensional");
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <Autocomplete
        value={selectedOption}
        onChange={handleChange}
        options={options}
        getOptionLabel={(option) => option?.label || ""}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        renderInput={(params) => (
          <TextField {...params} label={label} variant="outlined" size="small" fullWidth />
        )}
        sx={{ width: '100%' }}
      />
    </Box>
  );
};

export default IngenniSelect;
