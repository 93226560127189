import React from 'react';
import Button from '@mui/material/Button';

export function downloadDataAsJson(localStorageKey) {
    const data = JSON.parse(localStorage.getItem(localStorageKey));
    
    if (!data) {
        console.log("No data found for the given localStorage key.");
        return;
    }

    // Limpieza de elementos no deseados
    if (data.DataSheet && data.DataSheet.Elements) {
        data.DataSheet.Elements = data.DataSheet.Elements.filter(element => {
            // Conserva el elemento si el nombre no es un arreglo vacío ni una cadena vacía
            return !(Array.isArray(element.name) && element.name.length === 0) && element.name !== "";
        });
    }

    const nameSheet = data.nameSheet || "DefaultName";
    const isModule = data.isModule || "UnknownModule";
    const fileName = `${nameSheet}-${isModule}.json`;

    const dataStr = JSON.stringify(data, null, 2);
    const dataBlob = new Blob([dataStr], { type: 'application/json' });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(dataBlob);
    downloadLink.download = fileName;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}
