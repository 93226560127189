import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { Document, Page, Text, View, Image, StyleSheet, pdf } from '@react-pdf/renderer';
import axios from 'axios';

const DownloadButton = ({ data }) => {
  const [imagesData, setImagesData] = useState([]);

  useEffect(() => {
    const downloadImages = async () => {
      const promises = data.images.map(image => axios.get(image, { responseType: 'arraybuffer' }));
      const images = await Promise.all(promises);
      const base64Images = images.map(image => Buffer.from(image.data, 'binary').toString('base64'));
      setImagesData(base64Images);
    };
    downloadImages();
  }, [data.images]);

  const handleDownload = () => {
    generatePDF(data, imagesData).then((pdfData) => {
      const blob = new Blob([pdfData], { type: 'application/pdf' });
      saveAs(blob, 'documento.pdf');
    });
  };

  const generatePDF = async (data, imagesData) => {
    const doc = (
      <Document>
        <Page style={styles.page}>
          <View style={styles.container}>
            <Text style={styles.title}>{data.title}</Text>
            {data.paragraphs.map((paragraph, index) => (
              <Text key={`paragraph-${index}`} style={styles.paragraph}>
                {paragraph}
              </Text>
            ))}
            {data.equations.map((equation, index) => (
              <Text key={`equation-${index}`} style={styles.equation}>
                {equation}
              </Text>
            ))}
            {imagesData.map((image, index) => (
              <Image key={`image-${index}`} src={`data:image/jpeg;base64,${image}`} style={styles.image} />
            ))}
            {data.tables.map((table, index) => (
              <View key={`table-${index}`} style={styles.table}>
                <View style={styles.tableHeaderRow}>
                  {table.headers.map((header, headerIndex) => (
                    <Text key={`header-${headerIndex}`} style={styles.tableHeaderCell}>
                      {header}
                    </Text>
                  ))}
                </View>
                {table.rows.map((row, rowIndex) => (
                  <View key={`row-${rowIndex}`} style={styles.tableRow}>
                    {row.map((cell, cellIndex) => (
                      <Text key={`cell-${cellIndex}`} style={styles.tableCell}>
                        {cell}
                      </Text>
                    ))}
                  </View>
                ))}
              </View>
            ))}
          </View>
        </Page>
      </Document>
    );

    const pdfData = await pdf(doc).toBlob();
    return pdfData;
  };

  return (
    <button onClick={handleDownload}>
      Descargar PDF
    </button>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
  },
  container: {
    flex: 1,
    margin: 10,
  },
  title: {
    fontSize: 20,
    marginBottom: 10,
  },
  paragraph: {
    fontSize: 12,
    marginBottom: 5,
  },
  equation: {
    fontSize: 12,
    marginBottom: 5,
  },
  image: {
    width: 200,
    height: 200,
    marginBottom: 10,
  },
  table: {
    marginBottom: 20,
  },
  tableHeaderRow: {
    flexDirection: 'row',
    borderBottomColor: '#000',
    borderBottomWidth: 1,
    marginBottom: 5,
    padding: 5,
  },
  tableHeaderCell: {
    flex: 1,
    fontSize: 14,
    fontWeight: 'bold',
    padding: 5,
  },
  tableRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 5,
  },
  tableCell: {
    flex: 1,
    fontSize: 12,
    padding: 5,
  },
});

export default DownloadButton;
