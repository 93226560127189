import React,{useState,useEffect,useContext} from 'react';
import {useTheme, Box, Typography, Grid, TextField, Stack, Tabs, Tab, Button, MenuItem, FormControl, InputLabel, Select,useMediaQuery } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import { List, ListItem, ListItemText, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { Checkbox, FormControlLabel } from '@mui/material';


import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Icono para el indicador de expansión

///Import Contexto principal
import { DataContext } from '../../../contexts/ContextDataProvider/DataProvider';
///Import colorstheme
import { tokens } from '../../../theme';
///Import Componentes
import IngenniInput from '../../Components/inputs/IngenniInput';
import IngenniSelect from '../../Components/inputs/ingenniSelect';
///Import custom Hooks---Importar en todos los componente
import useUnitsLabels from '../../hooks/useUnitLabels/useUnitLabels';
import useLanguageLabels from '../../hooks/useNamesLabels/useNamesLabels';
import useDesignCode from '../../hooks/useDesignCode/useDesignCode';
import useUnits from '../../hooks/useConversionUnits/useUnits';
import useFormData from '../../hooks/useFormData/useFormData';
// Imports Particulares de cada modulo
import names from './names.json';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';

const IngenniM11 = (props) => {

   //-------------------------MANDATORY-------------------------------------------------
  //-------------------------MANDATORY-------------------------------------------------
  //************************************************************************************
  //************Theme Colors******* 
  //************************************************************************************  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);     

  //************************************************************************************
  //************Use Customs Manejo de Labels por idioma y Unidades**********************
  //************************************************************************************  
  const N = useLanguageLabels(names);
  const units=props.unitsDesign
  console.log("Console from ViewIndexM11")
  console.log("Console from ViewIndexM11")
  console.log("Console from ViewIndexM11")
  console.log(units)
  const currentUnits= useUnits(units);
  const unitData = useUnitsLabels(props.unitsDesign); 
  const currentCodeDesign = useDesignCode(props.codeDesign) 
  //************************************************************************************
  //************Use custom Gestion Estado Principal del componente**********************
  //************************************************************************************  
  const [formData, handleInputChange,handleInputObjectChange] = useFormData({},props.activeElement,currentUnits);
  //-------------------------MANDATORY-------------------------------------------------
  //-------------------------MANDATORY-------------------------------------------------
  

  const [isCustomModulus, setIsCustomModulus] = useState(false);
  const [isImportedSection, setisImportedSection] = useState(false);
  const [isAutoAnalysis, setisisAutoAnalysis] = useState(true);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const toggleCustomModulus = () => {
    setIsCustomModulus(!isCustomModulus);
  };

  const toggleImportedSection = () => {
    setisImportedSection(!isImportedSection);
  };

  const toggleAutoAnalysis = () => {
    setisisAutoAnalysis(!isAutoAnalysis);
  };

  

  const ListProfiles= [
  {value: 'IPE100', label: 'IPE100' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' }]

  return (
    <Box sx={{
      width: '100%',
      minHeight: '80vh',
      display: 'grid',
      gridTemplateColumns: { xs: '1fr', sm: '1fr', md: '80% 20%' },
      gap: 2
    }}>
      {/* Primer Box Azul Oscuro */}
      <Box sx={{
        bgcolor: colors.primary.primary,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Typography variant={isMobile ? 'h4' : 'h3'} component="div" sx={{  mb: 2 }}>
          Composite Slab Design
        </Typography>
        <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} mt={1} mb={3}>
          <LooksOneIcon sx={{ color: colors.blueAccent.contrast1}}/>
          <Typography variant="h5" component="div" sx={{  
          textDecoration: 'underline',
          textDecorationColor: colors.blueAccent.contrast1, 
          textDecorationThickness: '2px', 
          textUnderlineOffset: '5px',
          }}>
            Materials
          </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={3}>              
              <IngenniInput 
                 label={N["fy steel"] ? N["fy steel"] : "Loading..."} valueType="realPositive" width="90%" placeholder="fy-yield stress" units={unitData ? unitData.stress : "Loading..."}                                      
                 OnSetChange={(newValue,units,typeunit) => handleInputChange("fy", newValue,units,typeunit)} 
                 valuei={formData['fy'] ? formData['fy'].value : ''}  
                 error={false}
                 labelError="error 1"
                />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>              
              <IngenniInput 
                 label={N["fc concrete"] ? N["fc concrete"] : "Loading..."} valueType="realPositive" width="90%" placeholder="fc-concrete strenght" units={unitData ? unitData.stress : "Loading..."}                                      
                 OnSetChange={(newValue,units,typeunit) => handleInputChange("fc", newValue,units,typeunit)} 
                 valuei={formData['fc'] ? formData['fc'].value : ''}  
                 error={false}
                 labelError="error 1"
                />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>              
              <IngenniInput 
                 label={N["Es"] ? N["Es"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Es-Elastic module steel" units={unitData ? unitData.stress : "Loading..."}                                      
                 OnSetChange={(newValue,units,typeunit) => handleInputChange("Es", newValue,units,typeunit)} 
                 valuei={formData['Es'] ? formData['Es'].value : ''}  
                 error={false}
                 labelError="error 1"
                />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>              
              <IngenniInput 
                 label={N["Young's Modulus (E)"] ? N["Young's Modulus (E)"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Ec-Young's Modulus (E) concrete" units={unitData ? unitData.stress : "Loading..."}                                      
                 OnSetChange={(newValue,units,typeunit) => handleInputChange("Ec", newValue,units,typeunit)} 
                 valuei={formData['Ec'] ? formData['Ec'].value : ''}  
                 error={false}
                 labelError="error 1"
                />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCustomModulus}
                    onChange={toggleCustomModulus}
                    name="customModulus"
                  />
                }
                label="Custom Elasticity Modulus"
              />
            </Grid>
           
            {isCustomModulus && (
              <Grid item xs={12} sm={4} md={3}>              
              <IngenniInput 
                 label={N["n"] ? N["n"] : "Loading..."} valueType="realPositive" width="90%" placeholder="n- modular ratio Es/Ec" units={unitData ? unitData.stress : "Loading..."}                                      
                 OnSetChange={(newValue,units,typeunit) => handleInputChange("nUser", newValue,units,typeunit)} 
                 valuei={formData['nUser'] ? formData['nUser'].value : ''}  
                 error={false}
                 labelError="error 1"
                />
            </Grid>
            )}
          </Grid>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} mt={2}>
          <LooksTwoIcon sx={{color:colors.blueAccent.contrast1}} ></LooksTwoIcon>
          <Typography variant="h5" component="div" sx={{ mt: 1, mb: 1,          
            textDecoration: 'underline',
            textDecorationColor: colors.blueAccent.contrast1, 
            textDecorationThickness: '2px', 
            textUnderlineOffset: '5px',
          }}>
            Geometry and Properties
          </Typography>

         
          </Box>

          <Box mt={1} mb={1}>
          <Typography variant="h6" component="div" sx={{mt: 1, mb: 1,          
            textDecoration: 'underline',
            textDecorationColor: colors.blueAccent.contrast1, 
            textDecorationThickness: '1px', 
            textUnderlineOffset: '5px',
          }}>
            General
          </Typography>
          </Box>

          <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={3}>              
              <IngenniInput 
                 label={N["BeamSpacing"] ? N["H"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Beam spacing" units={unitData ? unitData.length : "Loading..."}                                      
                 OnSetChange={(newValue,units,typeunit) => handleInputChange("BeamSpacing", newValue,units,typeunit)} 
                 valuei={formData['BeamSpacing'] ? formData['BeamSpacing'].value : ''}  
                 error={false}
                 labelError="error 1"
                />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>              
              <IngenniInput 
                 label={N["span"] ? N["span"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Longitudinal Span" units={unitData ? unitData.length : "Loading..."}                                      
                 OnSetChange={(newValue,units,typeunit) => handleInputChange("Span", newValue,units,typeunit)} 
                 valuei={formData['Span'] ? formData['Span'].value : ''}  
                 error={false}
                 labelError="error 1"
                />
            </Grid>

          
              
          
            </Grid>


          <Box mt={1} mb={1}>
          <Typography variant="h6" component="div" sx={{mt: 1, mb: 1,          
            textDecoration: 'underline',
            textDecorationColor: colors.blueAccent.contrast1, 
            textDecorationThickness: '1px', 
            textUnderlineOffset: '5px',
          }}>
            Steel Section
          </Typography>
          </Box>

          <Grid item xs={12} sm={4} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isImportedSection}
                    onChange={toggleImportedSection}
                    name="importedSection"
                  />
                }
                label="Imported Section?"
              />
            </Grid>
       

          
   
            <Grid container spacing={2}>
            {isImportedSection && (
            <Grid item xs={12} sm={4} md={3}>
               <IngenniSelect label="Steel Section" options={ListProfiles}  
              valuei={formData['Steel Section'] ? formData['Steel Section'].value : ''}
              OnSetChange={(newValue,units,typeunit) => handleInputChange("Steel Section", newValue,units,typeunit)}                  
              ></IngenniSelect>
            </Grid>)}
              <Grid item xs={12} sm={4} md={3}>              
              <IngenniInput 
                 label={N["H"] ? N["H"] : "Loading..."} valueType="realPositive" width="90%" placeholder="H- total heigh section" units={unitData ? unitData.length : "Loading..."}                                      
                 OnSetChange={(newValue,units,typeunit) => handleInputChange("H", newValue,units,typeunit)} 
                 valuei={formData['H'] ? formData['H'].value : ''}  
                 error={false}
                 labelError="error 1"
                />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>              
            <IngenniInput 
               label={N["tw"] ? N["tw"] : "Loading..."} valueType="realPositive" width="90%" placeholder="tw- web tickness" units={unitData ? unitData.length : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("tw", newValue,units,typeunit)} 
               valuei={formData['tw'] ? formData['tw'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>              
            <IngenniInput 
               label={N["wf"] ? N["wf"] : "Loading..."} valueType="realPositive" width="90%" placeholder="wf- width flange" units={unitData ? unitData.length : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("bf", newValue,units,typeunit)} 
               valuei={formData['wf'] ? formData['wf'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>              
            <IngenniInput 
               label={N["twf"] ? N["twf"] : "Loading..."} valueType="realPositive" width="90%" placeholder="twf- tickness flange" units={unitData ? unitData.length : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("twf", newValue,units,typeunit)} 
               valuei={formData['twf'] ? formData['twf'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>

            

          </Grid>
          <Typography variant="h6" component="div" sx={{mt: 1, mb: 1,          
            textDecoration: 'underline',
            textDecorationColor: colors.blueAccent.contrast1, 
            textDecorationThickness: '1px', 
            textUnderlineOffset: '5px',
          }}>
            Concrete Slab
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["hlosa"] ? N["hlosa"] : "Loading..."} valueType="realPositive" width="90%" placeholder="tickness Slab" units={unitData ? unitData.length : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("hlosa", newValue,units,typeunit)} 
               valuei={formData['hlosa'] ? formData['hlosa'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["hrib"] ? N["hrib"] : "Loading..."} valueType="realPositive" width="90%" placeholder="heigh rib" units={unitData ? unitData.length : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("hrib", newValue,units,typeunit)} 
               valuei={formData['hrib'] ? formData['hrib'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>

            
            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["wrib"] ? N["wrib"] : "Loading..."} valueType="realPositive" width="90%" placeholder="width rib" units={unitData ? unitData.length : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("hrib", newValue,units,typeunit)} 
               valuei={formData['wrib'] ? formData['wrib'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["percentageCompositeAction"] ? N["percentageCompositeAction"] : "Loading..."} valueType="realPositive" width="90%" placeholder="% Composite Action" units={unitData ? unitData.percentage : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("PAC", newValue,units,typeunit)} 
               valuei={formData['PAC'] ? formData['PAC'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>
          
          </Grid>



          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} mt={2}>
          <Looks3Icon sx={{color:colors.blueAccent.contrast1}} ></Looks3Icon>
          <Typography variant="h5" component="div" sx={{ mt: 1, mb: 1,          
            textDecoration: 'underline',
            textDecorationColor: colors.blueAccent.contrast1, 
            textDecorationThickness: '2px', 
            textUnderlineOffset: '5px',
          }}>
            Forces
          </Typography>

         
          </Box>
          
          <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAutoAnalysis}
                    onChange={toggleAutoAnalysis}
                    name="autoAnalysis"
                  />
                }
                label="Automatic Anlysis?"
              />
            </Grid>

                {isAutoAnalysis &&(
                  <Grid container spacing={2} mr={0.1} ml={0.1}>
            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["wslab"] ? N["wslab"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Load slab" units={unitData ? unitData.forcelength : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("wslab", newValue,units,typeunit)} 
               valuei={formData['wslab'] ? formData['wslab'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["LiveCload"] ? N["LiveCload"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Live Construttion Load" units={unitData ? unitData.forcelength : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("Live Construttion Load", newValue,units,typeunit)} 
               valuei={formData['LiveCload'] ? formData['LiveCload'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["SteelDeckload"] ? N["SteelDeckload"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Load Steel deck" units={unitData ? unitData.forcelength : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("Load Steel deck", newValue,units,typeunit)} 
               valuei={formData['SteelDeckload'] ? formData['SteelDeckload'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["SuperDeadload"] ? N["SuperDeadload"] : "Loading..."} valueType="realPositive" width="90%" placeholder="SuperDead load" units={unitData ? unitData.forcelength : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("Live Construttion Load", newValue,units,typeunit)} 
               valuei={formData['SuperDeadload'] ? formData['SuperDeadload'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["LiveLoad"] ? N["LiveLoad"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Live Load" units={unitData ? unitData.forcelength : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("LiveLoad", newValue,units,typeunit)} 
               valuei={formData['LiveLoad'] ? formData['LiveLoad'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>
            </Grid>
            )}

                {!isAutoAnalysis &&(
                  <Grid container spacing={2} mr={0.1} ml={0.1}>
                    
            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["Mdc"] ? N["Mdc"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Moment Dead under Construction" units={unitData ? unitData.moment : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("Mdc", newValue,units,typeunit)} 
               valuei={formData['Mdc'] ? formData['Mdc'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["Mcl"] ? N["Mcl"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Moment Live under Constrution " units={unitData ? unitData.moment : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("Mcl", newValue,units,typeunit)} 
               valuei={formData['Mcl'] ? formData['Mcl'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["Md"] ? N["Md"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Moment Dead " units={unitData ? unitData.moment : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("Md", newValue,units,typeunit)} 
               valuei={formData['Md'] ? formData['Md'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["Ml"] ? N["Ml"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Moment Live " units={unitData ? unitData.moment : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("Ml", newValue,units,typeunit)} 
               valuei={formData['Ml'] ? formData['Ml'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["Vcd"] ? N["SteelDeckload"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Shear dead under construction" units={unitData ? unitData.force : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("Vcd", newValue,units,typeunit)} 
               valuei={formData['Vcd'] ? formData['Vcd'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["Vcl"] ? N["Vcl"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Shear live under construction" units={unitData ? unitData.force : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("Vcl", newValue,units,typeunit)} 
               valuei={formData['Vcl'] ? formData['Vcl'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["Vd"] ? N["SteelDeckload"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Shear dead" units={unitData ? unitData.force : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("Vd", newValue,units,typeunit)} 
               valuei={formData['Vd'] ? formData['Vd'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["Vl"] ? N["Vl"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Shear live " units={unitData ? unitData.force : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("Vl", newValue,units,typeunit)} 
               valuei={formData['Vl'] ? formData['Vl'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["Temperature"] ? N["Vl"] : "Loading..."} valueType="realPositive" width="90%" placeholder="Temperature " units={unitData ? unitData.temperature : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("Temperature", newValue,units,typeunit)} 
               valuei={formData['Temperature'] ? formData['Temperature'].value : ''}  
               error={false}
               labelError="error 1"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["angle"] ? N["angle"] : "Loading..."} valueType="realPositive" width="90%" placeholder="degrees" units={unitData ? unitData.degrees : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("angle", newValue,units,typeunit)} 
               valuei={formData['angle'] ? formData['angle'].value : ''}  
               error={false}
               labelError="error 1"
               variant="standard"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["adimensional"] ? N["adimensional"] : "Loading..."} valueType="realPositive" width="90%" placeholder="adimensional" units={unitData ? unitData.adimensional : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("adimensional", newValue,units,typeunit)} 
               valuei={formData['adimensional'] ? formData['adimensional'].value : ''}  
               error={false}
               labelError="error 1"
               variant="filled"
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3}>
            <IngenniInput 
               label={N["none"] ? N["none"] : "Loading..."} valueType="realPositive" width="90%" placeholder="none" units={unitData ? unitData.none : "Loading..."}                                      
               OnSetChange={(newValue,units,typeunit) => handleInputChange("none", newValue,units,typeunit)} 
               valuei={formData['none'] ? formData['none'].value : ''}  
               error={false}
               labelError="error 1"
               variant="filled"
              />
            </Grid>


           
            </Grid>
            )}       



          </Grid>

        </Box>
      </Box>
      {/* Segundo Box Azul Oscuro */}
      <Box sx={{
        bgcolor: colors.primary.primary,
        minHeight: { xs: 200, md: 650 },
        borderRadius: '10px',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Typography variant={isTablet ? 'h6' : 'h5'} component="div" sx={{ mb: 2 }}>
          Right Panel Title
        </Typography>
        <Typography >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Typography>
      </Box>
    </Box>
  );
};

export default IngenniM11;
