import { useState, useEffect } from 'react';
import unidades from '../../UnitsFile/UnitsFile.json';

// Definiciones de las unidades incluidas directamente en el hook.
const unitsNames = unidades

function useUnitsLabels(unitsDesign) {
  const [unitData, setUnitData] = useState(null);

  useEffect(() => {
    if (unitsDesign) {
      const data = unitsNames[unitsDesign];
      if (data) {
        setUnitData(data);
      } else {
        // Manejar el caso de no encontrar los datos. 
        setUnitData(null); // O manejar un estado de error específico.
      }
    }
  }, [unitsDesign]);

  return unitData;
}

export default useUnitsLabels;
