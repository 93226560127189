import React, { useState } from 'react';
import {Box, AppBar, Toolbar, IconButton, Button, TextField, Grid, Menu, MenuItem, useMediaQuery, useTheme,Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { tokens } from '../../theme';
import SaveIcon from '@mui/icons-material/Save';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { Link } from 'react-router-dom';





const MenuPublic = ({ add, namesheetactive, updateActiveSheet, openSaveAs }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [saveAsMenuAnchorEl, setSaveAsMenuAnchorEl] = useState(null);
  const saveAsMenuOpen = Boolean(saveAsMenuAnchorEl);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleSaveAsMenuClose(); // Asegúrate de cerrar el submenú "Save As" también
  };

  const handleSaveAsMenuOpen = (event) => {
    setSaveAsMenuAnchorEl(event.currentTarget);
  };

  const handleSaveAsMenuClose = () => {
    setSaveAsMenuAnchorEl(null);
  };

  const handleSaveCloudStorageClick = () => {
    openSaveAs();
    handleSaveAsMenuClose(); // Cierra el submenú después de la acción
  };

  return (
    <AppBar position="static">
      <Toolbar sx={{ backgroundColor: colors.primary.menubar }}>
      <Typography variant="h3" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
            Ingenni
          </Typography>
        {isMobile && (
          <>
           <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={handleMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} component={Link} to="/">Home</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/login">Login</MenuItem>
              </Menu>
          </>
        )}

        {!isMobile && (
          <>
             <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
           <Box >
      
           

         
          </Box>
   
      
          <Box>
        
            <Button variant="contained" color="primary"  component={Link} to="/login"  style={{ marginLeft: theme.spacing(2) }}>
              Login
            </Button>
            </Box>
            </Box>
          </>
        )}

        {/* Submenú "Save As" */}
        <Menu
          id="save-as-submenu"
          anchorEl={saveAsMenuAnchorEl}
          open={saveAsMenuOpen}
          onClose={handleSaveAsMenuClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          
          sx={{
            '& .MuiPaper-root': { // Esto aplica estilos al papel del menú
              backgroundColor: colors.blueAccent.contrast1, // Cambia el color de fondo del papel del menú
              marginTop: '15px', // Añade un poco de espacio arriba para bajar el menú
            }
          }}
        >
          <MenuItem onClick={handleSaveCloudStorageClick}>Save Cloud Storage</MenuItem>
          <MenuItem onClick={handleSaveAsMenuClose}>Save as JSON file</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default MenuPublic;
