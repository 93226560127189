import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { tokens,ColorModeContext } from '../../theme';


const PasswordRecoveryModal = ({ open, onClose }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [colorMessageRecover, setColorMessage] = useState('');

  useEffect(() => {
    // Resetear el mensaje cuando se abre el modal
    if (open) {
      setResponseMessage('');
    }
  }, [open]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleRecoverPassword = async () => {
    // Validar el formato del correo electrónico
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Correo electrónico no válido');
      return;
    }

    setLoading(true);
    setEmailError('');

    try {
      // Realizar la llamada a la API utilizando fetch
      const response = await fetch(
        'https://ss0pnhnrj9.execute-api.us-east-1.amazonaws.com/dev/recoverpassword',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        }
      );

      if (!response.ok) {
        throw new Error('Error al recuperar la contraseña');
      }

      const data = await response.json();
      setResponseMessage(data.body);
      const validationColor = data.statusCode;

      if (validationColor == "200") {
        setColorMessage(colors.primary.third3);
      } else {
        setColorMessage(colors.yelloAccent.contrast3);
      }

    } catch (error) {
      setResponseMessage('Error al recuperar la contraseña');
    } finally {
      setLoading(false);
    }
  };

  const modalWidth = isMobile ? '90%' : isTablet ? '80%' : '40%';

  return (
    <Modal open={open} onClose={() => { onClose(); setResponseMessage(''); }}>
      <Box
        sx={{
          position: 'absolute',
          width: modalWidth,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: 3,
          textAlign: 'center',
          backgroundColor: colors.primary.primary3,
          borderRadius:1.5
         
        }}
      >
        <Typography variant="h5" mb={2}>
          Password recovery
        </Typography>

        <TextField
          label="Enter your email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={handleEmailChange}
          error={!!emailError}
          helperText={emailError}
          mb={5}
        />
        <Box mt={2}>
          <Button
            variant="contained"            
            onClick={handleRecoverPassword}
            disabled={loading}
            fullWidth
           
          >
            {loading ? <CircularProgress size={24} /> : 'Recover password'}
          </Button>
        </Box>

        {responseMessage && (
          <Box
            sx={{
              mt: 2,
              p: 2,
              backgroundColor: colorMessageRecover,
            
              borderRadius: '5px',
            }}
          >
            <Typography variant="h6" sx={{"color":colors.primary.text1}}>{responseMessage}</Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default PasswordRecoveryModal;

