import React from 'react';
import './3vistaPrevia.css';

// Esta función ayuda a renderizar los datos generales en un formato legible
const renderizarDatosGenerales = (datos) => {
  return (
    <>
    <p className="nombre-proyecto">Nombre del Proyecto: {datos.nombreProyecto}</p>
    <p className="dato-general">Número del Proyecto: {datos.numeroProyecto}</p>
    <p className="dato-general">Calculo: {datos.calculo}</p>
    <p className="dato-general">Revisó: {datos.reviso}</p>
    <p className="dato-general">Ciudad: {datos.ciudad}</p>
    <p className="dato-general">Fecha: {datos.fecha}</p>
    <p className="dato-general">Observaciones: {datos.observaciones}</p>
    
  </>
  );
}

function VistaPrevia({ datosGenerales }) { // Asegúrate de desestructurar datosGenerales de props
  return (
    <div className="VistaPrevia"> {/* Cambiado de "App" a "VistaPrevia" para coincidir con el CSS */}
      <div className="hoja tamaño-carta">
        <div className="contenido">
          {/* Aquí se renderizan los datos generales */}
          {renderizarDatosGenerales(datosGenerales)}
        </div>
      </div>
   
    </div>
  );
}

export default VistaPrevia;

