import { useState,useEffect} from "react";
import { useContext } from "react";
import { tokens,ColorModeContext } from "../../theme";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme,Button } from "@mui/material";
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import "react-pro-sidebar/dist/css/styles.css";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import useMediaQuery from '@mui/material/useMediaQuery';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PersonIcon from '@mui/icons-material/Person';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import FactoryIcon from '@mui/icons-material/Factory';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import UserAvatar from "../../components/UserAvatar/UserAvatar";
import UserProfileContext from "../../contexts/UserProfileContext/UserProfileContext";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DataContext } from "../../contexts/ContextDataProvider/DataProvider";


const Item = ({ title, to, icon, selected, setSelected, noSelect,onClick}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);  
  const colorMode = useContext(ColorModeContext);
 
  
  return (
    <MenuItem
    active={selected === title}
    style={{ color: colors.grey[100] }}
    onClick={() => {
      if (!noSelect) setSelected(title);
      if (onClick) onClick();
    }}
    icon={icon}
  >
    <Typography>{title}</Typography>
    <Link to={to} />
  </MenuItem>
  );
};

const fetchPresignedUrlAndUploadImage = async (imageFile, id) => {
  console.log("Start Loading Image");
  const response = await fetch('https://ss0pnhnrj9.execute-api.us-east-1.amazonaws.com/dev/crud-enterprises', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({
        operation: "generateUploadUrl",
        id: id,
    }),
  });

  const data = await response.json();
  console.log("First answer from api");
  console.log(data);

  // No realices JSON.parse aquí, ya que data.body ya debería ser un objeto JavaScript
  const uploadUrl = data.body.uploadUrl;

  // Ahora usa la URL prefirmada para cargar la imagen
  const uploadResponse = await fetch(uploadUrl, {
    method: 'PUT',
    headers: {
        'Content-Type': 'image/jpeg', // Ajusta según el tipo de imagen
    },
    body: imageFile, // El archivo binario de la imagen
  });

  if (uploadResponse.ok) {
    console.log("Imagen cargada con éxito");
    // Opcional: Guardar el nombre del archivo (data.fileName) en tu base de datos
  } else {
    console.error("Error al cargar la imagen");
  }
};




const Sidebar = ({ usernamesidebar,onLogout}) => {
 
  const { userName, userJob } = useContext(UserProfileContext);
  const {dataEnterprise} = useContext(DataContext);
  
  
  
  const showMenuProgramming=true;  
  const job=localStorage.getItem('userjob'); 
  const rol=localStorage.getItem('rol'); 
  const urlLogoEnterprise=localStorage.getItem('UrlLogo'); 
  const widthLogoEnterprise=localStorage.getItem('widthLogo'); 
  const heightLogoEnterprise=localStorage.getItem('heightLogo'); 
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [logo, setLogo] = useState(null);
  
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const appSideBarStyles = {
    position: 'fixed',
    top: 50
    // Agrega otros estilos personalizados según tus necesidades
  };

 
  

  const [idioma, setIdioma] = useState(localStorage.getItem('idioma') || 'en');
  const getTexts = (languaje) => {
    if (languaje === 'es') {
      // Textos en español
      return {
        new:"Nuevo Calculo",
        logOut:"logout",
        myprojects:'Mis Proyectos',
        mysheets:'Mis calculos',
        myuser:'Mi usuario',
        myenterprise:'Mi empresa',
        thememode:"Modo thema",

      };
    } else {
      // Textos en inglés 
      return {
        new:"New Sheet",
        logOut:"logout",
        thememode:"theme mode",
        myprojects:'My Projects',
        mysheets:'My Sheets',
        myuser:'My User',
        myenterprise:'My Enterprise'
      };
    }
  };
  const texts=getTexts(idioma)
  const [selected, setSelected] = useState(texts.new);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setLogo(null);
    }
  };

  const handleSaveLogo = () => {
    let identerprise = localStorage.getItem('NitEnterprise'); 
    console.log("Logo to save:", logo); 
    console.log("Enterprise ID:", identerprise);
    if(logo) {
        fetchPresignedUrlAndUploadImage(logo, identerprise);
    } else {
        console.log("No logo to upload.");
    }
};

  
  const handleDeleleteLogo = () => {
    setLogo(null)
    console.log("Logo Deleted!"); // Aquí puedes implementar la lógica de guardado
  };
  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          
          background: `linear-gradient(to bottom, ${colors.primary.sidebar1} 10%, ${colors.primary.sidebar2} 80%, ${colors.primary.sidebar2} 100%)`
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: `${colors.greenAccent.contrast1} !important`,
        },
        "& .pro-menu-item.active": {
          color: `${colors.blueAccent.contrast1} !important`,
        },
        display: isDesktop ? 'block' : 'none'
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{ margin: "10px 0 20px 0", color: colors.grey[100] }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >

                <Typography variant="h4" color={colors.grey[100]}>
                  Ingenni 
                </Typography>
                
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          

          {!isCollapsed && (


         
            <Box mb="25px">  



<Box textAlign="center" mt={2} mb={2}>
          <Box display="flex" justifyContent="center" mb={2}>
            {logo ? (
              <img src={logo} alt="Logo" style={{ maxWidth: '200px', maxHeight: '200px' }} />
            ) : (
              <Typography>Here your Logo</Typography>
              
            )}
          </Box>
          <Box>
          <label htmlFor="upload-logo">
            <input
              accept="image/*"
              id="upload-logo"
              type="file"
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />
            {!logo && (
            <Button
              color="primary"
              variant="contained"
              component="span"
              aria-label="upload picture"
              startIcon={<AddPhotoAlternateIcon />}
            >
              Select File
            </Button>
             )}
          
          </label> 
          </Box>
          {logo && (
          <Box mt={2}> 
          <Box mb={2}>
            <Button
              color="secondary"
              variant="outlined"
              component="span"
              aria-label="upload picture"
              startIcon={<CloudUploadIcon />}
              onClick={handleDeleleteLogo}
            >
              Delete image
            </Button>
            </Box>

          
            <Box>
          <Button
              color="secondary"
              variant="contained"
              component="span"
              aria-label="upload picture"
              startIcon={<CloudUploadIcon />}
              onClick={handleSaveLogo}
            >
              Save Logo
            </Button>
            </Box>
            </Box>
          )}
        </Box>

         
             
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width={widthLogoEnterprise}
                  height={heightLogoEnterprise}
                  src={urlLogoEnterprise}         
                />
              </Box>  
              <Box textAlign="center">
                
                <Box display="flex" justifyContent="center" mt={2}>
                  <UserAvatar name={userName} width={75} height={75} />
                </Box>

                <Typography
                  variant="h3"
                  color={colors.blueAccent.contrast1}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {userName}
                </Typography>

                <Typography variant="h5" color={colors.blueAccent.contrast1}>
                    {rol}
                </Typography>
                <Typography variant="h5" color={colors.blueAccent.contrast1}>
                    {userJob}
                </Typography>
                <Box mt={2}>
                <Typography variant="h5" color={colors.primary.secondary}>
                    {dataEnterprise.NameEnterprise}
                </Typography>
                </Box>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "1%"}>
            <Item
              title={texts.new}
              to="/"
              icon={<PlaylistAddIcon/>}
              selected={selected}
              setSelected={setSelected}
            /> 
             {showMenuProgramming &&(     
            <Item
              title={texts.myprojects}
              to="/myprojects"
              icon={<AccountTreeIcon/>}              
              selected={selected}
              setSelected={setSelected}
            />  )}
            {showMenuProgramming &&(     
            <Item
              title={texts.mysheets}
              to="/mysheets"
              icon={<ListAltIcon/>}              
              selected={selected}
              setSelected={setSelected}
            />  )}
            {showMenuProgramming &&(        
            <Item
              title={texts.myuser}
              to="/user"
              icon={<PersonIcon/>}              
              selected={selected}
              setSelected={setSelected}
            />)}
         {rol === 'owner' && showMenuProgramming && (
          <Item
            title={texts.myenterprise}
            to="/myenterprise"
            icon={<FactoryIcon/>}              
            selected={selected}
            setSelected={setSelected}
          />
        )}
        
        <Item
              title={texts.thememode}
              icon= {theme.palette.mode === "dark" ? (
                <DarkModeOutlinedIcon />
              ) : (
                <LightModeOutlinedIcon />
              )}
              onClick={colorMode.toggleColorMode}
              noSelect={true}
            />

       <Item
              title={texts.logOut}
              icon={<CloseIcon />}
              onClick={onLogout}
              noSelect={true}
            />


           </Box>
        </Menu>



      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
