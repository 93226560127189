import React from 'react';
import Button from '@mui/material/Button';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsAppButton2 = ({ phoneNumber }) => {
  // Formato de número debe ser internacional (ejemplo: +1234567890)
  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank'); // Abre el enlace en una nueva pestaña
  };

  return (
    <Button startIcon={<WhatsAppIcon />} onClick={handleClick}>
      Questions? Contact Us
    </Button>
  );
};

export default WhatsAppButton2;
