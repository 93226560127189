import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Typography,Box } from '@mui/material';
import { PDFDocument,StandardFonts } from 'pdf-lib';
import logoAycardi from './LogotipoOficialAycardiIngenierosCiviles.png';


function DragAndDrop() {



    const [files, setFiles] = React.useState([]);
    const [mergedFile, setMergedFile] = React.useState(null);

    


    const onDrop = useCallback((acceptedFiles) => {
        setFiles((prev) => [...prev, ...acceptedFiles]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'application/pdf',
    });
    const useremail=localStorage.getItem('useremail'); 
    /////////////////////////////////////
    // Función para enviar datos a la API
       const sendAPIData = async () => {

        try {
            const response = await fetch('https://ss0pnhnrj9.execute-api.us-east-1.amazonaws.com/dev/modulestrack', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user: useremail,
                    module: "modulo_unir_memorias",
                    action: "click boton unir y descargar pdfs"
                }),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            const data = await response.json();
            console.log('Success:', data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const mergePDFs = async () => {
        if (files.length < 2) {
            alert('Sube al menos 2 archivos para unir.');
            return;
        }
    
        const mergedPdf = await PDFDocument.create();
    
        for (let file of files) {
            const fileBuffer = await file.arrayBuffer();
            const pdfDoc = await PDFDocument.load(fileBuffer);
            const pages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
    
            for (let page of pages) {
                // Obtiene el tamaño y el centro de la página
                const { width } = page.getSize();
                const xCenter = width / 2;
                mergedPdf.addPage(page);
            }
        }
    
      
        const mergedPdfFile = await mergedPdf.save();
        setMergedFile(mergedPdfFile);
    
        const blob = new Blob([mergedPdfFile], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'merged_document.pdf';
        link.click();

         // Llamada a la función sendAPIData después de la unión y descarga de los PDFs
         await sendAPIData();
    };
   
  

    const applyFormatToPDF = async (pdfBuffer) => {
        const pdfDoc = await PDFDocument.load(pdfBuffer);
        
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    
        // Incrusta la imagen en el documento PDF
        {/*
        const logoURL = "https://ingennical-recources-enterprises.s3.amazonaws.com/LogotipoOficialAycardiIngenierosCiviles.png";
        const logoBytes = await fetch(logoURL).then(res => res.arrayBuffer());
        */}
        

        const logoBytes = await fetch(logoAycardi).then(res => res.arrayBuffer());
        
        const logoImage = await pdfDoc.embedPng(logoBytes);
    
        const pages = pdfDoc.getPages();

           // Descargar el PDF original
            ///-------DATA CONTROL//////
        const marginSize = 28.35; // 1cm en puntos
        const marginSizeLeft=32
        const marginSizeRight=26
        const padding=2 // distancia de imagen a lineas de margen
        const logoSizeInPoints = 90; // dimenxion cuadrada de logo
        const thicknessline1=1.2 // grosor lineas margenes
        const hcell1=29  //altura de fila, no mofificar
        const hcell2=53  //altura de fila, no mofificar
        const hcell3=19.5  //altura de fila, no mofificar
        const hcell4=21
        const hcell5=43
        const spacecell1=23 //distancia de logo a inicio de lineas
        const spacepagenumber=100.1 // distancia de margen a linea vertical de numeracion de pagina
        const spacecell2=158 // distancia entre lineas de pagina y linea divisisoria de filas 1 y 2
            ///-------DATA CONTROL//////
        
        for (let page of pages) {
            const { width, height } = page.getSize();        
            
            // Logo Aycardi Ingenieros Civiles
            page.drawImage(logoImage, {
                x: marginSizeLeft + padding,
                y: page.getHeight() - logoSizeInPoints - marginSize - padding,
                width: logoSizeInPoints,
                height: logoSizeInPoints,
            });

    // Dibujar la línea superior
    page.drawLine({
        start: { x: marginSizeLeft, y: height - marginSize },
        end: { x: width - marginSize, y: height - marginSize },
        thickness: thicknessline1,
        
      });

      // Dibujar la línea superior 2
      page.drawLine({
        start: { x: marginSizeLeft, y: height - marginSize - logoSizeInPoints - 2*padding},
        end: { x: width - marginSize, y: height - marginSize - logoSizeInPoints - 2*padding},
        thickness: thicknessline1,
        
      });

      // Dibujar la línea superior 3
      page.drawLine({
        start: { x: marginSize+logoSizeInPoints+spacecell1, y: height - marginSize - hcell1},
        end: { x: width - marginSize, y: height - marginSize - hcell1},
        thickness: thicknessline1,
        
      });

      // Dibujar la línea superior 4
      page.drawLine({
        start: { x: marginSize+logoSizeInPoints+spacecell1, y: height - marginSize - hcell2},
        end: { x: width - marginSize, y: height - marginSize - hcell2},
        thickness: thicknessline1,
        
      });

       // Dibujar la línea superior 5
       page.drawLine({
        start: { x: marginSize+logoSizeInPoints+spacecell1, y: height - marginSize - hcell2-hcell3},
        end: { x: width - marginSize-spacepagenumber, y: height - marginSize - hcell2-hcell3},
        thickness: thicknessline1,
        
      });

   

      // Dibujar la línea superior 7 Vetical
      page.drawLine({
        start: { x: width - marginSize - spacepagenumber-spacecell2, y: height-marginSize-hcell1},
        end: { x: width - marginSize - spacepagenumber-spacecell2, y: height-marginSize-hcell1-hcell5},
        thickness: thicknessline1,
        
      });

    // Dibujar la línea superior 6
             page.drawLine({
                start: { x: width - marginSize-spacepagenumber, y: height - marginSize - hcell2},
                end: { x: width - marginSize-spacepagenumber, y: height - marginSize - hcell2-hcell3-hcell4},
                thickness: thicknessline1,
                
              });

      // Dibujar la línea inferior
      page.drawLine({
        start: { x: marginSizeLeft, y: marginSize },
        end: { x: width - marginSize, y: marginSize },
        thickness: thicknessline1,
        
      });

      // Dibujar la línea izquierda
      page.drawLine({
        start: { x: marginSizeLeft ,y: marginSize },
        end: { x: marginSizeLeft, y: height - marginSize },
        thickness: thicknessline1,
        
      });

      // Dibujar la línea derecha
      page.drawLine({
        start: { x: width - marginSizeRight, y: marginSize },
        end: { x: width - marginSizeRight, y: height - marginSize },
        thickness: thicknessline1,                  
      });

        }
    
        return await pdfDoc.save();
    };

    const downloadBlob = (blob, filename) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        
    };
    
    const handleApplyFormatAndDownload = async () => {
        if (!mergedFile) {
            alert('Primero une los archivos PDF.');
            return;
        }
        const formattedPDF = await applyFormatToPDF(mergedFile);
        const blob = new Blob([formattedPDF], { type: 'application/pdf' });
        downloadBlob(blob, 'formatted_document.pdf');
    };
    
    
    return (
        <div>
            
            <div
                {...getRootProps({
                    className: 'dropzone',
                })}
                style={{ border: '2px dashed gray', padding: '20px', textAlign: 'center' }}
            >
                <input {...getInputProps()} />
                <p>Arrastra y suelta archivos PDF aquí, o haz clic para seleccionarlos</p>
            </div>
            {files.length > 0 && (
                <ul>
                    {files.map((file, idx) => (
                        <li key={idx}>{file.name}</li>
                    ))}
                </ul>
            )}
            <Box mt={4}>
            <Button variant="contained" color="primary" onClick={mergePDFs}>
                Unir y descargar
            </Button>
            </Box>
            {/*
            <Box mt={4} mb={10}>
            <Button variant="contained" color="secondary" onClick={handleApplyFormatAndDownload}>
            Aplicar formato y descargar
            </Button>            
            </Box>
            */}
           
        </div>
    );
}

export default DragAndDrop;
