import React, { useState,useEffect,useContext} from 'react';
import { tokens,ColorModeContext } from '../../theme';
import { useTheme,Modal, Box, TextField, MenuItem, Button,CircularProgress, Typography } from '@mui/material';
import britishFlag from '../assets/britishflag1.png';
import colombiaflag from '../assets/colombiaflag1.png';
import usflag from '../assets/usflag1.png';
import unitssi from '../assets/unitssi1.png';
import unitsmks from '../assets/unitsmks1.png';
import unitsimperial from '../assets/unitsimperial1.png';
import noneimage from '../assets/none.png';
import { Autocomplete } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../../contexts/ContextDataProvider/DataProvider';

//console
function ModalNewSheetInside({ open, handleClose, codeDesigns, units, onAcceptNewSheet, module,nameprovisional }) {
  const navigate = useNavigate();
  const { projects } = useContext(DataContext);
  //console
  //console.log("NameProvisional Fron ModalNewSheetInside.js")
  //console.log("NameProvisional Fron ModalNewSheetInside.js")
  //console.log(nameprovisional)
  //Start Theme Colors
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); 
  //End theme Colors
  const [nameSheet, setNameSheet] = useState(nameprovisional);
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedSubproject, setSelectedSubproject] = useState('');
  const [showSubprojects, setShowSubprojects] = useState(false);

  const [projectsoptions, setProjects] = useState([]);
  

  const handleProjectSelectionChange = (event, newValue) => {
    setSelectedProject(newValue);
    const selectedProjectData = projects.find(project => project.nombre === newValue);
    if (selectedProjectData && selectedProjectData.subproyectos.length > 0) {
      // Transforma la lista de subproyectos para extraer solo la parte antes de la coma
      const transformedSubprojects = selectedProjectData.subproyectos.map(subproyecto => subproyecto.split(',')[0]);
      setSubprojects(transformedSubprojects);
      setShowSubprojects(true);
    } else {
      setSubprojects([]);
      setShowSubprojects(false);
    }
  };
  

  useEffect(() => {
    
    const projectOptions = projects.map(project => project.nombre);
    setProjects(projectOptions)

    // Resetear subproyectos cuando se cierra el modal
    if (!open) {
      setSelectedProject(null);
      setSubprojects([]);
      setShowSubprojects(false);
    }
  }, [open]);
  
  const [subprojects, setSubprojects] = useState([]);

  


  const [selectedCodeDesign, setSelectedCodeDesign] = useState(
    codeDesigns.length === 1 && codeDesigns[0] === 'none' ? 'none' : ''
  );
  const [selectedUnit, setSelectedUnit] = useState(
    units.length === 1 && units[0] === 'none' ? 'none' : ''
  );
  const [isLoading, setIsLoading] = useState(false);

   // Función para manejar el clic en el botón Skip
   const handleSkipClick = (cardData) => {
    navigate(`/${cardData.to}`);
  };






  const handleAccept = () => {
    const formData = {
      nameSheet,
      project: selectedProject,
      subproject: selectedSubproject,
      codeDesign: selectedCodeDesign,
      unit: selectedUnit
    };
    setIsLoading(true);
     // Guardar en localStorage
     localStorage.setItem('fileName', nameSheet);
     localStorage.setItem('selectedProject', selectedProject);
     localStorage.setItem('selectedSubproject', selectedSubproject);
     localStorage.setItem('selectedCodeDesign', selectedCodeDesign);
     localStorage.setItem('selectedUnit', selectedUnit);
     localStorage.removeItem('inputData');

    onAcceptNewSheet(formData);
    setIsLoading(false);
    handleClose();
  };


  useEffect(() => {
    if (codeDesigns.length > 1) {
      setSelectedCodeDesign(codeDesigns[0]);
    } else {
      setSelectedCodeDesign(
        codeDesigns.length === 1 && codeDesigns[0] === 'none' ? 'none' : ''
      );
    }
  }, [codeDesigns]);
  
  useEffect(() => {
    if (units.length > 1) {
      setSelectedUnit(units[0]);
    } else {
      setSelectedUnit(
        units.length === 1 && units[0] === 'none' ? 'none' : ''
      );
    }
  }, [units]);

  const isFormComplete = () => {
    return (
      nameSheet &&
      selectedProject &&
      selectedSubproject 
   
    );
  };

  useEffect(() => {
    // Mapea los nombres de los proyectos
    const projectOptions = projects.map(project => project.nombre);
    
    // Ordena las opciones de proyectos en orden alfabético
    const sortedProjectOptions = projectOptions.sort((a, b) => a.localeCompare(b));
    
    // Establece las opciones ordenadas en el estado
    setProjects(sortedProjectOptions);
  
    // Resetear subproyectos cuando se cierra el modal
    if (!open) {
      setSelectedProject(null);
      setSubprojects([]);
      setShowSubprojects(false);
    }
  }, [open, projects]);
  
  



  const getCodeDesignImage = (codeDesign) => {
    switch (codeDesign) {
      case "NSR-10":
      case "CCP-14":
        return colombiaflag;
      case "ACI-318-19":
      case "AASHTO-2020":
        return usflag;
      default:
        return noneimage; // O puedes retornar una imagen por defecto
    }
  };

  const getUnitsmage = (unitsImage) => {
    switch (unitsImage) {
      case "kN,m,MPa":
      case "kN,mm,MPa":
        return unitssi;
      case "tonf,m,tonf/m2":
      case "kgf,m,kgf/cm2":
        return unitsmks;

      case "kip,ft,ksi":
      case "kip,in,psi":
            return britishFlag;
      default:
        return noneimage; // O puedes retornar una imagen por defecto
    }
  };

  //console.log("FROM ModalNewSheet.js")
  //console.log(projects)
  

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      >
      <Box
      sx={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)', 
        width: 400, 
        bgcolor: colors.primary.primary2,
        border: `0.5px solid ${colors.primary.third}`,
        borderRadius:0.8,
        boxShadow: 24
      }}
      >
     <Box 
      sx={{ 
        height: '1cm', 
        backgroundColor: theme.palette.mode  === 'dark' ? colors.primary.primary2: colors.primary.contrast2, 
        width: '100%' 

        
      }}
     >      
      
     </Box>
      <Box ></Box>
        <Box>
        <h2 id="modal-title" style={{ textAlign: 'center' }}>Saving File</h2>
        </Box>
        <Box sx={{ mr: 4, ml: 4 }}>
        <TextField
          fullWidth
          label="NameSheet"
          margin="normal"
          value={nameSheet}
          onChange={(e) => setNameSheet(e.target.value)}
        />
        </Box>
      
        <Box sx={{ mr: 4, ml: 4 }}>
      <Autocomplete
        value={selectedProject}
        onChange={handleProjectSelectionChange} // Asegúrate de llamar a la función correcta aquí
        options={projectsoptions}
        renderInput={(params) => (
          <TextField {...params} label="Projects" margin="normal" />
        )}
      />
      </Box>
  
     
      {showSubprojects && (
        <Box sx={{ mr: 4, ml: 4 }}>
        <Autocomplete
          value={selectedSubproject}
          onChange={(event, newValue) => {
            setSelectedSubproject(newValue);
          }}
          options={['none', ...subprojects]}
          renderInput={(params) => (
            <TextField
              {...params}
              label="SubProjects"
              margin="normal"
            />
          )}
        />
        </Box>
      )}
      

      <Box sx={{ mr: 4, ml: 4 }}>
        <TextField
          fullWidth
          label="Code Designs"
          margin="normal"
          // Directamente el valor que llega de las props
          value={codeDesigns[0]}
          // Desactivado para que el usuario no pueda modificarlo
          disabled
          // Se elimina el atributo select ya que no necesitaremos un menú desplegable
          // Se elimina el map sobre codeDesigns ya que mostraremos un valor fijo
          InputProps={{
            startAdornment: (
              <img
                src={getCodeDesignImage(codeDesigns[0])} // Usa la función para obtener la imagen basada en el valor de codeDesigns[0]
                alt={codeDesigns[0]}
                style={{ width: '20px', height: '20px', marginRight: '10px' }}
              />
            ),
          }}
        />
      </Box>



        <Box sx={{ mr: 4, ml: 4 }}>
          <TextField
            fullWidth
            label="Units"
            margin="normal"
            // Directamente el valor que llega de las props
            value={units[0]} 
            // Desactivado para que el usuario no pueda modificarlo
            disabled
            // Se elimina el atributo select ya que no necesitaremos un menú desplegable
            // Se elimina el map sobre units ya que mostraremos un valor fijo
            InputProps={{
              startAdornment: (
                <img
                  src={getUnitsmage(units[0])} // Usa la función para obtener la imagen basada en el valor de units[0]
                  alt={units[0]}
                  style={{ width: '20px', height: '20px', marginRight: '10px' }}
                />
              ),
            }}
          />
        </Box>


        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 ,mr:4, mb:4}}>
          <Button sx={{ mr: 1 }} onClick={handleClose}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleAccept}
            disabled={!isFormComplete()}
          >
            Accept
          </Button>
        </Box>
      </Box>
   
    </Modal>
  );
}

export default ModalNewSheetInside;

